import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";

import { getStoarge } from "../Datas";
import { getMeLanding } from "./GET";

const ProfileState = atom({
  key: "ProfileState",
  default: null,
});
const UserState = atom({
  key: "UserState",
  default: null,
});
export const GetMe = () => {
  const [profile, setProfile] = useRecoilState(ProfileState);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const setAuth = async () => {
      let jwt = await getStoarge("jwt");
      if (jwt) {
        const result = await getMeLanding();

        if (result !== undefined) {
          setProfile(result?.data?.jwt);
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    setAuth();
  }, []);
  return { profile, loading };
};

export { ProfileState, UserState };
