/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { POST_JOIN_API } from '../Data/POST';


const containerStyle = css`
  max-width: 700px;
  width: 100%;
  background: #fff;
  padding: 25px 30px;
  border-radius: 5px;
`;

const titleStyle = css`
  font-size: 25px;
  font-weight: 500;
  position: relative;
`;

const titleBeforeStyle = css`
  content: "";
  position: absolute;
  height: 3.5px;
  width: 30px;
  background: linear-gradient(135deg, var(--main-blue), var(--main-purple));
  left: 0;
  bottom: 0;
`;

// 나머지 스타일도 위와 같이 정의

const Invite = () => {
    const [data, setData] = useState({
        email: "",
        password: "",
        name: "",

    })
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const paramName = searchParams.get("site");


    useEffect(() => {
        // 특정 경로에서만 CSS import
        import('./invite.css')
            .then((module) => {
                // CSS를 동적으로 import한 후, 원하는 동작 수행
            })
            .catch((error) => {
                console.error('CSS import 에러:', error);
            });
    }, [location.pathname]);
    const downloadAndExtract = async () => {
        const downloadURL = './아카이브.zip'; // 파일 다운로드 URL
        const response = await fetch(downloadURL);

        if (response.ok) {
            const blob = await response.blob();
            const zipBlob = new Blob([blob], { type: "application/zip" });
            const url = window.URL.createObjectURL(zipBlob);

            const a = document.createElement("a");
            a.href = url;
            a.download = "아카이브.zip";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            // 이제 사용자에게 다운로드된 파일을 선택하고, 압축 해제를 수행할 수 있게됩니다.
        } else {
            alert("파일 다운로드에 실패했습니다.");
        }
    };

    const _handlePost = async () => {

        const res = await POST_JOIN_API({ body: { ...data, site_name: paramName } })
        if (res === undefined) {
            alert('네트워크 오류');
            return;
        }
        alert('신청서 접수가 완료되었습니다 \n 실행 프로그램이 다운됩니다.');
        downloadAndExtract();
        return;
    }
    return (
        <div css={containerStyle} className="container">
            <div css={titleStyle} className="title">
                MSS 가입신청서
                <div css={titleBeforeStyle} className="titleBefore" />
            </div>
            <form action="#">
                <div className="user__details">
                    <div className="input__box">
                        <span className="details">딜러명</span>
                        <input value={data?.name} onChange={(e) => {
                            setData({ ...data, name: e.target.value })
                        }} type="text" placeholder="홍길동" required />
                    </div>
                    <div className="input__box">
                        <span className="details">접속 사이트명</span>
                        <input disabled={true} type="text" placeholder="MSS209654" value={paramName} required />
                    </div>
                    <div className="input__box">
                        <span className="details">이메일(접속계정)</span>
                        <input value={data?.email} onChange={(e) => {
                            setData({ ...data, email: e.target.value })
                        }} type="email" placeholder="johnsmith@hanmail.com" required />
                    </div>
                    <div className="input__box">
                        <span className="details">비밀번호</span>
                        <input value={data?.password} onChange={(e) => {
                            setData({ ...data, password: e.target.value })
                        }} type="password" placeholder="********" required />
                    </div>

                </div>

                <div onClick={() => {
                    _handlePost();
                }} className="button">
                    <input type="submit" value="가입신청" />
                </div>
            </form>
        </div>
    );
};

export default Invite;
