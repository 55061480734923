import { atom, useRecoilState } from "recoil";
import { MenuList } from "../MainMenuData";
export const LogoutModalState = atom({
  key: "LogoutModalState",
  default: false,
});

export const dropdownState = atom({
  key: "dropdownState",
  default: Array(MenuList.length).fill(false), // 드롭다운의 초기 상태를 저장하는 배열
});
