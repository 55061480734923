export const POST = {
  POST_LOGIN: "user/login", //로그인
  GET_ME: "user/gemMe", //ME
  POST_NOTICE: "notice/createNotice",
  //공지사항삭제
  POST_INVITER_DELAR: "user/inviteDelar",
  POST_CASH: "user/DelaryRefundByIds",
  DEL_FUND: "user/deleteRefund",
  U_FUND: "user/updateRefefund",
  POST_DB: "aplct/createDB",

  POST_REQ: "user/createRequest",
  JOIN_CENTER: "user/createDealry",
  INPUT_DB: 'aplct/DBInput',
};

export const PUT = {
  PUT_INFO: "user/updateUserInfo",
  PUT_DB: "aplct/updateDB"
};

export const GET = {
  GET_DASH: "user/getdashinfo",
  GET_DB_LIST: "aplct/getDBList",
  GET_DB_INFO: 'aplct/getDBDetail',
  GET_USER: "user/getMyInfo",
  GET_MAIN: "user/getMain",
  GET_DELARY: "user/getDelarList",
  GET_INFO: "user/getDealerInfo",
  GET_NOTICE: "notice/getNoticeList",
  GET_CENTER_LIST: "user/getAllCenter",
  GET_ALL_CENTER: 'user/getCenterList',
  GET_DELARY_STATUS: "user/getDelaryStatus",
};

export const DEL = {
  DEL_NOTICE: "notice/deleteNotice",
  DEL_DELARY: "user/DelaryDelete",
  DEL_APLCT: "aplct/deleteDB"
};
