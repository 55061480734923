import React, { useState } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ChecklistWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
`;

const LeftContent = styled.div`
  width: 40%;
`;

const RightContent = styled.div`
  width: 50%;
  overflow-y: scroll;
  max-height: 150px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
`;

const ChecklistItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: ${(props) => (props.isDragging ? "#e0e0e0" : "white")};
`;

const ChkList = ({ leftContent, rightContent }) => {
  const [checklistItems, setChecklistItems] = useState(rightContent);

  const onDragEnd = (result) => {
    if (!result.destination) return; // 항목을 옮긴 위치가 없으면 종료

    const items = [...checklistItems];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setChecklistItems(items);
  };

  return (
    <ChecklistWrapper>
      <LeftContent>{leftContent}</LeftContent>
      <RightContent>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="checklist">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {checklistItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <ChecklistItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                      >
                        <input
                          type="checkbox"
                          checked={item.checked}
                          onChange={() => {
                            const updatedItems = [...checklistItems];
                            updatedItems[index].checked = !item.checked;
                            setChecklistItems(updatedItems);
                          }}
                        />
                        {item.content}
                      </ChecklistItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </RightContent>
    </ChecklistWrapper>
  );
};

export default ChkList;
