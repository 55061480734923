import React from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

// Keyframes를 사용하여 로딩 애니메이션 정의
const loadingAnimation = keyframes`
  0%, 100% {
    opacity: 0.3;
    color: red;
  }
  25% {
    opacity: 1;
    color: green;
  }
  50% {
    opacity: 0.3;
    color: blue;
  }
  75% {
    opacity: 1;
    color: orange;
  }
`;

// 스타일드 컴포넌트를 사용하여 스타일 적용
const LoadingContainer = styled.div`
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
`;

const LoadingText = styled.span`
  display: inline-block;
  animation: ${loadingAnimation} 2s linear infinite;
`;

const LoadingBar = () => {
  return (
    <LoadingContainer>
      <LoadingText>M</LoadingText>
      <LoadingText>S</LoadingText>
      <LoadingText>S</LoadingText>
    </LoadingContainer>
  );
};

export default LoadingBar;
