export const isItemChecked = ({ checked, id }) => {
  return checked.includes(id);
};

export const handleCheckboxChange = ({ id, setChecked }) => {
  setChecked((prevChecked) => {
    if (prevChecked.includes(id)) {
      // 이미 체크된 경우, 해당 ID를 배열에서 제거
      return prevChecked.filter((item) => item !== id);
    } else {
      // 체크되지 않은 경우, 해당 ID를 배열에 추가
      return [...prevChecked, id];
    }
  });
};

//전체체크 리스트중하나라도 체크된게있으면 모든체크해제 아니면 전부체크

//전체체크로직
export const isAllChecked = ({ data, checked }) => {
  // 모든 아이템의 ID를 추출
  const allItemIds = data.map((item) => item.id);
  // 모든 아이템의 ID가 checked 배열에 포함되어 있는지 확인
  return allItemIds.every((id) => checked.includes(id));
};

export const handleAllSelectChange = ({ data, setChecked, checked }) => {
  console.log(data, "데이터");
  const allItemIds = data?.map((item) => item.id);

  if (isAllChecked({ data, checked })) {
    // 모든 체크 해제
    setChecked([]);
  } else {
    // 전부 체크
    setChecked(allItemIds);
  }
};
