import useSWR from "swr";
import { GET } from "../url";

export const GetDashInfo = () => {
  const { data, error, mutate } = useSWR(() => `${GET.GET_DASH}`);

  return {
    DashInfooData: data?.result,
    DashInfoMutate: mutate,
    DashInfoError: !data && !mutate,
    isLoading: !data && !error,
  };
};

//GET_CENTER_LIST


//GET_ALL_CENTER

export const GET_USER_INFO_SWR = () => {
  const { data, error, mutate } = useSWR(() => `${GET.GET_USER}`);

  return {
    UserINfoData: data?.result,
    UserINfoMutate: mutate,
    UserINfoError: !data && !mutate,
    isLoading: !data && !error,
  };
};

//GET_USER

export const GetAllCenter = () => {
  const { data, error, mutate } = useSWR(() => `${GET.GET_ALL_CENTER}`);

  return {
    AllCenterData: data?.result,
    AllCenterMutate: mutate,
    AllCenterError: !data && !mutate,
    isLoading: !data && !error,
  };
};

export const GET_CENTER_LIST_SWR = () => {
  const { data, error, mutate } = useSWR(() => `${GET.GET_CENTER_LIST}`);

  return {
    CenterListData: data?.result,
    CenterListMutate: mutate,
    CenterListoError: !data && !mutate,
    isLoading: !data && !error,
  };
};

//GET_INFO

export const GetDInfo = () => {
  const { data, error, mutate } = useSWR(() => `${GET.GET_INFO}`);

  return {
    InfoData: data?.result,
    InfoMutate: mutate,
    InfoError: !data && !mutate,
    isLoading: !data && !error,
  };
};

//GET_NOTICE

export const GET_SITE_SWR = ({ site }) => {
  const { data, error, mutate } = useSWR(() => `${GET.GET_MAIN}?site=${site}`);

  return {
    SiteData: data?.result,
    SiteMutate: mutate,
    SiteError: !data && !mutate,
    isLoading: !data && !error,
  };
};

//GET_MAIN


//GET_DB_LIST 
export const GET_DB_INFO = ({ id }) => {
  const { data, error, mutate } = useSWR(
    () =>
      `${GET.GET_DB_INFO}/${id}`
  );

  return {
    DBInfoData: data?.result,
    DBInfoMutate: mutate,
    DBInfoListError: !data && !mutate,
    isLoading: !data && !error,
  };
};
export const GET_DB_LIST_SWR = ({ offset, limit, search }) => {
  const { data, error, mutate } = useSWR(
    () =>
      `${GET.GET_DB_LIST
      }?offset=${offset}&limit=${limit} ${search !== "" && `&search=${search}`
      }`
  );

  return {
    DBListData: data?.result,
    DBListMutate: mutate,
    NoticeListError: !data && !mutate,
    isLoading: !data && !error,
  };
};

export const GET_NOTICE_SWR = ({ offset, limit, centerId, filter, search }) => {
  const { data, error, mutate } = useSWR(
    () =>
      `${GET.GET_NOTICE
      }?offset=${offset}&limit=${limit}&centerId=${centerId}&filter=${filter}&search=${search === "" ? null : search
      }`
  );

  return {
    NoticeListData: data?.result,
    NoticeListMutate: mutate,
    NoticeListError: !data && !mutate,
    isLoading: !data && !error,
  };
};

//GET_DELARY_STATUS
export const GET_DELARY_STATUS_SWR = ({
  offset,
  limit,
  centerId,
  filter,
  search,
}) => {
  const { data, error, mutate } = useSWR(
    () =>
      `${GET.GET_DELARY_STATUS
      }?offset=${offset}&limit=${limit}&centerId=${centerId}&filter=${filter}&search=${search === "" ? null : search
      }`
  );

  return {
    DL_Data: data?.result,
    DL_Mutate: mutate,
    DL_Error: !data && !mutate,
    isLoading: !data && !error,
  };
};

//GET_DELARY
export const GET_DELARY_SWR = ({ offset, limit, centerId, filter, search }) => {
  const { data, error, mutate } = useSWR(
    () =>
      `${GET.GET_DELARY
      }?offset=${offset}&limit=${limit}&centerId=${centerId}&filter=${filter}&search=${search === "" ? null : search
      }`
  );

  return {
    DelaryListData: data?.result,
    DelaryListMutate: mutate,
    DelaryListError: !data && !mutate,
    isLoading: !data && !error,
  };
};
