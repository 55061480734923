
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GenTop from "../../../Component/GenTop";
import ImageUploadFormRow from "../../../Component/Table/ImageUploadFormRow";
import TableButton from "../../../Component/Table/TableButton";
import ChckRow from "../../../Component/TableIn/ChckRow";
import ChkList from "../../../Component/TableIn/ChkList";
import Editor from "../../../Component/TableIn/Editor";
import FormRow, { FormRowDate, FormRowDate2, FormRowDateSingle, FormRowDateSingle2, FormRowFileUpload, FormRowSelect, FormRowSelect2, FormRowYMD } from "../../../Component/TableIn/FromRow";
import TextCheckboxFormRow from "../../../Component/TableIn/TextCheckboxFormRow";
import TextRadioFormRow from "../../../Component/TableIn/TextRadioFormRow";
import { AGERANGETYPE, AGGREEJSON, CNSLAPLCTTYPEJSON, CTPRVCODEJSON, DBTYPEJSON, DRIVEASSENS, GENDERJSON, INPUTDB, INSURANCE_COMPANIESJSON, MEDIACODEJSON, MONTHDATA, PRDCTKINDJSON, QUIRYTYLEJSON } from "../../../Data/DBDATA";
import { POST_INPUT_DB, POST_IN_DB, POST_NOTICE_API, PUT_IN_DB } from "../../../Data/POST";
import { GET_DB_INFO } from "../../../Data/SWR";
import { WrapperDash } from "../../../Styled/DashBoardGlobal";
import { TableWrap } from "../GeneralSettings";



import Spreadsheet from "react-spreadsheet";

const parseDate = (dateString) => {
    // Assuming dateString is in the format "YYYYMMDD"
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1; // Month is 0-based in JavaScript Date
    const day = dateString.substring(6, 8);

    return new Date(year, month, day);
};
const DBEDIT = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({
        interfaceType: "",
        inflowRqstClass: "",
        timestamp: "",
        dbType: "",


        cnslAplctType: "",

        mediaCode: "",


        inflowDt: "",
        name: "",
        brthd: "",
        gender: "",
        clphnNo: "",

        ctprvCode: "",


        adtnInfo1: "",

        prvcyUseAgrmnYn: "",
        prvcyUseAgrmnDate: "",
        prvcyUseAgrmnPeriod: "",
        prvcyUseAgrmnType: "",

        cnslRqstCntnt: "",

        recoardFile: "",
    });

    console.log(data, '데이터')



    const { type } = useParams();



    const { DBInfoData, DBInfoListError, DBInfoMutate } = GET_DB_INFO({ id: type })


    const _inputDB = async () => {
        if (type !== "edit") {
            await POST_INPUT_DB({ body: { id: data?.id } })
            alert('유입처리 완료')
        }
    }
    const _handleCreate = async () => {
        const restoredata = { ...data };
        delete restoredata.timestamp;
        restoredata.inflowDt = moment(data?.inflowDt).format('YYYY-MM-DD HH:mm:ss');
        restoredata.brthd = moment(data?.brthd, 'YYYYMMDD').format('YYYYMMDD');
        restoredata.prvcyUseAgrmnDate = moment(data?.prvcyUseAgrmnDate, 'YYYYMMDD').format('YYYYMMDD');


        const res = await POST_IN_DB({ body: restoredata });

        if (res === undefined) {
            alert("네트워크 연결을 확인해주세요");
            return;
        }
        /*    alert("등록성공");
           navigate(-1); // 뒤로 가기 */
    };
    const _handleUpdate = async () => {
        const restoredata = { ...data };
        delete restoredata.timestamp;
        restoredata.inflowDt = moment(data?.inflowDt).format('YYYY-MM-DD HH:mm:ss');
        restoredata.brthd = moment(data?.brthd, 'YYYYMMDD').format('YYYYMMDD');
        restoredata.prvcyUseAgrmnDate = moment(data?.prvcyUseAgrmnDate, 'YYYYMMDD').format('YYYYMMDD');

        const res = await PUT_IN_DB({ body: restoredata, id: restoredata?.id });

        if (res === undefined) {
            alert("네트워크 연결을 확인해주세요");
            return;
        }
        alert("등록성공");
        navigate(-1); // 뒤로 가기
    };

    console.log(DBInfoData, '데이터ㅏ')

    useEffect(() => {
        // Assuming DBInfoData is the data you retrieve from the database
        // and it has the same structure as the local state 'data'
        if (DBInfoData && type !== "edit") {
            // Manipulate the data before setting it in the state
            const restoreData = { ...DBInfoData };
            restoreData.inflowDt = moment(DBInfoData.inflowDt).format('YYYY-MM-DD HH:mm:ss');
            restoreData.brthd = moment(DBInfoData.brthd).format('YYYY-MM-DD');
            restoreData.giftTmlmPymnt = moment(DBInfoData.giftTmlmPymnt).format('YYYYMMDD');
            restoreData.prvcyUseAgrmnDate = moment(DBInfoData.prvcyUseAgrmnDate).format('YYYY-MM-DD');



            // Set the manipulated data in the local state
            setData({ ...restoreData });
        }
    }, [DBInfoData, type]);
    return (
        <WrapperDash>
            <GenTop
                title={"DB"}
                SubTitle={"DB 관리"}
                SubTitle2={`DB ${type === "edit" ? "등록" : "수정"}`}
            />
            <div
                style={{
                    marginTop: 16,
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                {type !== "edit" &&
                    <>
                        {DBInfoData?.isSend ? <div style={{ marginRight: 16 }}>
                            <TableButton
                                color={{ active: "black", none: "pink" }}

                                text={"유입완료"}
                            />
                        </div> :
                            <div style={{ marginRight: 16 }}>
                                <TableButton
                                    color={{ active: "red", none: "black" }}

                                    event={() => {
                                        _inputDB();
                                    }}
                                    text={"DB 유입"}
                                />
                            </div>
                        }

                    </>
                }
                <TableButton
                    color={{ active: "red", none: "black" }}
                    event={() => {
                        if (type === "edit") {
                            _handleCreate();


                        } else { _handleUpdate() }

                    }}
                    text={`DB ${type === "edit" ? "등록" : "수정"}`}
                />

            </div>

            <TableWrap>
                <div style={{ overflowX: 'scroll' }} >
                    <div className="Spreadsheet">
                        <table className="Spreadsheet__table">
                            <colgroup>
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="Spreadsheet__header" tabIndex={0} />
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        InterfaceType
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        유입요청구분
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        요청시간
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        DB유형
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        상담신청유형
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        매체코드
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        유입일시
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        이름
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        생년월일
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        성별
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        휴대전화
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        시도코드
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        상세주소
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        개인정보활용동의여부
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        개인정보활용동의일
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        개인정보활용동의기간
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        개인정보활용동의유형
                                    </th>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        상담요청내용
                                    </th>

                                </tr>
                                <tr row={0}>
                                    <th className="Spreadsheet__header" tabIndex={0}>
                                        납품정보
                                    </th>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer"><input disabled={true} style={{ width: '100%', border: 'none', outline: 'none' }} /></span>
                                    </td>
                                    <td
                                        className="Spreadsheet__cell Spreadsheet__cell--readonly"
                                        tabIndex={0}
                                    >
                                        <span className="Spreadsheet__data-viewer"><input disabled={true} style={{ width: '100%', border: 'none', outline: 'none' }} /> </span>
                                    </td>
                                    <td
                                        className="Spreadsheet__cell Spreadsheet__cell--readonly"
                                        tabIndex={0}
                                    >
                                        <span className="Spreadsheet__data-viewer"><input disabled={true} style={{ width: '100%', border: 'none', outline: 'none' }} /> </span>
                                    </td>
                                    <td
                                        className="Spreadsheet__cell Spreadsheet__cell--readonly"
                                        tabIndex={0}
                                    >
                                        <span className="Spreadsheet__data-viewer">
                                            <FormRowSelect2
                                                formName="제목 입력"
                                                isTop={true}
                                                selectArray={DBTYPEJSON}
                                                value={data.dbType}
                                                event={(e) => {
                                                    setData({ ...data, dbType: e.target.value });
                                                }}

                                            />
                                        </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer">

                                            <FormRowSelect2
                                                formName="제목 입력"
                                                isTop={true}
                                                selectArray={CNSLAPLCTTYPEJSON}
                                                value={data?.cnslAplctType}
                                                event={(e) => {
                                                    setData({ ...data, cnslAplctType: e.target.value });
                                                }}

                                            />


                                        </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer">


                                            <FormRowSelect2
                                                formName="제목 입력"
                                                isTop={true}
                                                selectArray={MEDIACODEJSON}
                                                value={data?.mediaCode}
                                                event={(e) => {
                                                    setData({ ...data, mediaCode: e.target.value });
                                                }}

                                            /> </span>


                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer">
                                            <FormRowDate2
                                                formName="제목 입력"
                                                isTop={true}
                                                selectArray={DRIVEASSENS}
                                                value={data?.inflowDt}
                                                event={(e) => {
                                                    setData({ ...data, inflowDt: e.target.value });
                                                }}

                                            />

                                        </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer"><input
                                            type="text"
                                            value={data?.name}
                                            onChange={(e) => {
                                                setData({ ...data, name: e.target.value })
                                            }}
                                            style={{ width: '100%', border: 'none', outline: 'none' }} /> </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer">
                                            <FormRowDateSingle2 formName="제목 입력"
                                                isTop={true}
                                                selectArray={MONTHDATA}
                                                value={data?.brthd}
                                                event={(e) => {
                                                    setData({ ...data, brthd: e.target.value });
                                                }}

                                            />
                                        </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer">
                                            <FormRowSelect2
                                                formName="제목 입력"
                                                isTop={true}
                                                selectArray={GENDERJSON}
                                                value={data?.gender}
                                                event={(e) => {
                                                    setData({ ...data, gender: e.target.value });
                                                }}

                                            />
                                        </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer"><input type="text" value={data?.clphnNo}
                                            onChange={(e) => {
                                                setData({ ...data, clphnNo: e.target.value })
                                            }}
                                            style={{ width: '100%', border: 'none', outline: 'none' }} /> </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer">
                                            <FormRowSelect2
                                                formName="제목 입력"
                                                isTop={true}
                                                selectArray={CTPRVCODEJSON}
                                                value={data?.ctprvCode}
                                                event={(e) => {
                                                    setData({ ...data, ctprvCode: e.target.value });
                                                }}

                                            />
                                        </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer"><input

                                            value={data?.adtnInfo1}
                                            onChange={(e) => {
                                                setData({ ...data, adtnInfo1: e.target.value })
                                            }}
                                            style={{ width: '100%', border: 'none', outline: 'none' }} /> </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer">

                                            <FormRowSelect2
                                                formName="제목 입력"
                                                isTop={true}
                                                selectArray={AGGREEJSON}
                                                value={data?.prvcyUseAgrmnYn}
                                                event={(e) => {
                                                    setData({ ...data, prvcyUseAgrmnYn: e.target.value });
                                                }}

                                            />
                                        </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer">

                                            <FormRowDateSingle2 formName="제목 입력"
                                                isTop={true}
                                                selectArray={MONTHDATA}
                                                value={data?.prvcyUseAgrmnDate}
                                                event={(e) => {
                                                    setData({ ...data, prvcyUseAgrmnDate: e.target.value });
                                                }}

                                            />
                                        </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer">
                                            <FormRowSelect2
                                                formName="제목 입력"
                                                isTop={true}
                                                selectArray={MONTHDATA}
                                                value={data?.prvcyUseAgrmnPeriod}
                                                event={(e) => {
                                                    setData({ ...data, prvcyUseAgrmnPeriod: e.target.value });
                                                }}

                                            />
                                        </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer">
                                            <FormRowSelect2
                                                formName="제목 입력"
                                                isTop={true}
                                                selectArray={QUIRYTYLEJSON}
                                                value={data.prvcyUseAgrmnType}
                                                event={(e) => {
                                                    setData({ ...data, prvcyUseAgrmnType: e.target.value });
                                                }}

                                            />
                                        </span>
                                    </td>
                                    <td className="Spreadsheet__cell" tabIndex={0}>
                                        <span className="Spreadsheet__data-viewer"><input vlaue={data?.cnslRqstCntnt} onChange={(e) => {
                                            setData({ ...data, cnslRqstCntnt: e.target.value })
                                        }} style={{ width: '100%', border: 'none', outline: 'none' }} /> </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            className="Spreadsheet__floating-rect Spreadsheet__floating-rect--selected Spreadsheet__floating-rect--hidden"
                            style={{}}
                        />
                        <div className="Spreadsheet__floating-rect Spreadsheet__floating-rect--copied Spreadsheet__floating-rect--hidden" />
                    </div>

                </div>
                <FormRowFileUpload
                    formName="제목 입력"
                    isTop={true}
                    value={data?.recoardFile}
                    event={(e) => {
                        setData({ ...data, recoardFile: e })
                    }}


                    headerName={`녹취파일`}
                />
            </TableWrap>
        </WrapperDash >
    );
};

export default DBEDIT;
