import styled from "@emotion/styled";
import { Checkbox } from "@mui/material";
import moment from "moment";
import React from "react";
import { CTPRVCODEJSON } from "../../Data/DBDATA";
import {
  handleAllSelectChange,
  handleCheckboxChange,
  isAllChecked,
  isItemChecked,
} from "../../Util";

const TableContainer = styled.div`
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
`;

const TableHeader = styled.div`
  background-color: #dfe6ed;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding: 10px;
  align-items: center;
  background-color: white;
`;

const PlusIcon = styled.div`
  background-color: #eee;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out; /* 애니메이션 효과 설정 */

  &:hover {
    transform: scale(1.1); /* 마우스 호버 시 크기를 1.1배로 확대 */
  }
`;
const TableCell = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: ${({ fixedWidth }) =>
    fixedWidth ? "0 0 auto" : "1"}; /* 체크박스 열은 너비 고정 */
  min-width: ${({ fixedWidth }) =>
    fixedWidth ? "100px" : "0"}; /* 원하는 너비로 설정 */
`;

const MainTable = ({
  data,
  headers,
  Action,
  ActionMode,
  CheckMode,
  setChecked,
  checked,
  inputMode,
}) => {
  console.log(data, "데이터");
  return (
    <TableContainer>
      <TableHeader>
        {CheckMode && (
          <TableCell fixedWidth>
            <Checkbox
              checked={isAllChecked({ data, checked })}
              size="small"
              onClick={() => {
                handleAllSelectChange({
                  data: data,
                  setChecked: setChecked,
                  checked: checked,
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </TableCell>
        )}
        {headers?.map((header, index) => (
          <TableCell key={index}>{header.title}</TableCell>
        ))}
        {inputMode && <TableCell>{inputMode}</TableCell>}
        {ActionMode && <TableCell>더보기</TableCell>}
      </TableHeader>

      {data?.map((item, index) => {
        return (
          <TableRow key={index}>
            {CheckMode && (
              <TableCell fixedWidth>
                <Checkbox
                  checked={isItemChecked({ checked, id: item?.id })}
                  size="small"
                  onClick={() => {
                    handleCheckboxChange({
                      id: item?.id,
                      setChecked: setChecked,
                    });
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </TableCell>
            )}

            {headers?.map((header, idx) => {
              let cellValue;
              if (header.key === "USER.name") {
                cellValue = item.USER.name;
              } else if (header.key === "recoardFile.password") {
                cellValue = item.recoardFile.password;
              } else if (header.key === "createdAt") {
                cellValue = moment(item.createdAt).format("YYYY-MM-DD");
              } else if (header.key === "updatedAt") {
                cellValue = moment(item.updatedAt).format("YYYY-MM-DD");
              } else if (header.key === "master") {
                if (item.master === 0) {
                  cellValue = "딜러";
                } else if (item.master === 1) {
                  cellValue = "센터장";
                } else {
                  cellValue = "관리자";
                }
              } else if (header.key === "USER_CENTER.title") {
                cellValue = item?.USER_CENTER?.title;
              } else if (header.key === "isCash") {
                cellValue = item?.isCash ? "정산완료" : "정산전";
              } else if (header.key === "USER_SITE.site_name") {
                cellValue = item?.USER_SITE?.site_name;
              } else if (header.key === "ctprvCode") {
                cellValue = CTPRVCODEJSON.filter((itemx, idxs) => {
                  return itemx.value === item[header.key]; // Returning the result of the condition
              })[0]?.key;

              } else {
                cellValue = item[header.key];
              }

              return <TableCell key={idx}>{cellValue}</TableCell>;
            })}
            {inputMode && (
              <TableCell>
                <input
                  type="number"
                  style={{ width: 100, textAlign: "center" }}
                />
              </TableCell>
            )}
            {ActionMode && (
              <TableCell
                onClick={() => {
                  Action(
                    {
                      id: item?.id
                    }
                  );
                }}
              >
                <PlusIcon>+</PlusIcon>
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </TableContainer>
  );
};

export default MainTable;
