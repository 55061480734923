import axios from "axios";
import { DEL, POST, PUT } from "../url";

export const LOGIN_API = async ({ body }) => {
  let res;
  try {
    res = await axios.post(POST.POST_LOGIN, body);
  } catch {
    res = false;
  }
  return res?.data;
};

//PUT_INFO

export const PUT_INFO_API = async ({ body }) => {
  let res;
  try {
    res = await axios.put(PUT.PUT_INFO, body);
  } catch {
    res = false;
  }
  return res?.data;
};

export const POST_NOTICE_API = async ({ body }) => {
  let res;
  try {
    res = await axios.post(POST.POST_NOTICE, body);
  } catch {
    res = false;
  }
  return res?.data;
};

//POST_REQ

export const POST_REQ_API = async ({ body, site }) => {
  let res;
  try {
    res = await axios.post(`${POST.POST_REQ}?site=${site}`, body);
  } catch {
    res = false;
  }
  return res?.data;
};

export const POST_DEL_FU_API = async ({ body }) => {
  let res;
  try {
    res = await axios.post(POST.DEL_FUND, body);
  } catch {
    res = false;
  }
  return res?.data;
};

export const POST_UP_FU_API = async ({ body }) => {
  let res;
  try {
    res = await axios.post(POST.U_FUND, body);
  } catch {
    res = false;
  }
  return res?.data;
};

//DEL_NOTICE
export const DEL_NOTICE_API = async (body) => {
  let res;
  try {
    // 요청 URL과 옵션 객체를 따로 전달
    res = await axios.delete(DEL.DEL_NOTICE, {
      data: body,
    });
  } catch {
    res = false;
  }
  return res?.data;
};


//POST_DB


export const POST_IN_DB = async ({ body }) => {
  let res;
  try {
    res = await axios.post(POST.POST_DB, body);
  } catch {
    res = false;
  }
  return res?.data;
};

export const PUT_IN_DB = async ({ body, id }) => {
  let res;
  try {
    res = await axios.put(`${PUT.PUT_DB}/${id}`, body);
  } catch {
    res = false;
  }
  return res?.data;
};

export const uploadImage = async (image) => {
  try {
    console.log(image, '이미지')
    const { data } = await axios.post("upload/", image);
    return data[0];
  } catch (err) {
    alert("이미지 업로드 실패");
    console.log(err.response.data);
  }
};

//DEL_APLCT

export const DEL_APLCT_API = async (body) => {
  let res;
  try {
    // 요청 URL과 옵션 객체를 따로 전달
    res = await axios.delete(DEL.DEL_APLCT, {
      data: body,
    });
  } catch {
    res = false;
  }
  return res?.data;
};


export const POST_CASH_API = async ({ body }) => {
  let res;
  try {
    res = await axios.post(POST.POST_CASH, body);
  } catch {
    res = false;
  }
  return res?.data;
};
export const POST_INPUT_DB = async ({ body }) => {
  let res;
  try {
    res = await axios.post(POST.INPUT_DB, body);
  } catch {
    res = false;
  }
  return res?.data;
};
//INPUT_DB

//DEL_NOTICE

//DEL_DELARY
export const DEL_DELARY_API = async (body) => {
  let res;
  try {
    // 요청 URL과 옵션 객체를 따로 전달
    res = await axios.delete(DEL.DEL_DELARY, {
      data: body,
    });
  } catch {
    res = false;
  }
  return res?.data;
};

export const POST_INVITE_API = async ({ body }) => {
  let res;
  try {
    res = await axios.post(POST.POST_INVITER_DELAR, body);
  } catch {
    res = false;
  }
  return res?.data;
};


//JOIN_CENTER

export const POST_JOIN_API = async ({ body }) => {
  let res;
  try {
    res = await axios.post(POST.JOIN_CENTER, body);
  } catch {
    res = false;
  }
  return res?.data;
};


