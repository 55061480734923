import { Login } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Navigate, Route, Router, Routes } from "react-router-dom";
import AdminNav from "../AdminNav";
import AdminLayer from "../AdminNav/AdminLayer";
import { GetMe } from "../Data/GetMe";

import Home from "../Pages/Home";
import Invite from "../Pages/Invite";
import Landing from "../Pages/Landing";
import LoginLoading from "../Pages/LoginLoading";
import LoginPage from "../Pages/LoginPage";
//로딩라이브러리 임포트
/*
 네비게이션 정보를 담습니다 
*/

const Nav = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { profile, loading } = GetMe();
  useEffect(() => {
    // 모의로 3초 후에 로딩 상태 변경
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  if (isLoading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ReactLoading
          type={"spokes"}
          height={50}
          width={50}
          color={"#33a0df"}
        ></ReactLoading>
      </div>
    );
  } else {
    return (
      <Routes>
        
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/invite" element={<Invite />} />
 

        <Route
          path="/login"
          element={profile ? <Navigate to="/admin" /> : <LoginPage />}
        />

        <Route path="/loading" element={<LoginLoading />} />

        <Route
          path="/admin/*"
          element={profile ? <AdminLayer /> : <Navigate to="/login" />}
        />
      </Routes>
    );
  }
};

export default Nav;
