import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled"; // styled import 추가
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterListIcon from "@mui/icons-material/FilterList"; // FilterListIcon import 추가
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material/styles"; // ThemeProvider import 추가

// 새로운 테마 생성
const theme = createTheme({
  palette: {
    primary: {
      main: "#007bff", // 원하는 배경색 설정
    },
  },
});

// styled-components를 사용하여 스타일된 버튼 컴포넌트 생성
const StyledButton = styled.button`
  border: none;
  border-radius: 20px;
  padding: 6px 12px; /* 버튼 크기 조절 */
  text-transform: none;
  font-weight: bold;
  color: white; /* 텍스트 컬러를 흰색으로 설정 */
  background-color: #007bff; /* 백그라운드 색상을 원하는 색상으로 설정 */
  min-width: 100px; /* 버튼 최소 너비 조절 */
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const FilterForm = ({
  setSelectedFilter,
  selectedFilter,
  setAnchorEl,
  anchorEl,
  FilterValue,
}) => {
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = (filter) => {
    setAnchorEl(null);
    setSelectedFilter(filter);
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* 스타일된 버튼 컴포넌트를 사용 */}
        <StyledButton onClick={handleFilterClick}>
          <FilterListIcon style={{ marginRight: 4 }} />
          {`${selectedFilter}`}
          <ArrowDropDownIcon className="filter-icon" />
        </StyledButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {FilterValue?.map((item, idx) => {
            return (
              <MenuItem
                className="menu-item"
                onClick={() => {
                  item?.event();
                  handleFilterClose(item?.text);
                }}
              >
                {item?.text}
              </MenuItem>
            );
          })}

          {/* 필요한 만큼의 메뉴 아이템을 추가하세요 */}
        </Menu>
      </div>
    </ThemeProvider>
  );
};

export default FilterForm;
