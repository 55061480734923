import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import ReactLoading from "react-loading";
import AsideNav from "../Component/ASideNav";
import Header from "../Component/Header";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useTheme } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import Invite from "../Pages/DEALER/invite";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LogoutModal from "../Modal/LogoutModal";
import { useRecoilState } from "recoil";
import { LogoutModalState } from "../Data/Store";
import NoticeView from "../Pages/Admin/NoticeView";
import DashBoard from "../Pages/Admin/DashBoard";
import DealerManagement from "../Pages/Admin/DealerManagement";
import ReceptionManagement from "../Pages/Admin/ReceptionManagement";
import GeneralSettings from "../Pages/Admin/GeneralSettings";
import NoticeManage from "../Pages/Admin/NoticeManage";
import ApplicationManagement from "../Pages/Admin/ApplicationManagement";
import LoadingBar from "../Component/LoadingBar";
import NoticeEdit from "../Pages/Admin/Notice/NoticeEdit";
import DealerRefund from "../Pages/Admin/DealerRefund";
import Center from "../Pages/Admin/Center";
import Reception from "../Pages/Admin/Reception";
import DBManageMent from "../Pages/Admin/DBManageMent";
import DBEDIT from "../Pages/Admin/DBEDIT";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  content: {
    flexGrow: 1,
    padding: "12px 16px 0px 260px",
  },
  contentM: {
    flexGrow: 1,
    padding: 24,
  },
  toolbar: theme.mixins.toolbar,
}));

const AdminLayer = ({
  onDrawerToggle,
  mobileOpen,
  handleDrawerToggleFasle,
}) => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useRecoilState(LogoutModalState);
  const [isAnimating, setIsAnimating] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // 로딩 스피너 상태 추가

  useEffect(() => {
    setIsAnimating(false);
    setIsLoading(true); // 로딩 스피너를 보이도록 설정
    setTimeout(() => {
      setIsAnimating(true);
      setIsLoading(false); // 로딩 스피너를 감추도록 설정
    }, 500);
  }, [location]);

  return (
    <div className={classes.root}>
      <LogoutModal
        isOpen={isOpen}
        onConfirmLogout={() => {
          setIsOpen(false);
        }}
        onRequestClose={() => {
          setIsOpen(false);
        }}
      />
      <CssBaseline />
      <Header onDrawerToggle={onDrawerToggle} />
      <AsideNav
        handleDrawerToggleFasle={handleDrawerToggleFasle}
        mobileOpen={mobileOpen}
        onDrawerToggle={onDrawerToggle}
      />
      <main className={isMobile ? classes.contentM : classes.content}>
        <div className={classes.toolbar} />
        {/* Router Inner */}
        {isLoading ? ( // 로딩 중인 경우 로딩 스피너를 표시
          <div
            style={{
              width: "100%",
              height: 800,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBar
              type={"spokes"}
              height={50}
              width={50}
              color={"#33a0df"}
            ></LoadingBar>
          </div>
        ) : (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={300} classNames="fade">
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/admin/settings/dashboard" replace />}
                />
                <Route path="/notice/manage" element={<NoticeManage />} />
                <Route path="/notice/edit" element={<NoticeEdit />} />
                <Route path="/db/:type" element={<DBEDIT />} />
                <Route path="/db/DBManageMent" element={<DBManageMent />} />
                <Route path="/settings/dashboard" element={<DashBoard />} />
                <Route path="/settings/general" element={<GeneralSettings />} />
                <Route path="/members/dealers" element={<DealerManagement />} />
                <Route path="/members/invite" element={<Invite />} />
                <Route path="/center/center" element={<Center />} />
                <Route path="/center/reception" element={<Reception />} />
                <Route
                  path="/members/dealersrefund"
                  element={<DealerRefund />}
                />
                <Route
                  path="/center/reception"
                  element={<ReceptionManagement />}
                />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        )}
      </main>
    </div>
  );
};
export default AdminLayer;
