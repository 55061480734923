import styled from "@emotion/styled";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ProfileState } from "../Data/GetMe";
import { LOGIN, LOGIN_API } from "../Data/POST";
import { setStoarge } from "../Datas";

const BackContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #080710;
`;
const Background = styled.div`
  width: 430px;

  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
`;

const Shape = styled.div`
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
`;

const Shape1 = styled(Shape)`
  background: linear-gradient(#1845ad, #23a2f6);
  left: -80px;
  top: -80px;
`;

const Shape2 = styled(Shape)`
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -30px;
  bottom: -80px;
`;

const Form = styled.form`
  height: 520px;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  color: #fff;
  font-weight: bold;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
`;

const H3 = styled.h3`
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
`;

const Label = styled.label`
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
`;
const Input = styled.input`
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border: none; /* 테두리 제거 */
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  color: white; /* 글자색을 흰색으로 설정 */
  outline: none; /* 포커스 스타일 제거 */
`;

const Button = styled.button`
  margin-top: 50px;
  width: 100%;
  background-color: #ffffff;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const Social = styled.div`
  margin-top: 30px;
  display: flex;
`;

const SocialDiv = styled.div`
  background: red;
  width: 150px;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255, 255, 255, 0.27);
  color: #eaf0fb;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.47);
  }
`;

const GoogleDiv = styled(SocialDiv)`
  margin-left: 25px;
`;

const FacebookDiv = styled(SocialDiv)`
  margin-left: 25px;
`;

const LoginPage = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [profile, setProfile] = useRecoilState(ProfileState);
  //로그인 API
  const Navigate = useNavigate();

  const _handleLogin = async () => {
    const res = await LOGIN_API({ body: data });
    if (res === false || res === undefined) {
      alert("잘못된 로그인 정보입니다");
      return;
    }
    console.log(res, "레스");
    await setStoarge("jwt", res?.data?.jwt); //로그인후 저장 메인이동
    setProfile(res?.data?.jwt);
    Navigate("/admin");
  };

  return (
    <BackContainer>
      <Background>
        <Shape1 />
        <Shape2 />
        <Form>
          <H3>로그인</H3>
          <Label htmlFor="username">아이디</Label>
          <Input
            type="text"
            placeholder="이메일을 입력해주세요."
            id="username"
            value={data?.email}
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
            }}
          />
          <Label htmㄴlFor="password">비밀번호</Label>
          <Input
            type="password"
            placeholder="비밀번호를 입력해주세요"
            id="password"
            value={data?.password}
            onChange={(e) => {
              setData({ ...data, password: e.target.value });
            }}
          />

          <Button
            type="button"
            onClick={() => {
              _handleLogin();
            }}
          >
            로그인
          </Button>
        </Form>
      </Background>
    </BackContainer>
  );
};

export default LoginPage;
