import styled from "@emotion/styled";
import React, { useState } from "react";
import { CloudUpload } from "@mui/icons-material"; // Material-UI 아이콘
import Button from "@mui/material/Button"; // Material-UI 버튼
import CloseIcon from "@mui/icons-material/Close"; // Material-UI 닫기 아이콘
import TableModal from "../TableIn/TableModal";
const FormRowWrapper = styled.div`
  // ... 이전 스타일 코드 ...
  background-color: #ffffff;
 
  border-radius: 5px;
 
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: -11px;
  h2 {
    font-size: 16px;
    padding-bottom: 10px;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    padding-top: 8px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    padding: 5px;
    border: 1px solid #ddd;
  }

  input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .description {
    color: red; /* 빨간색 텍스트 색상 */
  }

  /* 입력 필드 2의 공간을 설명 공간보다 크게 설정 */
  .input-field-2 {
    height: 40px; /* 원하는 높이로 조정하세요 */
  }
  .image-upload-container {
    display: flex;
    align-items: center;
    position: relative; // 포지션을 상대 포지션으로 설정
  }

  .image-upload-button {
    display: inline-block;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 10px;
  }

  .delete-icon {
    position: absolute; // 포지션을 절대 포지션으로 설정
    top: 5px; // 원하는 위치로 조정
    right: 1005px; // 원하는 위치로 조정
    cursor: pointer;
    opacity: 0; // 처음에는 투명도를 0으로 설정
    transition: opacity 0.3s; // 애니메이션 효과 추가
  }

  .image-upload-container:hover .delete-icon {
    opacity: 1; // 호버 시 투명도를 1로 변경
  }
  .image-upload-container {
    cursor: pointer;
  }
  .image-container .image-hovered {
    opacity: 0.8; /* 호버 시 투명도를 변경 */
  }
`;

const ImageUploadFormRow = ({
  formName,
  headerName,
  placeholder,
  description,
  isError,
  isTop,
}) => {
  const [imageUrl, setImageUrl] = useState("https://mond-al.github.io/assets/images/forTest/ratio/all_ratio.png"); // State to store the image URL
  const [imageHovered, setImageHovered] = useState(false);
  // Function to handle image upload

  const [isModalOpen, setIsModalOpen] = useState("");

  // 이미지 클릭 시 모달 열기
  const handleImageClick = (imageUrl) => {
    setIsModalOpen(true);
  };

  // 모달 닫기
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleImageUpload = (event) => {
    // Here, you can implement logic to upload the image and set the imageURL state.
    // For now, we'll just set a placeholder URL.
    setImageUrl(
      "https://mond-al.github.io/assets/images/forTest/ratio/all_ratio.png"
    ); // Placeholder URL
  };

  // Function to handle image removal
  const handleImageRemove = () => {
    setImageUrl(""); // Clear the image URL
  };

  return (
    <FormRowWrapper>
      <table>
        <tbody>
          <tr>
            <td
              width={"15%"}
              style={{
                backgroundColor: "#dfe6ed",
                paddingLeft: 16,
                verticalAlign: "middle", // 수직 정렬 추가
              }}
            >
              {headerName}
            </td>
            <td style={{ padding: 8, paddingLeft: 16 }}>
              <div className="image-upload-container">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudUpload />}
                  onClick={handleImageUpload}
                >
                  Upload Image
                </Button>
                {/*    {imageUrl && (
                  <div className="delete-icon" onClick={handleImageRemove}>
                    <CloseIcon fontSize="small" />
                  </div>
                )} */}
                <div
                  className={imageUrl ? "image-container" : ""}
                  onMouseEnter={() => setImageHovered(true)}
                  onClick={() => {
                    handleImageClick();
                  }}
                  onMouseLeave={() => setImageHovered(false)}
                >
                  <img
                    src={imageUrl}
                    alt="Uploaded"
                    style={{ maxWidth: 150 }}
                    className={imageHovered ? "image-hovered" : ""}
                  />
                </div>
                <TableModal open={isModalOpen} onClose={handleCloseModal}>
                  <img
                    src={imageUrl}
                    alt="Modal"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </TableModal>
              </div>
            </td>
          </tr>
          {isError && (
            <tr>
              <td colSpan={2} className="description">
                {description}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </FormRowWrapper>
  );
};

export default ImageUploadFormRow;
