import React, { useState } from "react";

const TableButton = ({ text, color, event }) => {
  const [active, setActive] = useState(false);
  const buttonStyle = {
    backgroundColor: color.none, // 초기 배경색
    color: "white",
    border: "none",
    borderRadius: "20px",
    padding: "8px 16px",
    fontWeight: "bold",
    minWidth: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // 텍스트를 가운데 정렬
    cursor: "pointer",
    transition: "background-color 0.3s", // CSS 트랜지션을 사용한 애니메이션
  };

  const buttonHoverStyle = {
    backgroundColor: color.active, // 호버시 배경색 변경
  };

  return (
    <div>
      <button
        style={{ ...buttonStyle, ...(active && buttonHoverStyle) }}
        onClick={() => {
          event();
        }}
        onMouseEnter={() => setActive(true)} // 호버 이벤트에 event() 함수 호출 추가
        onMouseLeave={() => setActive(false)} // 호버에서 벗어날 때 active 상태 해제
      >
        {text}
      </button>
    </div>
  );
};

export default TableButton;
