import styled from "@emotion/styled";

export const AdminSettingArea = styled.div`
  height: 100vh;
  padding: 20px 50px 0;
`;

export const SettingTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 25px 0;
`;

export const SettingContentArea = styled.div`
  width: 100%;
  margin-top:30px;
  font-size: 15px;
  .title {
    width: 100%;
    min-width: 1080px;
    height: 40px;
    border: 1px solid #ccc;
    border-bottom: 0;
    background-color: #fff;
    padding: 0 15px;
    line-height: 40px;
  }
  & > table {
    width: 100%;
    min-width: 1080px;
    background-color: #fff;
  }
  & > table > thead > tr > span {
    padding-left: 15px;
    color: #000;
    font-size: 15px;
  }
  & > table > thead > tr {
    border: 1px solid #ccc;
    height: 40px;
    line-height: 37px;
    padding-left: 15px;
  }
  & > table > thead > tr > th:first-child {
    width: 15%;
    height: 40px;
    border: 1px solid #ccc;
    text-align: left;
    padding-left: 15px;
    line-height: 37px;
    background: #ddd;
    font-size: 15px;
    color: #222;
    vertical-align: middle;
  }
  & > table > thead > tr > th:last-child {
    width: 85%;
    border: 1px solid #ccc;
    text-align: left;
    padding-left: 15px;
    font-size: 15px;
  }
  & > table > thead > tr > th > input {
    border: 0;
    width: 30%;
    height: 25px;
    border: 1px solid #bbb;
    background: transparent;
    outline: none;
    padding: 0 5px;
    ::placeholder {
      font-size: 15px;
    }
  }
  & > table > thead > tr > th:last-child div {
    margin-left: 10px;
    display: inline-block;
    color: #999;
  }
  & > table > thead > tr > th:last-child button {
    cursor: pointer;
    display: inline-block;
    width: 110px;
    height: 28px;
    border: 1px solid #999;
  }
  .wrap > span {
    display: block;
    margin-top: -10px;
    font-size: 14px;
  }
  .firstTable {
    margin-bottom: 50px;
  }
  .lastTable {
    margin-bottom: 35px;
  }
  .last {
    margin-top: 50px;
  }
  .radio {
    display: flex;
    align-items: center;
    & label {
      margin-right: 40px;
    }
    & input {
      accent-color: #000;
    }
  }
`;


export const TintBox = styled.p`
  color: red;
`;
export const ButtonArea = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
 
  & > button {
    width: 150px;
    height: 35px;
    line-height: 28px;
    color: #000;
    border-radius: 8px;
    border: 1px solid #bbb;
    margin: 0 10px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.2s;
    &:hover {
      background-color: #000;
      color: #fff;
      transform: scale(1.05);
    }
  }
   
  .black {
    background-color: #000;
    color: #fff;
  }
  .black.detail {
    width: 150px;
    height: 35px;
    border: 1px solid #000;
    cursor: pointer;
    border-radius: 9px;
  }
  .gray {
    background-color: #ddd;
    border: 1px solid #bbb;
  }
  &.detail {
    margin-top: 30px;
  }
`;



export const AdminHomeArea = styled.div`
  padding: 20px 50px 0;
`;

export const AdminHomeTop = styled.div`
  display: flex;
  .pathBtn {
    text-decoration: none;
  }
  .pathBtn > .active {
    background-color: #f73d79 !important;
    color: #fff !important;
    border: 1px solid #f73d79 !important;
  }
  .pathBtn > div {
    background-color: #fff;
    height: 45px;
    width: 180px;
    line-height: 45px;
    text-align: center;
    border: 1px solid #ccc;
    font-weight: 600;
  }
  .pathBtn:nth-child(1) div {
    color: #000;
  }
  .pathBtn:nth-child(2) div {
    background-color: #dadada;
    border: 1px solid #bbb;
    margin-left: -1px;
    text-align: center;
    line-height: 45px;
    color: #666;
  }
  .pathBtn:nth-child(3) div {
    background-color: #dadada;
    border: 1px solid #bbb;
    margin-left: -1px;
    text-align: center;
    line-height: 45px;
    color: #666;
  }
  .pathBtn:nth-child(4) div {
    background-color: #dadada;
    border: 1px solid #bbb;
    margin-left: -1px;
    text-align: center;
    line-height: 45px;
    color: #666;
  }
`;

export const AdminHomeSub = styled.div`
  margin-top: 13px;
  display: flex;
  align-items: center;
  & > img {
    height: 18px;
    margin-right: 5px;
  }
  & > span {
    margin: 0 4px;
  }
`;

export const AdminHomeBody = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div:nth-child(1) {
    width: 35%;
    min-width: 400px;
    padding: 5px 20px;
  }
  & > div {
    text-align: center;
    width: 20%;
    height: 325px;
    min-width: 300px;
    background-color: #fff;
    margin-right: 15px;
  }
`;

export const NoticeArea = styled.div`
  & > ul > li {
    width: 100%;
    justify-content: space-between;
    display: flex;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
  }
  & > div {
    display: flex;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ddd;
    width: 108%;
    margin-left: -20px;
    padding: 0 20px;
  }
  & > div > img {
    height: 20px;
    margin-top: 10px;
  }
  & > div > h1 {
    font-size: 17px;
    font-weight: bold;
  }
`;

export const TotalGumae = styled.div`
  display: flex;
  flex-direction: column;
  & > h1 {
    text-align: left;
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    font-size: 17px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
  }
  & > div.top {
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 2px solid #ccc;
    width: 90%;
    margin: 0 auto;
    & > span:first-child {
      font-size: 16px;
      color: #666;
      font-weight: bold;
      text-align: left;
      margin-left: 15px;
    }
    & > span:last-child {
      font-size: 50px;
      color: #0095f6;
      font-weight: bold;
    }
  }
  & > div.bottom {
    height: 130px;
    line-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 30px;
    padding: 0 40px;
    color: #333;
    & > div {
      display: flex;
      justify-content: space-between;
    }
    & > div > span:first-child {
      font-size: 19px;
      margin-right: 15px;
      color: #666;
      font-weight: 600;
    }
  }
`;

/*
        backgroundColor: "#ddd",
                paddingLeft: 26,
                paddingRight: 26,
                marginRight:12,
                borderRadius: 6,
                paddingTop: 5,
                paddingBottom: 5,

*/
export const LableFor = styled.label`
  background-color: #ddd;
  padding-left: 26px;
  padding-right: 26px;
  margin-right: 12px;
  border-radius: 6px;
  padding-top: 5px;
  padding-bottom: 5px;

  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

export const SettingTopBtnArea = styled.div`
  width: 100%;
  display: flex;
  margin-top:16px;
  align-items: center;
  justify-content: space-between;
  & > button {
    width: 150px;
    height: 30px;
    line-height: 26px;
    color: #fff;
    border: 1px solid #000;
    background-color: #000;
    font-size: 15px;
    cursor: pointer;
  }
  & > .noticeAdd > button {
    width: 150px;
    height: 30px;
    border: 1px solid #000;
    background-color: #000;
    cursor: pointer;
    color: #fff;
  }
`;



export const SettingBottomArea = styled.div`
  width: 56%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  &.noticeAdd {
    width: 100%;
  }
  &.noticeAdd .noticeAddBtn {
    text-align: right;
  }
  &.noticeAdd .save {
    width: 120px !important;
  }
`;


export const SettingBottomBtnArea = styled.div`
  width: 100%;
  & > span {
    font-size: 16px;
  }
  & > button {
    width: 130px;
    height: 30px;
    line-height: 26px;
    color: #000;
    border: 1px solid #bbb;
    background-color: #ddd;
    margin: 0 15px;
    font-size: 15px;
    cursor: pointer;
  }
  &.brand > button {
    margin: 0;
  }
  &.brand > button,
  &.brand .edit > button {
    width: 160px;
    height: 30px;
    line-height: 26px;
    color: #000;
    border: 1px solid #bbb;
    background-color: #ddd;
    font-size: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
  &.brand .save {
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
    width: 140px;
  }
  &.brand .del {
    background-color: #f73d79;
    border: 1px solid #f73d79;
    color: #fff;
    width: 120px;
  }
  &.brand .edit > button {
    background-color: #199dd9;
    border: 1px solid #199dd9;
    color: #fff;
    width: 120px;
  }
  &.brand .list > button {
    background-color: #ddd;
    border: 1px solid #bbb;
    color: #000;
    width: 120px;
    height: 30px;
    line-height: 28px;
    cursor: pointer;
  }
`;
export const SettingNoticeArea = styled.div`
  width: 100%;
  min-width: 1280px;
  .title {
    width: 100%;
    min-width: 1080px;
    height: 50px;
    border: 1px solid #ccc;
    border-bottom: 0;
    background-color: #fff;
    padding: 0 15px;
    line-height: 50px;
    & > strong {
      color: red;
    }
  }
  .firstTable > thead > tr > th:first-child {
    width: 15%;
    background: #ddd;
  }
  .firstTable > thead > tr > th:last-child {
    width: 85%;
    text-align: left;
    padding-left: 20px;
    position: relative;
    & > input {
      width: 350px;
      height: 28px;
      border: 1px solid #bbb;
      outline: none;
      padding-left: 5px;
    }
    & > select {
      height: 28px;
      width: 100px;
      margin-right: 5px;
      text-align: center;
      border: 1px solid #bbb;
    }
    & > img {
      width: 20px;
      height: 20px;
      margin-left: -25px;
      position: absolute;
      top: -3px;
      cursor: pointer;
    }
  }
  .secondTitle {
    width: 100%;
    font-size: 15px;
    margin-bottom: 10px;
  }
  & > table {
    background-color: #fff;
  }
  & > div > span > strong {
    color: red;
  }
  & > table {
    width: 100%;
  }
  & > table > thead > tr {
    border: 1px solid #ccc;
    height: 45px;
    line-height: 45px;
  }
  &.noticeAdd tr {
    height: 35px;
    line-height: 35px;
  }
  & > table > thead > tr > th > img {
    margin-top: 15px;
    height: 100px;
  }
  & > table > thead > tr > th:nth-child(5) > input {
    width: 60%;
    height: 25px;
    text-align: center;
    outline: none;
  }
  .secondTable > thead > tr:nth-child(1) > th {
    background: #ddd;
  }

 
  & > table > thead > tr > th:nth-child(1) {
    width: 4%;
    border: 1px solid #ccc;
    font-size: 14px;
    color: #333;
    vertical-align: middle;
  }
  & > table > thead > tr > th:nth-child(2) {
    width: 8%;
    border: 1px solid #ccc;
    font-size: 14px;
    color: #333;
    vertical-align: middle;
  }
  & > table > thead > tr > th:nth-child(3) {
    width: 44%;
    border: 1px solid #ccc;
    color: #333;
  }
  & > table > thead > tr > th:nth-child(4) {
    width: 15%;
    border: 1px solid #ccc;
    vertical-align: middle;
  }
  & > table > thead > tr > th:nth-child(5) {
    width: 7%;
    border: 1px solid #ccc;
    vertical-align: middle;
  }
  & > table > thead > tr > th:nth-child(6) {
    width: 7%;
    border: 1px solid #ccc;
    vertical-align: middle;
  }
  & > table > thead > tr > th:nth-child(7) {
    width: 15%;
    border: 1px solid #ccc;
    vertical-align: middle;
    & > select {
      width: 50%;
      height: 25px;
      text-align: center;
    }
  }
  .firstTable {
    margin-bottom: 30px;
  }
  &.noticeAdd {
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
  }
  &.noticeAdd .firstTable > thead > tr > th {
    background-color: #f4f4f4;
  }
  &.noticeAdd .firstTable > thead > tr:nth-child(1) > th > input {
    width: 40%;
    padding-left: 10px;
    ::placeholder {
      color: #aaa;
    }
  }
  &.noticeAdd .firstTable > thead > tr:nth-child(3) > th {
    padding: 10px 15px 5px 10px;
    background-color: #fff;
  }
  &.noticeAdd .firstTable > thead > tr:nth-child(3) > th > textarea {
    width: 100%;
    height: 300px;
    padding: 10px;
    border: 0;
    outline: none;
    font-size: 14px;
    ::placeholder {
      color: #aaa;
      font-size: 14px;
    }
  }
  &.noticeAdd span,
  &.noticeAdd button,
  &.noticeAdd input {
    margin-right: 15px;
  }
  &.noticeAdd button {
    height: 25px;
    width: 100px;
    border: 0;
    background-color: #ddd;
    border-radius: 6px;
  }
  &.noticeAdd input {
    padding-left: 13px !important;
    height: 25px !important;
  }
  & > .firstTable > thead > tr:nth-child(6) > th {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 0;
  }
  & > .firstTable > thead > tr:nth-child(6) > th > label > input {
    margin: 0 5px 0 0px;
  }
  & > .firstTable > thead > tr:nth-child(6) > th > label {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
  & > .firstTable > thead > tr:nth-child(6) > th > label > span {
    margin-top: 1px;
  }
  & .ThumbNail {
    display: block;
    text-align: left;
    padding: 0 20px;
    height: auto !important;
  }
  & .ThumbNail > div {
    margin-top: 0px !important;
    margin-bottom: 3px;
  }
  .iTitle {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 450px !important;
    border: 0 !important;
    margin: auto;
  }
  .ql-snow .ql-editor img {
    width: auto;
  }
`;








export const UserContentArea = styled.div`
  width: 100%;
  margin-top:24px;
  .title {
    width: 100%;
    min-width: 1080px;
    
    height: 50px;
    border: 1px solid #ccc;
    border-bottom: 0;
    background-color: #fff;
    padding: 0 15px;
    line-height: 50px;
  }
  .firstTable > thead > tr > th:first-child {
    width: 15%;
    background: #ddd;
  }
  .firstTable > thead > tr > th:last-child {
    width: 85%;
    text-align: left;
    padding-left: 20px;
    position: relative;
    & > input {
      width: 350px;
      height: 28px;
      border: 1px solid #bbb;
      outline: none;
      padding-left: 5px;
    }
    & > select {
      height: 28px;
      width: 100px;
      margin-right: 5px;
      text-align: center;
      border: 1px solid #bbb;
    }
    & > img {
      width: 20px;
      height: 20px;
      margin-left: -25px;
      position: absolute;
      top: -3px;
      cursor: pointer;
    }
  }
  .secondTitle {
    width: 100%;
    font-size: 15px;
    margin-bottom: 10px;
  }
  & > table {
    background-color: #fff;
  }
  & > div > span > strong {
    color: red;
  };
  & > table {
    width : 100%;
  };
  & > table > thead > tr {
    border: 1px solid #ccc;
    height: 45px;
    line-height: 45px;
  };
  & > table > thead > tr > th > img {
    margin-top: 15px;
    height: 100px;
  };
  .secondTable > thead > tr:nth-child(1) > th {
    background: #ddd;
  };
  & > table > thead > tr > th:nth-child(1) {
    width: 4%;
    border: 1px solid #ccc;
    font-size: 14px;
    color: #333;
    vertical-align: middle;
  };
  & > table > thead > tr > th:nth-child(2) {
    width: 8%;
    &.dormant {
      width: 15%;
    }
    border: 1px solid #ccc;
    font-size: 14px;
    color: #333;
    vertical-align: middle;
  };
  & > table > thead > tr > th:nth-child(3) {
    width: 9%;
    &.AllMemberList {
      width: 14%;
    }
    &.dormant {
      width: 9%;
    }
    border: 1px solid #ccc;
    color: #333;
  };
  & > table > thead > tr > th:nth-child(4) {
    width: 8%;
    &.AllMemberList {
      width: 8%;
    }
    &.dormant {
      width: 10%;
    }
    border: 1px solid #ccc;
    vertical-align: middle;
  };
  & > table > thead > tr > th:nth-child(5) {
    width: 10%;
    &.dormant {
      width: 10%;
    }
    border: 1px solid #ccc;
    vertical-align: middle;
  };
  & > table > thead > tr > th:nth-child(6) {
    width: 10%;
    &.dormant {
      width: 28%;
    }
    border: 1px solid #ccc;
    vertical-align: middle;
  };
  & > table > thead > tr > th:nth-child(7) {
    width: 10%;
    border: 1px solid #ccc;
    vertical-align: middle;
  };
  & > table > thead > tr > th:nth-child(8) {
    width: 10%;
    border: 1px solid #ccc;
    vertical-align: middle;
  };
  & > table > thead > tr > th:nth-child(9) {
    width: 9%;
    border: 1px solid #ccc;
    vertical-align: middle;
  };
  & > table > thead > tr > th:nth-child(10) {
    width: 9%;
    border: 1px solid #ccc;
    vertical-align: middle;
  };
  .firstTable {
    margin-bottom: 30px;
  }
  &.detail > div {
    height: 35px;
    line-height: 35px;
  }
  &.detail .firstTable > thead > tr {
    height: 35px!important;
    line-height: 35px;
    text-align: left;
    .firstTh {
      background-color: #ddd;
    }
    & > th {
      padding-left: 20px;
    }
    & > th:nth-child(1) {
      width: 15%;
    }
    & > th:nth-child(2) {
      width: 25%;
    }
    & > th:nth-child(3) {
      width: 15%;
    }
    & > th:nth-child(4) {
      width: 45%;
    }
    .sbtn {
      margin-left: 100px;
      cursor: pointer;
      width: 130px;
      height: 25px;
      background: #F73D79;
      border-radius: 7px;
      border: 1px solid #F73D79;
      color: #fff;
    }
  }
  &.detail .secondTable > thead > tr {
    height: 35px!important;
    line-height: 35px;
    text-align: left;
    .firstTh {
      background-color: #ddd;
    }
    & > th {
      padding-left: 20px;
    }
    & > th:nth-child(1) {
      width: 15%;
    }
    & > th:nth-child(2) {
      width: 85%;
      background: #fff;
    }
    .ebtn {
      margin-left: 100px;
      cursor: pointer;
      width: 130px;
      height: 25px;
      background: #199DD9;
      border-radius: 7px;
      border: 1px solid #199DD9;
      color: #fff;
    }
  }
`;
