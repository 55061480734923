import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { uploadImage } from "../../Data/POST";

// 스타일드 컴포넌트를 사용하여 스타일을 정의합니다.
const FormRowWrapper = styled.div`
  background-color: #ffffff;

  border-radius: 5px;

  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;

  h2 {
    font-size: 16px;
    padding-bottom: 10px;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    padding-top: 8px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    vertical-align: middle;
    padding: 16px;
    border: 1px solid #ddd;
  }

  input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .description {
    color: red; /* 빨간색 텍스트 색상 */
  }

  /* 입력 필드 2의 공간을 설명 공간보다 크게 설정 */
  .input-field-2 {
    height: 40px; /* 원하는 높이로 조정하세요 */
  }
`;

const FormRow = ({
  formName,
  headerName,
  placeholder,
  description,
  isError,
  isTop,
  disabled,
  value,
  type,
  AreaType,
  event,
}) => {
  return (
    <FormRowWrapper>
      <table>
        <tbody>
          <tr>
            <td
              width={"15%"}
              style={{
                backgroundColor: "#dfe6ed",

                paddingLeft: 16,
              }}
            >
              {headerName}
            </td>
            <td style={{ padding: 8, paddingLeft: 16 }}>
              {AreaType ? (
                <textarea
                  type={type ? type : "text"}
                  style={{ width: "100%", height: 200 }}
                  placeholder={placeholder}
                  disabled={disabled}
                  value={value}
                  onChange={(e) => {
                    event(e);
                  }}
                />
              ) : (
                <input
                  type={type ? type : "text"}
                  style={{ width: "40%" }}
                  placeholder={placeholder}
                  disabled={disabled}
                  value={value}
                  onChange={(e) => {
                    event(e);
                  }}
                />
              )}
            </td>
          </tr>
          {isError && (
            <tr>
              <td colSpan={2} className="description">
                {description}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </FormRowWrapper>
  );
};


export const FormRowFileUploadOrigin = ({
  formName,
  headerName,
  placeholder,
  description,
  isError,
  isTop,
  disabled,
  value,
  type,
  AreaType,
  event,
}) => {

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [pass, setPass] = useState("");
  const [isView, setIsView] = useState(false);
  const audioRef = useRef(null);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('files', file);
      try {
        const response = await uploadImage(formData);
        console.log(response, '응답')
        event(response);
      } catch (error) {
        // 에러 처리
        console.error("업로드 중 에러 발생:", error);
      }
    }
    console.log("File name:", file.name);
    console.log("File size:", file.size, "bytes");
    console.log("File type:", file.type);
  };

  const playPauseToggle = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    setCurrentTime(0);
  };


  const generateRandomPassword = () => {
    if (String(pass) === String(value?.password)) {
      setIsView(true)
    } else {
      alert('비밀번호 불일치');
      return;
    }
  }
  return (
    <FormRowWrapper>


      {value ? (
        <>
          {isView === true ? <div>
            <audio
              ref={audioRef}
              src={`${process.env.REACT_APP_FILEURL + value?.url}`}
              onTimeUpdate={handleTimeUpdate}
              onEnded={handleEnded}
            ></audio>
            <button onClick={playPauseToggle}>
              {isPlaying ? 'Pause' : 'Play'}
            </button>
            <div>재생시간: {currentTime.toFixed(2)} seconds</div>
          </div> :
            <>
              <label htmlFor="password">Password:</label>
              <input
                type="text"
                id="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                placeholder="Enter password or generate"
              />
              <button onClick={generateRandomPassword}>Generate Password</button>
            </>
          }
        </>
      ) :
        (<input type="file" accept="audio/*" onChange={handleFileUpload} />
        )
      }


      {isError && (
        <tr>
          <td colSpan={2} className="description">
            {description}
          </td>
        </tr>
      )}

    </FormRowWrapper>
  );
};


export const FormRowFileUpload = ({
  formName,
  headerName,
  placeholder,
  description,
  isError,
  isTop,
  disabled,
  value,
  type,
  AreaType,
  event,
}) => {

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [pass, setPass] = useState("");
  const [isView, setIsView] = useState(false);
  const audioRef = useRef(null);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('files', file);
      try {
        const response = await uploadImage(formData);
        console.log(response, '응답')
        event(response);
      } catch (error) {
        // 에러 처리
        console.error("업로드 중 에러 발생:", error);
      }
    }
    console.log("File name:", file.name);
    console.log("File size:", file.size, "bytes");
    console.log("File type:", file.type);
  };

  const playPauseToggle = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    setCurrentTime(0);
  };


  const generateRandomPassword = () => {
    if (String(pass) === String(value?.password)) {
      setIsView(true)
    } else {
      alert('비밀번호 불일치');
      return;
    }
  }
  return (
    <FormRowWrapper>
      <table>
        <tbody>
          <tr>
            <td
              width={"15%"}
              style={{
                backgroundColor: "#dfe6ed",

                paddingLeft: 16,
              }}
            >
              {headerName}
            </td>
            <td style={{ padding: 8, paddingLeft: 16 }}>
              {value ? (
                <>
                  {isView === true ? <div>
                    <audio
                      ref={audioRef}
                      src={`${process.env.REACT_APP_FILEURL + value?.url}`}
                      onTimeUpdate={handleTimeUpdate}
                      onEnded={handleEnded}
                    ></audio>
                    <button onClick={playPauseToggle}>
                      {isPlaying ? 'Pause' : 'Play'}
                    </button>
                    <div>재생시간: {currentTime.toFixed(2)} seconds</div>
                  </div> :
                    <>
                      <label htmlFor="password">Password:</label>
                      <input
                        type="text"
                        id="password"
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                        placeholder="Enter password or generate"
                      />
                      <button onClick={generateRandomPassword}>Generate Password</button>
                    </>
                  }
                </>
              ) :
                (<input type="file" accept="audio/*" onChange={handleFileUpload} />
                )
              }

            </td>
          </tr>
          {isError && (
            <tr>
              <td colSpan={2} className="description">
                {description}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </FormRowWrapper>
  );
};


export const FormRowSelect2 = ({
  formName,
  headerName,
  placeholder,
  description,
  isError,
  isTop,
  selectArray,
  disabled,
  value,
  type,
  AreaType,
  event,
}) => {
  return (

    <>
      <select value={value} onChange={(e) => {
        event(e);
      }}>

        {selectArray?.map((item, idx) => {
          return (
            <option value={item?.value}>
              {item?.key}
            </option>
          )
        })}

      </select>

      {isError && (
        <tr>
          <td colSpan={2} className="description">
            {description}
          </td>
        </tr>
      )}
    </>
  );
};

export const FormRowSelect = ({
  formName,
  headerName,
  placeholder,
  description,
  isError,
  isTop,
  selectArray,
  disabled,
  value,
  type,
  AreaType,
  event,
}) => {
  return (
    <FormRowWrapper>
      <table>
        <tbody>
          <tr>
            <td
              width={"15%"}
              style={{
                backgroundColor: "#dfe6ed",

                paddingLeft: 16,
              }}
            >
              {headerName}
            </td>
            <td style={{ padding: 8, paddingLeft: 16 }}>
              <select value={value} onChange={(e) => {
                event(e);
              }}>

                {selectArray?.map((item, idx) => {
                  return (
                    <option value={item?.value}>
                      {item?.key}
                    </option>
                  )
                })}

              </select>

            </td>
          </tr>
          {isError && (
            <tr>
              <td colSpan={2} className="description">
                {description}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </FormRowWrapper>
  );
};




export const FormRowDate2 = ({
  formName,
  headerName,
  placeholder,
  description,
  isError,
  isTop,
  selectArray,
  disabled,
  value,
  type,
  AreaType,
  event,
}) => {
  // 현재 날짜와 시간을 가져오는 함수

  return (
    <>
      <input
        type="datetime-local"
        value={value} // 현재 날짜와 시간을 기본값으로 설정
        onChange={(e) => {
          event(e);
        }}
      />

      {isError && (
        <tr>
          <td colSpan={2} className="description">
            {description}
          </td>
        </tr>
      )}
    </>
  );
};
export const FormRowDate = ({
  formName,
  headerName,
  placeholder,
  description,
  isError,
  isTop,
  selectArray,
  disabled,
  value,
  type,
  AreaType,
  event,
}) => {
  // 현재 날짜와 시간을 가져오는 함수

  return (
    <FormRowWrapper>
      <table>
        <tbody>
          <tr>
            <td
              width={"15%"}
              style={{
                backgroundColor: "#dfe6ed",
                paddingLeft: 16,
              }}
            >
              {headerName}
            </td>
            <td style={{ padding: 8, paddingLeft: 16 }}>
              <input
                type="datetime-local"
                value={value} // 현재 날짜와 시간을 기본값으로 설정
                onChange={(e) => {
                  event(e);
                }}
              />
            </td>
          </tr>
          {isError && (
            <tr>
              <td colSpan={2} className="description">
                {description}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </FormRowWrapper>
  );
};



export const FormRowDateSingle2 = ({
  formName,
  headerName,
  placeholder,
  description,
  isError,
  isTop,
  selectArray,
  disabled,
  value,
  type,
  AreaType,
  event,
}) => {
  // 현재 날짜와 시간을 가져오는 함수


  return (
    <>
      <input
        type="date"
        value={value} // 현재 날짜와 시간을 기본값으로 설정
        onChange={(e) => {
          event(e);
        }}
      />

      {isError && (
        <tr>
          <td colSpan={2} className="description">
            {description}
          </td>
        </tr>
      )}
    </>
  );
};






export const FormRowDateSingle = ({
  formName,
  headerName,
  placeholder,
  description,
  isError,
  isTop,
  selectArray,
  disabled,
  value,
  type,
  AreaType,
  event,
}) => {
  // 현재 날짜와 시간을 가져오는 함수


  return (
    <FormRowWrapper>
      <table>
        <tbody>
          <tr>
            <td
              width={"15%"}
              style={{
                backgroundColor: "#dfe6ed",
                paddingLeft: 16,
              }}
            >
              {headerName}
            </td>
            <td style={{ padding: 8, paddingLeft: 16 }}>
              <input
                type="date"
                value={value} // 현재 날짜와 시간을 기본값으로 설정
                onChange={(e) => {
                  event(e);
                }}
              />
            </td>
          </tr>
          {isError && (
            <tr>
              <td colSpan={2} className="description">
                {description}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </FormRowWrapper>
  );
};




export const FormRowYMD = ({
  formName,
  headerName,
  placeholder,
  description,
  isError,
  isTop,
  selectArray,
  disabled,
  value,
  type,
  AreaType,
  event,
}) => {

  const handleChange = (index, inputValue) => {
    const updatedValue = value ? value.split('') : Array(8).fill(' ');
    updatedValue.splice(index * 2, 2, ...inputValue.split(''));
    event(updatedValue.join('').trim());
  };

  return (
    <FormRowWrapper>
      <table>
        <tbody>
          <tr>
            <td
              width={"15%"}
              style={{
                backgroundColor: "#dfe6ed",
                paddingLeft: 16,
              }}
            >
              {headerName}
            </td>
            <td style={{ padding: 8, paddingLeft: 16, display: 'flex', width: '50%', border: 'none', alignItems: 'center' }}>
              {[0, 1, 2].map((index) => (
                <React.Fragment key={index}>
                  <input
                    type="text"
                    placeholder={["yyyy", "MM", "dd"][index]}
                    value={(value && value.length >= index * 2 + 2) ? value.substr(index * 2, 2) : ''}
                    maxLength={2}
                    onChange={(e) => handleChange(index, e.target.value)}
                  />
                  {index < 2 && <span style={{ margin: '0 5px' }}>-</span>}
                </React.Fragment>
              ))}
            </td>
          </tr>
          {isError && (
            <tr>
              <td colSpan={2} className="description">
                {description}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </FormRowWrapper>
  );
};
export default FormRow;
