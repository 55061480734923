import logo from "./logo.svg";
import "./App.css";
import Nav from "./Nav";

import axios from "axios";
import { SWRConfig } from "swr";
import { RecoilRoot, useRecoilState } from "recoil";
import { getStoarge } from "./Datas";
import ScrollToTopOnPageChange from "./Util/ScrollToTopOnPageChagne";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

axios.defaults.baseURL = process.env.REACT_APP_DEVURL /* REACT_APP_BASEURL */;

axios.interceptors.request.use(
  async (config) => {
    const jwt = await getStoarge("jwt");
    if (jwt) {
      config.headers.Authorization = `Bearer ${jwt}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

const App = () => {

   

  return (
    <>
      <SWRConfig
        value={{
          fetcher: (path, options) =>
            axios(path, options).then((res) => res.data),
        }}
      >
        <RecoilRoot>
          <ScrollToTopOnPageChange />
          <Nav />
        </RecoilRoot>
      </SWRConfig>
    </>
  );
};

export default App;
