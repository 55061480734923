import styled from "@emotion/styled";
import React from "react";

const TopWrap = styled.div`
  padding-bottom: 10px;
  border-bottom: 2px solid #e0e0e0;
`;

const SubFontWrap = styled.div`
  margin-top: -8px;
  display: flex;

  flex-direction: row;
  align-items: center;
`;
const SubFontText = styled.p`
  font-size: 15px;
  color: gray;
`;

const TopText = styled.p`
  font-size: 24px;
  margin-bottom: 0;
  padding-bottom: 16px;
  font-weight: bold;
  color: black;
`;

const GenTop = ({ title, SubTitle, SubTitle2 }) => {
  return (
    <TopWrap>
      <TopText>{title}</TopText>
      <SubFontWrap>
        <SubFontText style={{ marginRight: 8 }}>{SubTitle}</SubFontText>
        <SubFontText> / </SubFontText>
        <SubFontText style={{ marginLeft: 8, color: "black" }}>
          {SubTitle2}
        </SubFontText>
      </SubFontWrap>
    </TopWrap>
  );
};

export default GenTop;
