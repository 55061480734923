import styled from "@emotion/styled";
import moment from "moment";
import React from "react";
import { PlusIco } from "../../Assets";

const HoverableImage = styled.img`
  margin-right: 16px;
  cursor: pointer;
  transition: transform 0.2s; /* transform 속성에 애니메이션 효과 추가 */

  &:hover {
    transform: scale(1.3); /* 호버 시 크기 변경 */
  }
`;

const HoverableListItem = styled.li`
  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  margin: -5px -20px; /* 부모의 패딩을 상쇄시키는 마이너스 마진을 설정 */
  width: 100%;
  padding-left: 23px;
  padding-right: 16px;
`;

const AdminHomeBody = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    width: 50%;
    min-width: 400px;
    padding: 5px 20px;
  }
  & > div {
    text-align: center;
    width: 50%;
    height: 325px;
    min-width: 300px;
    background-color: #fff;
    margin-right: 15px;
  }
`;

const NoticeArea = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  & > ul > li {
    /* HoverableListItem 스타일드 컴포넌트 적용 */
    width: 106%;
    justify-content: space-between;
    display: flex;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
    transition: background-color 0.2s; /* 호버 시 배경색 변경에 애니메이션 효과 추가 */
  }
  & > div {
    display: flex;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ddd;
    width: 108%;
    margin-left: -20px;
    padding: 0 20px;
  }
  & > div > img {
    height: 20px;
    margin-top: 10px;
  }
  & > div > h1 {
    font-size: 17px;
    font-weight: bold;
  }
`;
const ApplicationTable = ({ Notice, OrderData }) => {
  return (
    <AdminHomeBody>
      <NoticeArea>
        <div>
          <h1>공지사항</h1>
          <HoverableImage src={PlusIco} />
        </div>
        <ul>
          {Notice?.map((item, idx) => {
            return (
              <HoverableListItem>
                {item?.title}
                <span>
                  접수일:{moment(item?.createdAt).format("YYYY-MM-DD")}
                </span>
              </HoverableListItem>
            );
          })}
        </ul>
      </NoticeArea>
      <NoticeArea>
        <div>
          <h1>신청서</h1>
          <HoverableImage src={PlusIco} />
        </div>
        <ul>
          {OrderData?.map((item, idx) => {
            return (
              <HoverableListItem>
                딜러:{item?.USER?.name} 고객:{item?.name} 상태:{item?.type}
                <span>접수일:2023-09-09</span>
              </HoverableListItem>
            );
          })}
        </ul>
      </NoticeArea>
    </AdminHomeBody>
  );
};

export default ApplicationTable;
