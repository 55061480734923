import React, { useState } from "react";
import Modal from "react-modal";
import { useRecoilState } from "recoil";
import { ProfileState } from "../Data/GetMe";
import { RemoveStorage } from "../Datas";

// 모달 스타일

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999999999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: "300px",
    height: "145px", // 높이 설정
    margin: "auto",
    padding: "20px",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "white",
    textAlign: "center",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)", // 높은 테두리 그림자
  },
};

const buttonStyle = {
  backgroundColor: "#007bff", // 파란색
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "8px 16px",
  margin: "0 4px",
  cursor: "pointer",
};

//스타일꾸미기 [진행] 및 로그아웃 진행

const LogoutModal = ({ isOpen, onRequestClose, onConfirmLogout }) => {
  const [profile, setProfile] = useRecoilState(ProfileState);

  const _handleLogout = async () => {
    await RemoveStorage("jwt");
    setProfile(null);
    window.location.href = "/login";
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={modalStyles}
      contentLabel="로그아웃 확인"
    >
      <h2 style={{ fontSize: 18, fontWeight: "bold", marginBottom: 36 }}>
        정말 로그아웃 하시겠습니까?
      </h2>
      <button
        onClick={() => {
          _handleLogout();
        }}
        style={buttonStyle} 
        type="button"
      >
        확인
      </button>
      <button
        style={{ ...buttonStyle, backgroundColor: "#dc3545" }} // 빨간색
        onClick={onRequestClose}
      >
        취소
      </button>
    </Modal>
  );
};

export default LogoutModal;
