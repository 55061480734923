import React from "react";
import PropTypes from "prop-types";

// Material-ui 컴포넌트들
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { TopTapData } from "../Data/AdmHeader";

import styled from "@emotion/styled";
import { AppLogo, EXITICO, HOMEICO } from "../Assets";
import AdmTopBtn from "./AdmTopBtn";
import { useRecoilState } from "recoil";
import { LogoutModalState } from "../Data/Store";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240; // 메뉴 Drawer 가로크기
const useStyles = makeStyles((theme) => ({
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      width: `calc(100%)`,
    },
    backgroundColor: "#232F3E",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  searchBox: {
    position: "relative",
    backgroundColor: "red",
    width: "256px",
    height: "38px",
    borderRadius: "19px",
    lineHeight: "38px",
    padding: "0 40px 0 24px",
  },
  searchField: {
    backgroundColor: "red",
    width: "100%",
    border: "none",
    fontSize: "1.2em",
  },
  searchIcon: {
    position: "absolute",
    top: "7px",
    right: "15px",
    color: "#304ffe",
  },
}));

const Icons = styled.img`
  width: 30px;
  height: 30px;
  resize: both;
  &:hover {
    opacity: 0.5;
  }
`;

const LogoutBtn = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #199dd9;
  padding: 5px 25px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  :hover {
    opacity: 0.5;
  }
`;

const ToolbarInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: #000;
`;

const LogoArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    margin-right: 30px;
  }
  & > span {
    color: #fff;
    font-size: 16px;
  }
`;

export const HeaderBarContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const HeaderBarLeft = styled.div`
  background-color: #232f3e;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 239px;
  height: 100%;
`;

export const HeaderBarRight = styled.div`
  background-color: #fff;
  justify-content: flex-end;
  padding-right: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #eee;
  box-shadow: 0 4px 4px -4px #eee;
  height: 100%;
`;

export const LogoWrap = styled.img`
  width: 303px;
  height: 53px;
  object-fit: contain;
`;
const Header = ({ onDrawerToggle }) => {
  const classes = useStyles();
  const Navi = useNavigate();
  const [isOpen, setIsOpen] = useRecoilState(LogoutModalState);
  /*   const [profile, setProfile] = useRecoilState(ProfileState);
   */
  /*   const _handleLogout = async () => {
    await RemoveStorage("jwt");
    setProfile(null);
    window.location.href = "/";
  }; */
  return (
    <AppBar position="fixed" className={classes.appBar} color="default">
      <HeaderBarContainer>
        <HeaderBarLeft>
          <LogoWrap src={AppLogo} />
        </HeaderBarLeft>

        <HeaderBarRight>
          {TopTapData?.map((item, idx) => {
            return (
              <AdmTopBtn
                action={() => {
                  if (item?.name === "로그아웃") {
                    setIsOpen(true);
            
                  } else {
                    Navi("/");
                  }
                }}
                ICO={item?.ICO}
              />
            );
          })}
        </HeaderBarRight>
      </HeaderBarContainer>
    </AppBar>
  );
};

Header.propTypes = {
  onDrawerToggle: PropTypes.func,
};

export default Header;
