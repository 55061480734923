import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const LoadingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #080710;
`;

const LoadingText = styled.h1`
  color: white;
  font-size: 24px;
  margin-bottom: 20px;
`;

 
const LoadingBox = styled.div`
  width: 70px;
  height: 70px;
  border: 5px solid transparent;
  border-top: 5px solid #23a2f6;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  animation-timing-function: linear; /* 회전 속도 조정 */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
const ProgressBarContainer = styled.div`
  width: 50%;
  height: 10px;
  background-color: #eee;
  border-radius: 5px;
  margin-top: 20px;
`;

const ProgressBar = styled.div`
  width: 50%;
  height: 100%;
  background-color: #23a2f6;
  border-radius: 5px;
  animation: progress 3s linear forwards;
`;

const LogoText = styled.span`
  color: white;
  font-size: 36px;
  font-weight: bold;
  animation: blink 0.5s alternate infinite;
`;

const LoginLoading = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 100) {
          clearInterval(timer);
          navigate("/login");
        }
        return prevProgress + 1;
      });
    }, 30); // 로딩 속도를 3초로 변경

    return () => {
      clearInterval(timer);
    };
  }, [navigate]);

  return (
    <LoadingPageContainer>
      <LogoText>MSS</LogoText> {/* 흰색 텍스트 및 반짝이는 애니메이션 */}
      <LoadingBox /> {/* 로딩 스피너 */}
      <ProgressBarContainer>
        <ProgressBar style={{ width: `${progress}%` }} />
      </ProgressBarContainer>
    </LoadingPageContainer>
  );
};

export default LoginLoading;
