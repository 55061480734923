const setStoarge = async (key, val) => {
    let data = await localStorage.setItem(key, val);
    return data;
  }; //스토리지 저장
  const getStoarge = async (key) => {
    let data = await localStorage.getItem(key);
    return data;
  }; //스토리지 가져오기
  
  const RemoveStorage = async (key) => {
    let data = await localStorage.removeItem(key);
    return data;
  }; //스토리지 제거
  
  export { setStoarge, getStoarge, RemoveStorage };
  