import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // 그림자 추가
    marginTop: "16px", // 16px 상단 마진 추가
  },
}));

const SearchBox = ({ value, onEvent, onPressEnter, onPressClose }) => {
  const classes = useStyles();

  return (
    <TextField
      variant="outlined"
      fullWidth
      onChange={(e) => {
        onEvent(e);
      }}
      value={value}
      placeholder="검색어를 입력하세요"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                onPressEnter();
              }}
              edge="end"
              aria-label="검색"
            >
              <SearchIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                onPressClose();
              }}
              edge="end"
              aria-label="초기화"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      className={classes.searchContainer} // 스타일 적용
    />
  );
};

export default SearchBox;
