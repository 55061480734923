/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import {
  FaUsers,
  FaQuestionCircle,
  FaFileAlt,
  FaCheckCircle,
} from "react-icons/fa";

const boxStyle = css`
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: left;
  width: calc(25% - 20px);
  transition: transform 0.3s ease; /* 호버 시 트랜스폼에 대한 애니메이션 효과 추가 */
  cursor: pointer; /* 커서를 포인터로 변경하여 호버 가능하도록 함 */

  &:hover {
    transform: scale(1.05); /* 호버 시 약간 커지도록 스케일 변환 */
  }
`;

const iconStyle = css`
  font-size: 24px;
  margin-right: 8px;
`;

const titleStyle = css`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: -8px;
`;

const separatorStyle = css`
  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 10px;
`;

const countStyle = css`
  font-size: 18px;
  margin-top: 0px;
`;

const BoxCompo = ({ title, count, icon, subText }) => {
  return (
    <div css={boxStyle}>
      <div css={titleStyle}>
        {icon === "사람" && <FaUsers css={iconStyle} />}
        {icon === "질문" && <FaQuestionCircle css={iconStyle} />}
        {icon === "문서" && <FaFileAlt css={iconStyle} />}
        {icon === "체크" && <FaCheckCircle css={iconStyle} />}
        <h2>{title}</h2>
      </div>
      <div css={separatorStyle}></div>
      <p css={countStyle}>
        {count} {subText}
      </p>
    </div>
  );
};

export default BoxCompo;
