import styled from "@emotion/styled";
import { Info, Tablet } from "@mui/icons-material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BoxCompo from "../../Component/BoxCompo";
import GenTop from "../../Component/GenTop";
import TableButton from "../../Component/Table/TableButton";
import FormRow from "../../Component/TableIn/FromRow";
import { PUT_INFO_API } from "../../Data/POST";
import { GetDInfo } from "../../Data/SWR";
import { WrapperDash } from "../../Styled/DashBoardGlobal";
import {
  AdminHomeSub,
  AdminHomeTop,
  AdminSettingArea,
  ButtonArea,
  SettingContentArea,
  SettingTitle,
  TintBox,
} from "../../Styled/SettingStyled";
const ContainerStyle = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  height: 142px;
`;
export const Tabletop = styled.div`
  width: 100%;
  padding: 10px;

  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
`;

export const TableWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
`;
export const TableTint = styled.div`
  width: 100%;
  padding: 10px;

  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;
const GeneralSettings = () => {
  const { InfoData, InfoError, InfoMutate } = GetDInfo();
  const [data, setData] = useState({
    name: "",
  });
  const [siteData, setSiteData] = useState({
    seotitle: "",
    metadata: "",
    seodescription: "",
    seokeyword: "",
    author: "",
    publisher: "",
  });

  const [masterData, setMasterData] = useState(0);
  useEffect(() => {
    if (InfoData) {
      if (InfoData?.isMaster ===true) {
        setMasterData(InfoData?.count_price);
      }
      setData({ name: InfoData?.name });

      setSiteData({
        seotitle: InfoData?.seotitle,
        metadata: InfoData?.metadata,
        seodescription: InfoData?.seodescription,
        seokeyword: InfoData?.seokeyword,
        author: InfoData?.author,
        publisher: InfoData?.publisher,
      });
    }
  }, [InfoData]);

  //업데이트로직 [진행]
  const _handleUpdate = async () => {
    let UserUPT = { ...data };
    if (InfoData?.isMaster) {
      UserUPT = { ...UserUPT, count_price: masterData };
    }
    const UpdateRow = {
      user: UserUPT,
      site: siteData,
    };
    const res = await PUT_INFO_API({ body: UpdateRow });
    if (res !== undefined) {
      alert("업데이트 완료");
    } else {
      alert("네트워크 연결실패");
    }
    //PUT 준비

    //update API
  };

  return (
    <WrapperDash>
      <GenTop
        title={"기본환경설정"}
        SubTitle={"환경설정"}
        SubTitle2={"기본환경설정"}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 16,
        }}
      >
        <TableButton
          color={{ active: "blue", none: "red" }}
          event={() => {
            _handleUpdate();
          }}
          text={"저장"}
        />
      </div>
   {/*    {InfoData?.isMaster === true && (
        <TableWrap>
          <Tabletop>딜러 수수료 적용</Tabletop>
          <FormRow
            formName="제목 입력"
            isTop={true}
            value={masterData}
            headerName="딜러 수수료"
            placeholder="딜러 수수료를 입력해주세요"
            description="관리자설정필드"
            event={(e) => {
              setMasterData(e.target.value);
            }}
          />
          <TableTint>
            <p>
              비밀번호는 관리자 접속관리에 매우 중요하므로 정기적으로 비밀번호를
              변경해주세요.
            </p>
          </TableTint>
        </TableWrap>
      )} */}
      <TableWrap>
        <Tabletop>관리자 필수정보</Tabletop>
        <FormRow
          formName="제목 입력"
          isTop={true}
          headerName="회원명"
          placeholder="회원명칭을 기입해주세요"
          description="관리자설정필드"
          value={data?.name}
          event={(e) => {
            setData({ ...data, name: e.target.value });
          }}
        />
        <FormRow
          formName="제목 입력"
          isTop={true}
          headerName="이메일주소"
          placeholder="이메일 주소를 기입해주세요"
          description="관리자설정필드"
          disabled={true}
          value={InfoData?.email}
        />
        <FormRow
          formName="제목 입력"
          isTop={true}
          headerName="연락처"
          placeholder="연락처를 기입해주세요."
          description="연락처를 기입해주세요."
          value={InfoData?.tel}
          disabled={true}
        />

        <FormRow
          formName="최종 로그인"
          isTop={true}
          value={moment(InfoData?.updatedAt).format("YYYY-MM-DD")}
          headerName="최종 로그인"
          placeholder="입력 필드 2"
          description="관리자설정필드"
          disabled={true}
        />
        <TableTint>
          <p>관리자 연락처정보가 불일치할시 불이익이 있을수있습니다.</p>
        </TableTint>
      </TableWrap>

      <TableWrap>
        <Tabletop>SEO 설정</Tabletop>
        <FormRow
          formName="제목 입력"
          isTop={true}
          headerName="사이트 제목 설정"
          value={siteData?.seotitle}
          placeholder="사이트 제목을 기입해주세요"
          description="관리자설정필드"
          event={(e) => {
            setSiteData({ ...siteData, seotitle: e.target.valuie });
          }}
        />
        <FormRow
          formName="제목 입력"
          isTop={true}
          headerName="사이트 소유권 확인정보"
          AreaType={true}
          placeholder="소유권 주소를 기입해주세요"
          description="관리자설정필드"
          value={siteData?.metadata}
          event={(e) => {
            setSiteData({ ...siteData, metadata: e.target.valuie });
          }}
        />
        <FormRow
          formName="제목 입력"
          isTop={true}
          headerName="메타 디스크립션(description)"
          placeholder="사이트 설명을 기입해주세요."
          AreaType={true}
          description="연락처를 기입해주세요."
          value={siteData?.seodescription}
          event={(e) => {
            setSiteData({ ...siteData, seodescription: e.target.valuie });
          }}
        />
        <FormRow
          formName="제목 입력"
          isTop={true}
          AreaType={true}
          headerName="키워드(,로 구분해주세요)"
          placeholder="키워드(,로 구분해주세요)"
          description="관리자설정필드"
          value={siteData?.seokeyword}
          event={(e) => {
            setSiteData({ ...siteData, seokeyword: e.target.valuie });
          }}
        />
        <FormRow
          formName="최종 로그인"
          isTop={true}
          headerName="제작자 정보 (author)"
          placeholder="제작자 정보 (author) 기입"
          description="관리자설정필드"
          value={siteData?.author}
          event={(e) => {
            setSiteData({ ...siteData, author: e.target.valuie });
          }}
        />
        <FormRow
          formName="최종 로그인"
          isTop={true}
          headerName="발행 주체 (publisher)"
          placeholder="발행주체 (publisher)정보 기입"
          description="관리자설정필드"
          value={siteData?.publisher}
          event={(e) => {
            setSiteData({ ...siteData, publisher: e.target.valuie });
          }}
        />
        <TableTint>
          <p>관리자 연락처정보가 불일치할시 불이익이 있을수있습니다.</p>
        </TableTint>
      </TableWrap>
    </WrapperDash>
  );
};

export default GeneralSettings;
