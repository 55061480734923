import styled from "@emotion/styled";
import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { SearchIco } from "../../Assets";
import GenTop from "../../Component/GenTop";
import FilterForm from "../../Component/Table/FilterForm";
import MainTable from "../../Component/Table/MainTable";
import SearchHeader from "../../Component/Table/SearchHeader";
import { WrapperDash } from "../../Styled/DashBoardGlobal";
import TableButton from "../../Component/Table/TableButton";
import {
  ButtonArea,
  SettingBottomArea,
  SettingBottomBtnArea,
  SettingContentArea,
  SettingNoticeArea,
  SettingTitle,
  SettingTopBtnArea,
} from "../../Styled/SettingStyled";
import { GET_CENTER_LIST_SWR, GET_DB_LIST_SWR, GET_NOTICE_SWR } from "../../Data/SWR";
import { DEL_APLCT_API, DEL_NOTICE_API } from "../../Data/POST";
const TableContainer = styled.div`
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
`;

const TableHeader = styled.div`
  background-color: #dfe6ed;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding: 10px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const PlusIcon = styled.div`
  background-color: #eee;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px; /* 추가 */
  min-height: 20px; /* 추가 */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const DBManageMent = () => {
  const [checked, setChecked] = useState([]); // 체크 상태를 나타내는 배열
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(false);



  const [inputVal, setInputVal] = useState("");
  const [search, setSearch] = useState("");

  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { DBListData, DBListMutate } = GET_DB_LIST_SWR({
    offset: offset,
    limit: 10,
    search: search,



  });


  console.log(DBListData, '디비')

  const Navigate = useNavigate();

  const headers = [
    { title: "비밀번호", key: "recoardFile.password" }, // 이 부분 수정
    { title: "상담신청유형", key: "cnslAplctType" },
    { title: "이름", key: "name" },
    { title: "생년월일", key: "brthd" },
    { title: "휴대전화", key: "clphnNo" },
    { title: "시도", key: "ctprvCode" },
    { title: "상세주소", key: "adtnInfo1" },
    { title: "개인정보활용동의일", key: "prvcyUseAgrmnDate" },

  ];


  const _handleDelete = async () => {
    if (checked.length === 0) {
      alert("제거할 항목을 선택해주세요.");
      return;
    }
    console.log(checked);
    const res = await DEL_APLCT_API(checked);
    if (res === undefined) {
      alert("네트워크 연결을 확인해주세요");
      return;
    }
    await DBListMutate();
  };
  /* 테이블모듈 =데이터 동기화  마무ㄹ*/
  return (
    <WrapperDash>
      <GenTop
        title={"DB"}
        SubTitle={"DB"}
        SubTitle2={"DB 관리"}
      />
      <div style={{ marginTop: 20 }}>
        DB 제목으로 검색
        <SearchHeader
          value={inputVal}
          onEvent={(e) => {
            setInputVal(e.target.value);
          }}
          onPressEnter={() => {
            setSearch(inputVal);
          }}
          onPressClose={() => {
            setInputVal("");
            setSearch("");
          }}
        />
      </div>
      <div style={{ marginTop: 20, display: "flex", alignItems: "center" }}>


      </div>

      <SettingTopBtnArea>
        <SettingTitle >
         
        </SettingTitle>
        
        <TableButton
          color={{ active: "blue", none: "red" }}
          event={() => {
            Navigate("/admin/db/edit");
          }}
          text={"DB 등록"}
        />
      </SettingTopBtnArea>
      <MainTable
        Action={(e) => {
          Navigate(`/admin/db/${e.id}`)
        }}
        ActionMode={true}
        data={DBListData?.rows ? DBListData?.rows : []}
        headers={headers}
        CheckMode={true}
        checked={checked}
        setChecked={setChecked}
      />
      <div style={{ marginTop: 16 }}>
        <TableButton
          color={{ active: "red", none: "black" }}
          event={() => {
            _handleDelete();
          }}
          text={"삭제"}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={10}
          onPageChange={(pageNumber) => {
            setCurrentPage(pageNumber);
            setOffset((pageNumber - 1) * 10);
          }}
          totalItems={DBListData?.count}
          pageNeighbours={14}
        />
      </div>
    </WrapperDash>
  );
};

export default DBManageMent;
