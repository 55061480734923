import React from "react";
import BoxCompo from "../../Component/BoxCompo";
import GenTop from "../../Component/GenTop";
import { WrapperDash } from "../../Styled/DashBoardGlobal";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import NoticeTable from "../../Component/Table/NoticeTable";
import { GetDashInfo } from "../../Data/SWR/index";
import ApplicationTable from "../../Component/Table/ApplicationTable";
const ContainerStyle = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  height: 142px;
`;

const DashBoard = () => {
  const { DashInfoError, DashInfoMutate, DashInfooData } = GetDashInfo();

  return (
    <WrapperDash>
      <GenTop title={"대시보드"} SubTitle={"환경설정"} SubTitle2={"대시보드"} />
      <ApplicationTable
        Notice={DashInfooData?.NoticeData}
        OrderData={DashInfooData?.OrderData}
      />
      {DashInfooData?.master === 1 && (
        <>
          {" "}
          <ContainerStyle>
            <BoxCompo
              title="센터 총 딜러수"
              count={DashInfooData?.CenterDelary}
              subText={"명"}
              icon={"체크"}
            />
            <BoxCompo
              title="센터 총 신청서수"
              count={DashInfooData?.CenterAppCount}
              subText={"건"}
              icon={"문서"}
            />
            <BoxCompo
              title="센터 총 사이트 방문자수 "
              count={DashInfooData?.totalVisit}
              subText={"명"}
              icon={"사람"}
            />
            <BoxCompo
              title="센터 총 완료 수"
              count={DashInfooData?.TotalClearApp}
              subText={"건"}
              icon={"질문"}
            />
          </ContainerStyle>
          <ContainerStyle>
            <BoxCompo
              title="센터 총 취소 수"
              count={DashInfooData?.TotalCancelApp}
              subText={"건"}
              icon={"체크"}
            />
            <BoxCompo
              title="센터 총 미처리 수"
              count={DashInfooData?.TotalDelayApp}
              subText={"건"}
              icon={"사람"}
            />

            <BoxCompo
              title="센터 총 전환율"
              count={DashInfooData?.conversionRate}
              subText={"%"}
              icon={"체크"}
            />
            <BoxCompo
              title="센터 방문자 수"
              count={DashInfooData?.VisitUser}
              subText={"건"}
              icon={"사람"}
            />
          </ContainerStyle>
        </>
      )}
      {DashInfooData?.master === 2 && (
        <>
          <ContainerStyle>
            <BoxCompo
              title="총 딜러수"
              count={DashInfooData?.AllUSerCount}
              subText={"명"}
              icon={"체크"}
            />
            <BoxCompo
              title="총 센터수"
              count={DashInfooData?.AllCenterCount}
              subText={"개"}
              icon={"사람"}
            />
            <BoxCompo
              title="총 신청서수"
              count={DashInfooData?.AllReq}
              subText={"건"}
              icon={"문서"}
            />
            <BoxCompo
              title="총 사이트 방문자수 "
              count={DashInfooData?.totalVisitCount}
              subText={"명"}
              icon={"사람"}
            />
          </ContainerStyle>
          <ContainerStyle>
            <BoxCompo
              title="총 완료 수"
              count={DashInfooData?.AllClearCount}
              subText={"건"}
              icon={"질문"}
            />
            <BoxCompo
              title="총 취소 수"
              count={DashInfooData?.AllCancelCount}
              subText={"건"}
              icon={"체크"}
            />
            <BoxCompo
              title="총 미처리 수"
              count={DashInfooData?.AllDelayCount}
              subText={"건"}
              icon={"사람"}
            />
            <BoxCompo
              title="총 전환율"
              count={DashInfooData?.conversionAll}
              subText={"%"}
              icon={"체크"}
            />
          </ContainerStyle>
        </>
      )}
      <ContainerStyle>
        <BoxCompo
          title="개별 방문 수"
          count={DashInfooData?.VisitUser}
          subText={"건"}
          icon={"문서"}
        />
        <BoxCompo
          title="개별 신청자 수"
          count={DashInfooData?.UserReq}
          subText={"건"}
          icon={"문서"}
        />
        <BoxCompo
          title="개별 취소 수"
          count={DashInfooData?.UserClear}
          subText={"건"}
          icon={"문서"}
        />
        <BoxCompo
          title="개별 미처리 수"
          count={DashInfooData?.UserDealy}
          subText={"건"}
          icon={"문서"}
        />
      </ContainerStyle>

      <ContainerStyle>
        <BoxCompo
          title="개별 전환율"
          count={DashInfooData?.conversionUser}
          subText={"건"}
          icon={"문서"}
        />
      </ContainerStyle>
    </WrapperDash>
  );
};

export default DashBoard;

/* CENTER --  */
/* import React from "react";
import BoxCompo from "../../Component/BoxCompo";
import GenTop from "../../Component/GenTop";
import { WrapperDash } from "../../Styled/DashBoardGlobal";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import NoticeTable from "../../Component/Table/NoticeTable";
import ApplicationTable from "../../Component/Table/ApplicationTable";
const ContainerStyle = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  height: 142px;
`;

const DashBoard = () => {
  const noticeData = {
    headers: ["제목", "작성년월일", "작성자"],
    rows: [
      ["공지 1", "2023-09-26", "작성자 1"],
      ["공지 2", "2023-09-25", "작성자 2"],
      ["공지 3", "2023-09-24", "작성자 3"],
      ["공지 4", "2023-09-23", "작성자 4"],
      ["공지 5", "2023-09-22", "작성자 5"],
    ],
  };

  const applicationData = {
    headers: ["딜러명", "접수자명", "거주지", "상태"],
    rows: [
      ["딜러 1", "접수자 1", "서울", "완료"],
      ["딜러 2", "접수자 2", "부산", "미처리"],
      ["딜러 3", "접수자 3", "대전", "완료"],
      ["딜러 4", "접수자 4", "광주", "미처리"],
      ["딜러 5", "접수자 5", "인천", "완료"],
    ],
  };
  return (
    <WrapperDash>
      <GenTop title={"대시보드"} SubTitle={"환경설정"} SubTitle2={"대시보드"} />
      <ApplicationTable data={applicationData} />
      <ContainerStyle>
        <BoxCompo title="센터 총 딜러수" count={10} subText={"명"} icon={"체크"} />
        <BoxCompo title="센터 총 신청서수" count={10} subText={"건"} icon={"문서"} />
        <BoxCompo
          title="센터 총 사이트 방문자수 "
          count={10}
          subText={"명"}
          icon={"사람"}
        />
           <BoxCompo title="센터 총 완료 수" count={10} subText={"건"} icon={"질문"} />
      </ContainerStyle>
      <ContainerStyle>
      
        <BoxCompo title="센터 총 취소 수" count={10} subText={"건"} icon={"체크"} />
        <BoxCompo
          title="센터 총 미처리 수"
          count={10}
          subText={"건"}
          icon={"사람"}
        />
        
        <BoxCompo title="센터 총 전환율" count={10} subText={"%"} icon={"체크"} />
        <BoxCompo
          title="센터 방문자 수"
          count={10}
          subText={"건"}
          icon={"사람"}
        />
      </ContainerStyle>
      <ContainerStyle>
        <BoxCompo
          title="센터 신청자 수"
          count={10}
          subText={"건"}
          icon={"문서"}
        />
        <BoxCompo
          title="센터 완료 수"
          count={10}
          subText={"건"}
          icon={"문서"}
        />
        <BoxCompo
          title="센터 취소 수"
          count={10}
          subText={"건"}
          icon={"문서"}
        />
        <BoxCompo
          title="센터 방문 수"
          count={10}
          subText={"건"}
          icon={"문서"}
        />
      </ContainerStyle>

      <ContainerStyle>
        <BoxCompo
          title="수수료 지급 신청 수"
          count={10}
          subText={"건"}
          icon={"문서"}
        />
        <BoxCompo
          title="수수료 지급 내역 수"
          count={10}
          subText={"건"}
          icon={"문서"}
        />
        <BoxCompo
          title="미지급 수수료 수"
          count={10}
          subText={"건"}
          icon={"문서"}
        />
        <BoxCompo title="센터 전환율" count={10} subText={"%"} icon={"체크"} />
      </ContainerStyle>
    </WrapperDash>
  );
};

export default DashBoard;

 */
/* import React from "react";
import BoxCompo from "../../Component/BoxCompo";
import GenTop from "../../Component/GenTop";
import { WrapperDash } from "../../Styled/DashBoardGlobal";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import NoticeTable from "../../Component/Table/NoticeTable";
import ApplicationTable from "../../Component/Table/ApplicationTable";
const ContainerStyle = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  height: 142px;
`;

const DashBoard = () => {
  const noticeData = {
    headers: ["제목", "작성년월일", "작성자"],
    rows: [
      ["공지 1", "2023-09-26", "작성자 1"],
      ["공지 2", "2023-09-25", "작성자 2"],
      ["공지 3", "2023-09-24", "작성자 3"],
      ["공지 4", "2023-09-23", "작성자 4"],
      ["공지 5", "2023-09-22", "작성자 5"],
    ],
  };

  const applicationData = {
    headers: ["딜러명", "접수자명", "거주지", "상태"],
    rows: [
      ["딜러 1", "접수자 1", "서울", "완료"],
      ["딜러 2", "접수자 2", "부산", "미처리"],
      ["딜러 3", "접수자 3", "대전", "완료"],
      ["딜러 4", "접수자 4", "광주", "미처리"],
      ["딜러 5", "접수자 5", "인천", "완료"],
    ],
  };
  return (
    <WrapperDash>
      <GenTop title={"대시보드"} SubTitle={"환경설정"} SubTitle2={"대시보드"} />
      <ApplicationTable data={applicationData} />

      <ContainerStyle>
        <BoxCompo title="신청서 수" count={10} subText={"건"} icon={"체크"} />
        <BoxCompo title="완료 수" count={10} subText={"건"} icon={"사람"} />

        <BoxCompo title="미처리 수" count={10} subText={"건"} icon={"체크"} />
        <BoxCompo title="취소 수" count={10} subText={"건"} icon={"사람"} />
      </ContainerStyle>
      <ContainerStyle>
        <BoxCompo title="방문 수" count={10} subText={"건"} icon={"문서"} />
        <BoxCompo
          title="수수료 지급신청 건"
          count={10}
          subText={"건"}
          icon={"문서"}
        />
        <BoxCompo
          title="미지급 수수료 건"
          count={10}
          subText={"건"}
          icon={"문서"}
        />
        <BoxCompo title="전환율" count={10} subText={"%"} icon={"문서"} />
      </ContainerStyle>
    </WrapperDash>
  );
};

export default DashBoard;
 */
