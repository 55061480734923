import styled from "@emotion/styled";
import { positions } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { BGSEARCH, C1LIMG, C1RIMG, CallIMG, Card0601, Card0602, Card0603, Card0701, Card0702, Card0703, Card0704, Card0705, CHECKIMG, CONTENTLAYER, LANDING_MOBILE, LANDING_PC, LANDING_TABLET, SHICO } from "../../Assets";
import MobilePage from "./MobilePage";
import PcPage from "./PcPage";

import Modal from 'react-modal';
import './form-page1.css';
import { Fade } from "react-reveal";

const GroundButton = styled.div`
    background: #fc5a1a;
    height: 66%;
    font-size: 3.5vw;
    width: 50%;
 
    font-weight: bold;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    cursor: pointer;
    :hover {
      opacity: 0.5;
    }
`;
/* 

const Landing = ({ route }) => {
  const [selectedSido, setSelectedSido] = useState("");
  const [selectedSigugun, setSelectedSigugun] = useState("");
  const [selectedDong, setSelectedDong] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [searchParams] = useSearchParams();

  // 쿼리 매개변수 값 얻기
  const paramName = searchParams.get("site");

  const location = useLocation();

  useEffect(() => {
    // 특정 경로에서만 CSS import
    import('./form-page1.css')
      .then((module) => {
        // CSS를 동적으로 import한 후, 원하는 동작 수행
      })
      .catch((error) => {
        console.error('CSS import 에러:', error);
      });
  }, [location.pathname]);


  return (
    <>

      <PcPage
        selectedSido={selectedSido}
        setSelectedSido={setSelectedSido}
        selectedSigugun={selectedSigugun}
        setSelectedSigugun={setSelectedSigugun}
        selectedDong={selectedDong}
        setSelectedDong={setSelectedDong}
        site={paramName}
      />
    </>
  );
}; */
const BottomTab = styled.div`
position:absolute;
 
width:100%;
height:4%;
position: absolute;
display:flex;
flex-direction: row;
justify-content: center;
bottom:0px; 
background-color: #222;
`;

const BottomTablInner = styled.div`
width:1150px;
`;
const StyledMobile = styled.div`
  display: none;

  @media (max-width: 368px) {
    display: block;
  }
`;

// 태블릿 화면에 보일 컴포넌트
const StyledTablet = styled.div`
  display: none;

  @media  (max-width: 768px) {
    display: block;
  }
`;

// PC 화면에 보일 컴포넌트
const StyledPc = styled.div`
  display: none;

  @media (min-width: 769px) {
    display: block;
  }
`;

const ImgeViewTablet = styled.div`
  background-image: ${(props) => props.url ? `url('${props.url}')` : 'none'};
  width: 100%;
  height: 4500px;
  background-repeat:no-repeat;
  background-size: cover; /* 배경 이미지를 영역에 맞게 크기 조정 */
  background-position: center; /* 배경 이미지를 가운데 정렬 */
`;



const Phone_ImgeView = styled.div`
  background-image: ${(props) => props.url ? `url('${props.url}')` : 'none'};
  width: 100%;
  height: 2400px;
  background-repeat:no-repeat;
  background-size: cover; /* 배경 이미지를 영역에 맞게 크기 조정 */
  background-position: center; /* 배경 이미지를 가운데 정렬 */
`;




const ImgeView = styled.div`
  background-image: ${(props) => props.url ? `url('${props.url}')` : 'none'};
  width: 100%;
  height: 7822px;
  background-repeat:no-repeat;
  background-size: cover; /* 배경 이미지를 영역에 맞게 크기 조정 */
  background-position: center; /* 배경 이미지를 가운데 정렬 */
`;



const ParentWrap = styled.div`
width:100%;
position: relative;
`;

const ParentBottom = styled.input`
 
 
    background-color: #fff;
    font-size:1.2vw;
    width:45%;
    margin-right:16px;
    border: none;
    height:3.2vw;
    border:none;
    outline: none;
    

`;


const CheckedWrap = styled.div`
display:flex;
flex-direction: row;
background-color: #222;
 
align-items: center;
 
`;

const CheckedInput = styled.input`
  margin-right:10px;
 
`;

const CheckedText = styled.label`
color:#fff;
font-size:16px ;
display:flex;

`;

const CheckedGender = styled.div`
display:flex;
flex-direction: row;
align-items: center;
 
margin-top:16px;
 
`;

const CheckedTextBig = styled.p`
font-size:2.2vw;
margin-right: 0.8vw;
color:#fff;
font-weight: bold;
@media (min-width: 1024px) {
    font-size: 18px;
  }
`;

const AllRequest = styled.div`
position: absolute;
width:300px;
height:300px;
bottom:0px;
left:0pc;
background-color: orange;
cursor: pointer;
:hover {
  opacity: 0.5;
}
`;
const BottomInner = styled.div`
 width:100%;
 background-color: black;
 
`;



const Card1 = styled.div`
background-color: #FC5A1A;
width:100%; 
min-height:650px;
position: relative;
`;

const Card1Wrap = styled.div`
margin:0 auto; 
max-width:1150px;
 
padding-top: 64px;
color: #fff;
font-size:16px;
font-weight: bold;
@media (max-width: 660px) {
    font-size: 1vw;
  }
& p {
  text-align: center;
  
}

& .title {
  @media (max-width: 660px) {
    font-size: 12em !important;
  }
  font-size:72px;
  font-weight: bold;
}
& .sub {
  margin-top:10px;
  font-size:30px;
  @media (max-width: 660px) {
    font-size: 5em !important;
  }
  font-weight:500;
}

.map {
  width:100%;
  display: flex;
  flex-direction: row; 
  padding-top: 40px;
  justify-content: center;
  @media (max-width: 660px) {
 flex-direction: column;
 align-items: center;
 justify-content: flex-start;
  }
  img {
    width:100%;
    height:100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
  .c-l {
    width:524px;
    height:511px;
    @media (max-width: 660px) {
width:80%; height:  auto;
    }
  }
  .c-r {
    width:252px;
    height:598px;
    margin-top: 37px;
    margin-left: 24px;
    margin-bottom: -108px;
    @media (max-width: 660px) {
width:42% !important;  
height:  auto;
 margin-bottom: -30px;
    }
  }
}
`;


const Card2 = styled.div`
position: relative;
background-color: #EAEAEA;
width:100%; 
min-height:350px;
@media (max-width: 660px) {
 min-height: auto;
 padding-top: 64px;
 padding-bottom: 64px;
}
z-index: 999;
display: flex;
justify-content: center;
align-items: center;
`;
const Card2Wrap = styled.div`
margin:0 auto;
max-width: 1150px;
 
height:100%;
@media (max-width: 660px) {
    font-size: 1vw;
  }

.title {
  @media (max-width: 660px) {
 font-size: 6.3em;
 line-height: 1.3em;
  }
  font-size:36px;
  font-weight: bold;
  line-height:42px;
  text-align: center;
}
.t-b {
  color:#FC5A1A;
}
.sub {
  @media (max-width: 660px) {
 font-size: 5.0em;
 line-height: 1.3em;
  }
  margin-top:16px;
  font-size:30px;
  text-align: center;
  line-height: 42px;
  font-weight: 500;
}
`;



const Card3 = styled.div`
position: relative;
background-color: #fff;
width:100%; 
 
 
@media (max-width: 660px) {
 min-height: auto;
 
}
z-index: 999; 
`;
//카드별 스일 


const Card3Wrap = styled.div`
padding-top: 60px;
padding-bottom: 10px;
margin:0 auto;
max-width: 1150px;
height:100%;
min-height:550px;
background-image: url(${CallIMG});
background-repeat: no-repeat;
 background-size: 25% auto; /* 이미지 크기를 500px x 500px로 조정 */
 background-position: right bottom; /* 이미지를 오른쪽 아래로 배치 */

 @media (max-width: 1036px) {
padding-left: 16px;
padding-right: 16px;
 }
 @media (max-width: 600px) {
padding-top: 36px;
min-height: auto;
padding-bottom: 36px;
 }
 @media (max-width: 660px) {
 font-size:1vw;
 }
  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
  .title {
    font-size:42px;
    @media (max-width: 600px) {
font-size:7em;
    }
    color: black;
    font-weight: bold;
  }
  .title > span {
    color: #fc5a1a;
  }
  ul {
    margin-top:24px;
    line-height: 6em;
 
 
  }
  @media (max-width: 600px) {
    ul > li {
    font-size:4.0em  !important;
    margin-top:26px !important;
    }
    }
  ul > li {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size:30px;
    font-weight:bold !important;
    font-weight:500;
 
  }

  @media (max-width: 600px) {
    .img {
    width:24px !important;
    height:24px !important;
  
  }
  }
   .img {
    width:34px;
    height:34px;
    margin-right: 16px;
  }
`;





const Card4 = styled.div`
  position: relative; 
  background-image: url(${BGSEARCH});
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: 500px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    background-size: contain;
  }

  @media (max-width: 768px) {
    background-size: cover;
    font-size:1vw;
  }
 
  @media (max-width: 1198px) {
    min-height: auto;
    height: 28vw; /* 화면 크기에 따라 높이가 자동으로 조정됨 */
}
`;

const Card4Btn = styled.div`
background-color: #FC5A1A;
 padding-left: 24px;
 padding-right: 24px;
 padding-top: 16px;
 padding-bottom: 16px;
 @media (max-width: 768px) {
font-size:5em;
padding-left: 16px !important;
 padding-right: 16px !important;
 padding-top: 10px !important;
 padding-bottom: 10px !important;
 }
font-size:34px;
font-weight:bold;
color:#fff;
 
display: flex;
justify-content: center;
align-items: center;
border-radius: 8px;
cursor: pointer;
:hover {
  opacity: 0.5;
}

.t-t {
  margin-left:20px;
}
@media (max-width: 768px) {
  .img {
width:16px;
height:16px;
  }
}
.img {
  width:28px;
  height:28px;
 margin-left: 10px;

}
img {
  width:100%;
  height:100%;
  max-width: 100%;
  max-height: 100%;
}

 
`;

const Card5 = styled.div`
background-color: #EAEAEA;
position: relative; 
margin-top:-8px;
 

`;
const Card5Wrap = styled.div`
max-width: 1150px;
width:100%;
margin:0 auto;
padding-top: 36px;
padding-bottom: 36px;
@media (max-width: 768px) { 
font-size:1vw;

.titleWrap {
  font-size:5em !important;
  line-height: 1.2em !important;
}
}

.titleWrap {
  font-size:34px;
  color: #fc5a1a;
  font-weight: bold;
  text-align:center;
  line-height: 42px;
}

.img {
  width:100%;
}
img {
  max-width: 100%;
  width:100%;
  max-height: 100%;
  height:100%;
}
.content {
  margin-top:46px;
  padding-left: 16px;
  padding-right: 16px;
}

`;

const Card6 = styled.div`
background-color: #fff;
position: relative;  

`;
const Card6WRap = styled.div`
padding-top:48px;
padding-bottom: 48px;
max-width:1150px;
margin:0 auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media (max-width: 768px) { 
font-size:1vw;

}
li:nth-child(2) {
  flex-direction: row-reverse;
}
li:nth-child(2) > .c-txt {
  margin-left:  auto !important;
  margin-right: 64px !important;
}
li {
  margin-top:34px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
}

 .c-txt {
  margin-left:64px !important;
  line-height: 16px;
  
}
 .c-txt > .subTitle {
  line-height:24px;
}
.c-txt > .title {
  line-height:34px;
}
li .title {
  font-size:34px;
  font-weight: bold;
  text-align:start;
} 

@media (max-width: 768px) { 
  li .title {
  font-size:4.8em;

  }
  .Infotitle {
    font-size:6em !important;
  }
  .content .img {
  width:30em !important; 
  height:30em !important;
}
  li {
    justify-content: space-around;
  }
  li:nth-child(2) > .c-txt {
  margin-left:  auto !important;
  margin-right: auto!important;
}
  .c-txt {
margin-left: auto !important;
margin-right:auto !important;
  }
  li .subTitle {
 font-size:3.9em !important; 
  }
}
li .subTitle {
  font-size:24px;
  font-weight:400;
  line-height: 1em !important;
}
li .title {
  line-height: 1em !important;
}
.content {
  margin-top:36px;
}
.content .img {
  width:256px;
  height:256px;
}
img {
  object-fit: contain;
  max-width: 100%;
  width:100%;
  max-height: 100%;
  height:100%;
}

.Infotitle {
  font-size:34px;
  text-align: center;
 font-weight: bold;
}
.main_color {
  color:#FC5A1A;
}
`;


const Card7 = styled.div`
background-color: #EAEAEA;
position: relative;  

`;

const Card7WRap = styled.div`
max-width:700px;
margin:0 auto;
padding-top: 36px;
padding-bottom: 36px;

@media (max-width: 768px) { 
padding-left: 16px;
padding-right: 16px;
}
@media (max-width: 768px) { 
font-size:1vw;
}
 
li {
   justify-content: space-around;
   font-weight: 400;
}
ul > li:nth-child(2n) {
  flex-direction: row-reverse;
}

 
.info {
  @media (max-width: 768px) { 
   margin-top:1em;
font-size:3.4em;
line-height: 1.2em;
  }
  font-size:18px;
  font-weight: 400;
  margin-top:24px;
  line-height: 28px;;
}
.usr {
  @media (max-width: 768px) { 
    font-size:5em;
  }
  font-size:24px;
  color: #fc5a1a;
  font-weight:bold;
}
.title {
  text-align: center;
  font-size:42px;
  font-weight: bold;
  margin-top:24px;
  margin-bottom: 48px;
}
.main_color {
  color:#FC5A1A;
}
ul > li {
  background-color: #fff;
  border-radius : 16px;
  margin-top:24px;
  padding-left: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 테두리 그림자 추가 */
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
} 
 img {
  max-width: 100%;
  max-height:100%;
  width:100%;
  height:100%;
  object-fit: contain;
 }
.img {

@media (max-width: 768px) { 
width:50em;
height:25.8em;
}
   width:185px;
   height:154px;
    
}
`;

const Card08 = styled.div`
background-color: black;
 
position:relative;
`
const Card08Wrap = styled.div`
margin:0 auto;
max-width:880px;
padding-top: 54px;
padding-bottom: 54px;
.radio-input {
  margin-left:38px;
  color:#fff;
  font-size:14px;
  display:flex;
  flex-direction: column;
  justify-content: center;
}
.radio-inner {
  display:flex;
  margin-left: 10px;
  flex-direction: row;
  align-items: center;
}
.btn {
  background-color:#FC5A1A ;
  
}
@media (max-width: 873px) { 
padding-left: 16px;
padding-right:16px;

padding-top:16px !important;
padding-bottom: 16px !important;
.flex-r {
  padding-left:16px;
  padding-right:16px;
  width:100%;
}
font-size:1vw;
.btn {
  width:100%;
  text-align:center;
  padding-left:0px !important;
  padding-right:0px !important;
  margin-top:24px !important;
  font-size:6em !important;
}
 .radio-input {
  margin-left:0px !important;
 }
  .flex-top {
  flex-direction: column !important;
  }
  .flex-l-t {
 flex-direction: column-reverse !important;
  }
  .radio-inner:nth-child(1) {
    margin-left:0px !important;
  }
  .txt-input {
    margin-top:16px;
  }
  .flex-l-b{ 
    margin:0px !important;
    flex-direction: column !important;
  }
  .flex-l {
    width:100% !important;
  }
  .txt-input {
    width:90% !important;
  }
}

.flex-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn {
  color:#fff;
  font-weight: bold;
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 26px;
  padding-bottom: 26px;
  font-size:34px;
  border-radius: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
}
.radio-wrap {
  display:flex;
  flex-direction: row;
  align-items: center;
 
}
input[type=text]{
  width:100%;
   padding:16px;
   @media (max-width: 873px) { 
padding-left:16px;
padding-top:12px !important;
padding-bottom: 12px !important;
   }
  
}
 
input[type=radio]{
  width:25px;
  height:25px;
  margin-right:10px;
  
}
.flex-l-t {
  display:flex;
  flex-direction: row;
 
}
.flex-l {
  display:flex;
  flex-direction: column;
  
  width:63%;
}
.flex-l-b{
  margin-top:16px;
  width:100%;
  display: flex;
 justify-content: space-between;
}
.txt-flex {
 
  width:45%;
} 

.txt-t-input {
  width:45%;
}
`;


const Card09 = styled.div`
background-color:#D31145;
position:relative;
padding-top:34px;
padding-bottom:34px;
font-size:1vw;
@media (max-width: 873px) { 
  padding-left:16px;
  padding-right:16px;
 
  .title {
    font-size:6em !important;
  }
 }

.title {
  font-size:30px;
  font-weight:bold;
  color:#fff;
}
display:flex;
flex-direction: column;
align-items:center;
`;
const Card09Wrap = styled.div`
padding-top:24px;
padding-bottom:24px;
border-radius:8px;
margin-top:24px;
width:100%;
max-width:1000px;
background-color:#D7D8DC;
  display: flex;
    text-align:center;
  flex-direction: column;
  align-items: center;
 font-size:1vw;
 .r-tint {
  background-color:#D31145;
  color:#fff;
 }

.a-title {
  line-height:24px;
}
 .a-title p {
  color: #d31145;
  font-size:16px;
  
 }

 @media (max-width: 873px) { 
 
  .a-title p {
  color: #d31145;
  font-size:2.4em;
  line-height:1.6em;
  padding-left:16px;
  padding-right:16px;
 }
 .a-info {
  font-size:2.3em !important;
line-height: 1.6em !important;
  padding-left:16px;
  padding-right:16px;
 }
 }
 .a-info {
  margin-top:20px;
  font-weight:bold;
  font-size:14px;
  line-height:24px;
 }

`;

const Landing = () => {

  const [require, setRequire] = useState(false);
  const [data, setData] = useState({
    name: "",
    birthd: "",
    tel: "",
    gender: "M"
  })

  const [isOpen, setIsOpen] = useState(false);

  const container = useRef(null);

  const sectionRef = useRef(null);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (<>
    <Fade>

      <Card1>
        <Card1Wrap>
          <div className="t-content">
            <p className="title">
              보험 네비게이션
            </p>
            <p className="sub">
              내보험 나에게 맞는 보장으로 가고있나요?
            </p>
          </div>
          <div className="map">
            <div className="c-l">

              <img src={C1LIMG} />

            </div>
            <div className="c-r">
              <img src={C1RIMG} />
            </div>
          </div>
        </Card1Wrap>
      </Card1>

    </Fade>
    <Fade>

      <Card2>
        <Card2Wrap>
          <p className="title">
            VIP 보험보장분석 무료 서버스로<br />
            <span className="t-b">안전보험</span> 하세요.
          </p>
          <p className="sub">
            부족한보장은없는지,불필요한가입은없는지<br />
            보험전문가가 안내 해드립니다.
          </p>
        </Card2Wrap>

      </Card2>

    </Fade>
    <Fade>

      <Card3>
        <Card3Wrap>
          <div className="title">
            내보험  <span>Check point</span>
          </div>
          <ul>
            <li>
              <div className="img">

                <img src={CHECKIMG} />
              </div>수입에 비해 과다한 보험료를 내고 있지는 않은가?</li>
            <li> <div className="img"><img src={CHECKIMG} /></div>가입한 보험의 보장범위가 부족하지 않은가?</li>
            <li> <div className="img"><img src={CHECKIMG} /></div>보장 기간은 충분한가?</li>
            <li> <div className="img"><img src={CHECKIMG} /></div>보장이 중복되는 보험이 있지 않을까?</li>
            <li> <div className="img"><img src={CHECKIMG} /></div>내 보험료는 왜 매년 오르지?</li>

          </ul>
        </Card3Wrap>
      </Card3>
    </Fade>
    <Fade>

      <Card4>
        <Card4Btn onClick={() => {
            scrollToSection();
          }} >
          <div  className="t-t">
            내보험 분석하기</div>
          <div className="img">
            <img src={SHICO} /></div>
        </Card4Btn>

      </Card4>

    </Fade>
    <Fade>

      <Card5>
        <Card5Wrap>
          <div className="titleWrap">
            <p className="title">
              보험 전문가<br />
              <span>1:1 보험분석 서비스</span>
            </p>


          </div>
          <div className="content">
            <div className="img">
              <img src={CONTENTLAYER} />
            </div>
          </div>
        </Card5Wrap>
      </Card5>

    </Fade>
    <Fade>

      <Card6>
        <Card6WRap>
          <div className="Infotitle">
            보험전문가의  <span className="main_color"> 보험점검 Point</span>
          </div>
          <div className="content">
            <ul>
              <li>
                <div className="img">
                  <img src={Card0601} />
                </div>
                <p className="c-txt">
                  <p className="title">  가입한 모든 보험 점검</p><br />
                  <span className="subTitle">가입한 <span className="main_color">모든 보험을 한번에 점검</span> 받으세요. </span>
                </p>

              </li>
              <li>
                <div className="img">
                  <img src={Card0602} />
                </div>
                <p className="c-txt">
                  <p className="title">월 보험료 점검</p><br />
                  <span className="subTitle">
                    <span className="main_color">  나이와 소득에 맞는 보험료 </span>를 내는지   <br />점검 받으세요.</span>
                </p>
              </li>
              <li>
                <div className="img">
                  <img src={Card0603} />
                </div>
                <p className="c-txt">
                  <p className="title">보장 항목 점검</p><br />
                  <span className="subTitle"><span className="main_color">보장이 과하거나 부족하지 않은지</span> <br />  점검 받으세요. </span>
                </p>
              </li>

            </ul>


          </div>
        </Card6WRap>

      </Card6>
    </Fade>
    <Fade>

      <Card7>
        <Card7WRap>
          <div className="title">
            신청자 <span className="main_color">상담후기</span>

          </div>
          <ul>
            <li>
              <div className="img">
                <img src={Card0701} />
              </div>
              <div className="content">
                <p className="usr">김ㅇㅇ(만48세)</p>
                <p className="info">
                  친절하게 상담해 주시고 보장도 필요한 것만 추천해 주셔서 잘 받았어요~^^
                </p>
              </div>
            </li>
            <li>
              <div className="img">
                <img src={Card0702} />
              </div>
              <div className="content">
                <p className="usr">김ㅇㅇ(만56세)</p>
                <p className="info">
                  보험사마다 특징이 있는데 한 번에 알기 쉽게 비교해주니 이해가 잘되네요.
                </p>
              </div>
            </li>
            <li>
              <div className="img">
                <img src={Card0703} />
              </div>
              <div className="content">
                <p className="usr">이ㅇㅇ(만39세)</p>
                <p className="info">
                  우와~ 대박이네요. 상담 받고 보험료 확 줄였어요. 너무 감사해요.
                </p>
              </div>

            </li>
            <li>
              <div className="img">
                <img src={Card0704} />
              </div>
              <div className="content">
                <p className="usr">조ㅇㅇ(만45세)</p>
                <p className="info">
                  보험 가입은 처음인데 상담을 통해서 저에게 필요한 보험으로 가입했어요.
                  이제 좀 마음이 든든해요.
                </p>
              </div>
            </li>
            <li>
              <div className="img">
                <img src={Card0705} />
              </div>
              <div className="content">
                <p className="usr">송ㅇㅇ(만53세)</p>
                <p className="info">
                  아는 지인보다 믿을만하네요. 새로 가입한 보험이 기존보다  훨씬 저렴한데
                  혜택 범위가 넓어졌어요.
                </p>
              </div>
            </li>
          </ul>

        </Card7WRap>

      </Card7>

    </Fade>
 

      <Card08 id="target-section" ref={sectionRef} >
        <Card08Wrap>
          <div className="flex-top">
            <div className="flex-l">
              <div className="flex-l-t">
                <div className="txt-input txt-t-input">

                  <input type="text" placeholder="이름"
                    value={data?.name}
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value })
                    }} />

                </div>
                <div className="radio-input">
                  <div className="radio-wrap">

                    <div onClick={(e) => {
                      setData({ ...data, gender: "M" })
                    }} className="radio-inner">
                      <input
                        checked={data?.gender === "M"} type="radio" />
                      <p>남성</p>
                    </div>
                    <div onClick={(e) => {
                      setData({ ...data, gender: "W" })
                    }} className="radio-inner">
                      <input
                        checked={data?.gender === "W"} type="radio" />
                      <p>여성</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-l-b">
                <div className="txt-input txt-flex">
                  <input type="text" placeholder="생년월일(예,19920101)"
                    value={data?.birthd}
                    onChange={(e) => {
                      setData({ ...data, birthd: e.target.value })
                    }} />

                  <CheckedGender>
                    <CheckedInput
                      onClick={() => {
                        setRequire(!require)
                      }}
                      checked={require}
                      id="checked" type={'checkbox'} />
                    <CheckedText for="checked">
                      (필수)개인정보처리동의 <div onClick={() => {
                        setIsOpen(true);
                      }} style={{ cursor: 'pointer' }}>[보기]</div>
                    </CheckedText>
                  </CheckedGender>
                </div>
                <div className="txt-input txt-flex">
                  <input type="text" placeholder="휴대폰(-없이 입력하세요)"

                    value={data?.tel}
                    onChange={(e) => {
                      setData({ ...data, tel: e.target.value })
                    }} />


                </div>

              </div>
            </div>
            <div className="flex-r">
              <div onClick={() => {
                if (data?.name === "") {
                  alert('이름을 입력해주세요.');
                  return;
                } else if (data?.gender === "") {
                  alert('성별을 선택해주세요.');
                  return;
                } else if (data?.tel === "") {
                  alert('연락처를 기입해주세요. ');
                  return;
                } else if (require === false) {
                  alert('약관에 동의주세요')
                  return;
                }
                alert('접수가 완료 되었습니다')
                return;

              }} className="btn">
                무료 상담신청
              </div>
            </div>
          </div>
        </Card08Wrap>
      </Card08>

 

    <Fade>

      <Card09>

        <p className="title">AIA 프리미어 파트너스 </p>

        <Card09Wrap>
          <div className="a-title">

            <p>
              보험계약자가 기본 보험계약을 해지하고 새로운 보험 계약을 체결하는  과정에서
            </p>
            <p>➀ 질병이력,연령증 등으로가입이 거절되거나 보험료가  인상될 수  있습니다.</p>
            <p>② 가입 상품에 따라 새로운 보험금 지급제한기간 적용 및 보장 제한 등</p>
            <p>기타 불이익이 발생할 수 있습니다.</p>

          </div>
          <div className="a-info">
            <p className="a-info-t">AIA 프리미어파트너스(주) [보험대리점 등록번호: 제 2023088016호]</p>
            <p className="a-info-m"><span className="r-tint"> 본 광고는 생명보험 광고심의기준을 준수하였으며, 유효기간은</span>   심의일 로부터 1년입니다.</p>
            <p className="a-info-b">생명보험협회 심의필 제2024-02394호 (2024.04.16 ~ 2025.04.15)</p>
          </div>
        </Card09Wrap>
      </Card09>

    </Fade>
    <div
      className="modal modal-personal-data d-none"
      style={{ display: isOpen ? "block" : "none" }} //toggleSEt 조건도 추가
    >
      <a
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(false)
        }}
        className="modal-btn">
        <span />
        <span />
      </a>
      <div className="modal-wrap">
        <div>
          <h3>
            개인정보 수집 및 이용에 관한 동의

          </h3>
          <div className="small-box bg-gray">
            <dl>
              <dt>1. 수집자</dt>
              <dd>
                <ul>
                  <li>
                    엠예스예스 주식회사
                  </li>
                </ul>
              </dd>
              <dt>2. 수집·이용 항목</dt>
              <dd>
                <ul>
                  <li>
                    이름, 생년월일, 성별, 휴대전화번호, 지역, 기타문의
                  </li>
                </ul>
              </dd>
              <dt>3.수집·이용 목적</dt>
              <dd>
                <ul>
                  <li>
                    보험상담, 보험 리모델링 및 가입 권유를 위한 안내 및 서비스 (이름, 생년월일, 휴대전화번호) -상담자 인근 설계사 또는 위탁 계약 체결자 연계 [지역명(시/군)]
                  </li>
                </ul>
              </dd>
              <dt>4. 보유·이용기간</dt>
              <dd>
                <ul>
                  <li>
                    동의일로부터 1년
                  </li>
                  <li>
                    귀하는 개인정보 수집·이용에 대한 동의를 거부할 권리가 있습니다. 단, 동의 거부 시 보험계약 상담 서비스를 받으실 수 없습니다.
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="small-box bg-gray">
            <dl>
              <dt>1. 제공받는자</dt>
              <dd>
                <ul>
                  <li>
                    당사의 모집 위탁 계약을 체결한자 (대리점, 설계사)
                  </li>
                  <li>
                    보험사: AIA프리미어파트너스, 굿리치㈜
                  </li>
                </ul>
              </dd>
              <dt>2. 제공 항목</dt>
              <dd>
                <ul>
                  <li>
                    이름, 생년월일, 성별, 휴대전화번호, 지역, 기타문의
                  </li>
                </ul>
              </dd>
              <dt>3.제공 목적</dt>
              <dd>
                <ul>
                  <li>
                    보험상담, 보험 리모델링 및 가입 권유를 위한 안내 및 서비스 (이름, 생년월일, 휴대전화번호) -상담자 인근 설계사 또는 위탁 계약 체결자 연계 [지역명(시/군)]
                  </li>
                </ul>
              </dd>
              <dt>4. 보유·이용기간</dt>
              <dd>
                <ul>
                  <li>
                    동의일로부터 1년
                  </li>
                  <li>
                    귀하는 개인정보 제공에 대한 동의를 거부할 권리가 있습니다. 단, 동의 거부 시 보험계약 상담 서비스를 받으실 수 없습니다.
                  </li>
                </ul>
              </dd>
            </dl>
          </div>

          <h2>개인정보처리방침</h2>
          <p>
            &lt; mss &gt;('https://mss리서치.com/' 이하 'mss리서치')은(는)
            「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고
            이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여
            다음과 같이 개인정보 처리방침을 수립·공개합니다.
          </p>
          <span>이 개인정보처리방침은 2023년 12월 1부터 적용됩니다.</span>
        </div>
        <div>
          <h3>
            제1조
            <br />
            개인정보의 처리 목적
          </h3>
          <p>
            &lt; mss &gt;('https:mss리서치.com/'이하 'mss리서치')은(는) 다음의
            목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는
            다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는
            경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등
            필요한 조치를 이행할 예정입니다.
          </p>
          <div className="small-box bg-gray">
            <dl>
              <dt>1. 회원가입 및  관리</dt>
              <dd>
                <ul>
                  <li>
                    서비스 제공에 따른 본인 식별·인증, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인 목적으로 개인정보를 처리합니다.
                  </li>
                </ul>
              </dd>
              <dt>2. 민원사무 처리</dt>
              <dd>
                <ul>
                  <li>
                    민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.
                  </li>
                </ul>
              </dd>
              <dt>3.재화 또는 서비스 제공</dt>
              <dd>
                <ul>
                  <li>
                    서비스 제공, 계약서·청구서 발송, 맞춤서비스 제공을 목적으로 개인정보를 처리합니다.
                  </li>
                </ul>
              </dd>
              <dt>4. 마케팅 및 광고에의 활용</dt>
              <dd>
                <ul>
                  <li>
                    신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 서비스의 유효성 확인 등을 목적으로 개인정보를 처리합니다.
                  </li>

                </ul>
              </dd>
            </dl>
          </div>
        </div>
        <div>
          <h3>
            제2조
            <br />
            개인정보의 처리 및 보유 기간
          </h3>
          <p>
            ①  {`< MSS >`}은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
          </p>
          <p>
            ②  각각의 개인정보 처리 및 보유 기간은 다음과 같습니다. (1년)
          </p>
        </div>
        <div>
          <h3>
            제3조
            <br />
            처리하는 개인정보의 항목
          </h3>
          <p>
            {`< MSS >`}은(는) 다음의 개인정보 항목을 처리하고 있습니다.
            개인정보는 수집.이용에 관한 동의일로부터까지 위 이용목적을 위하여 보유.이용됩니다.
            필수항목 : 이름, 생년월일, 성별, 주소, 휴대전화번호

          </p>

        </div>
        <div>
          <h3>
            제4조
            <br />
            개인정보의 제3자 제공에 관한 사항
          </h3>
          <p>
            ① {`< MSS >`}은(는) 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
          </p>
          <p>
            ② {`< MSS >`}은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
          </p>

          <div className="small-box bg-gray">
            <dl>

              <ul>
                <li>개인정보를 제공받는 자 : 굿리치, AIA프리미어파트너스</li>
                <li>제공받는 자의 개인정보 이용목적 : 보험 보장 분석 및 상담</li>
                <li>제공받는 자의 개인정보 이용목적 : 이름, 생년월일, 성별, 주소, 휴대전화번호</li>
                <li>제공받는 자의 보유.이용기간: 1년</li>
              </ul>

            </dl>
          </div>
        </div>
        <div>
          <h3>
            제5조
            <br />
            개인정보의 파기절차 및 파기방법
          </h3>
          <p>
            ① {`< MSS >`} 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
          </p>
          <p>
            ② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
          </p>

          <p>
            ③	개인정보 파기의 절차 및 방법은 다음과 같습니다.
          </p>            <div className="small-box bg-gray">
            <dl>
              <dt>1. 파기절차</dt>
              <dd>
                <br />
                <ul>
                  {`< MSS >`} 은(는) 파기 사유가 발생한 개인정보를 선정하고, {`< MSS >`} 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                </ul>
              </dd>
            </dl>
            <dl style={{ marginTop: 16 }}>
              <dt>2. 파기방법</dt>
              <dd>
                <br />
                <ul>
                  종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                  전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다

                </ul>
              </dd>
            </dl>
          </div>

        </div>
        <div>
          <h3>
            제6조
            <br />
            정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항
          </h3>
          <p>
            ①	 정보주체는 {`< MSS >`}에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
          </p>
          <p>
            ②	 제1항에 따른 권리 행사는 {`< MSS >`}에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 {`< MSS >`}은(는) 이에 대해 지체 없이 조치하겠습니다.
          </p>
          <p>
            ③	 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
          </p>
          <p>
            ④	 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
          </p>
          <p>
            ⑤	 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
          </p>
          <p>
            ⑥	 {`< MSS >`}은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
          </p>
          <div className="small-box bg-gray">
            <dl>
              <dt>
                제7조
                <br />
                개인정보의 안전성 확보조치에 관한 사항
              </dt>
              <dt>{`< MSS >`}은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</dt>
            </dl>
          </div>

          <div className="small-box bg-gray">
            <dl>
              <dt>내부관리계획의 수립 및 시행</dt>
              <dd>
                개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
              </dd>
              <dt>2. 개인정보에 대한 접근 제한</dt>
              <dd>
                <ul>
                  <li>
                    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
                  </li>

                </ul>
              </dd>
            </dl>
          </div>
        </div>
        <div>
          <h3>
            제8조
            <br />
            개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항
          </h3>
          <p>
            {`< MSS >`}은(는) 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.



          </p>
        </div>
        <div>
          <h3>
            제9조
            <br />
            행태정보의 수집·이용·제공 및 거부 등에 관한 사항
          </h3>
          <p>
            {` < MSS >`}은(는) 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지 않습니다.
          </p>

        </div>
        <div>
          <h3>
            제10조
            <br />
            추가적인 이용·제공 판단기준
          </h3>
          <p>
            {`< MSS >`} 은(는) ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다. 이에 따라 {`< MSS > `}가(이) 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.
          </p>
          <dl>

            <dd>
              <ul>
                <li>
                  개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부
                </li>
                <li>
                  개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부
                </li>
                <li>
                  개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부
                </li>
                <li>
                  가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부
                </li>
                <li>
                  추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로 자율적으로 판단하여 작성·공개함
                </li>

              </ul>
            </dd>
          </dl>
        </div>

        <div>
          <h3>
            제11조
            <br />
            개인정보 보호책임자에 관한 사항
          </h3>
          <p>
            {`< MSS >`} 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
          </p>
          <div className="small-box bg-gray">
            <ul className="arrow">
              <li>
                개인정보 보호책임자
                <ul>
                  <li>성명 :박현</li>
                  <li>직책 :대표</li>

                  <li>
                    연락처 :070-4725-0204, Mss740204@naver.com,
                    <br />
                  </li>
                </ul>
              </li>
              <li>
                개인정보 보호책임자
                <ul>
                  <li>부서명 :관리부</li>
                  <li>담당자 :조찬호</li>

                  <li>
                    연락처 :070-4725-0204
                    <br />
                  </li>
                </ul>
              </li>

            </ul>
          </div>
          <p>
            정보주체는 {`< MSS >`}의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. {`< MSS >`}은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
          </p>
        </div>
        <div>
          <h3>
            제12조
            <br />
            개인정보의 열람청구를 접수·처리하는 부서
          </h3>
          <p>
            정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. {`< MSS >`}은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
          </p>
          <div className="small-box bg-gray">
            <ul className="arrow">
              <li>
                개인정보 열람청구 접수·처리 부서
                <ul>
                  <li>부서명 :관리</li>
                  <li>담당자 : 조찬호</li>

                  <li>
                    연락처 : 070-4725-0204, mss740204@naver.com
                    <br />
                  </li>
                </ul>
              </li>


            </ul>
          </div>

        </div>

        <div>
          <h3>
            제13조
            <br />
            국내대리인의 지정
          </h3>
          <p>
            정보주체는 ｢개인정보 보호법｣ 제39조의11에 따라 지정된 &lt; mss
            &gt;의 국내대리인에게 개인정보 관련 고충처리 등의 업무를 위하여
            연락을 취할 수 있습니다. &lt; mss &gt;은(는) 정보주체의 개인정보
            관련 고충처리 등 개인정보 보호책임자의 업무 등을 신속하게 처리할
            수 있도록 노력하겠습니다.
          </p>

        </div>

        <div>
          <h3>
            제13조
            <br />
            정보주체의 권익침해에 대한 구제방법
          </h3>
          <p>
            정보주체는 개인정보침해로 인한 구제를 받기 위하여
            개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
            분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
            개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
            바랍니다.
          </p>
          <div className="small-box bg-gray">
            <dl>
              <dt />
              <dd>
                <ul>
                  <li>
                    개인정보분쟁조정위원회 : (국번없이) 1833-6972
                    (www.kopico.go.kr)
                  </li>
                  <li>
                    개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
                  </li>
                  <li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
                  <li>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
                </ul>
              </dd>
            </dl>
          </div>
          <p>
            「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의
            정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대
            하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
            이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
            청구할 수 있습니다.
            <span>
              행정심판에 대해 자세한 사항은
              중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기
              바랍니다.
            </span>
          </p>
        </div>

        <div>
          <h3>
            제14조
            <br />
            개인정보 처리방침 변경
          </h3>
          <p>
            이 개인정보처리방침은 2023년 12월 1부터 적용됩니다.
            이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.

          </p>



        </div>
      </div>

    </div>
    {/*  <BottomInner>
      <div style={{ width: '70%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <ParentBottom placeholder="이름"
            value={data?.name}
            onChange={(e) => {
              setData({ ...data, name: e.target.value })
            }}
            style={{ left: 331, bottom: 171, marginRight: '1.2vw' }} />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CheckedGender>

              <CheckedInput
                onClick={(e) => {
                  setData({ ...data, gender: "M" })
                }}
                checked={data?.gender === "M"} type="radio" style={{ width: '1.5vw', height: '1.5vw' }} />
              <CheckedTextBig>
                남성
              </CheckedTextBig>
            </CheckedGender>
            <CheckedGender style={{ left: 829 }}>

              <CheckedInput
                onClick={(e) => {
                  setData({ ...data, gender: "W" })
                }}
                checked={data?.gender === "W"} type="radio" style={{ width: '1.5vw', height: '1.5vw' }} />
              <CheckedTextBig>
                여성
              </CheckedTextBig>
            </CheckedGender>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '1.2vw' }}>
          <ParentBottom placeholder="생년월일(예,19920101)"
            value={data?.birthd}
            onChange={(e) => {
              setData({ ...data, birthd: e.target.value })
            }}
          />

          <ParentBottom placeholder="휴대폰(-없이 입력하세요)"

            value={data?.tel}
            onChange={(e) => {
              setData({ ...data, tel: e.target.value })
            }}
          />


        </div>
        <CheckedWrap  >

          <CheckedGender>
            <CheckedInput
              onClick={() => {
                setRequire(!require)
              }}
              checked={require}
              style={{ marginRight: '1.2vw', width: '1.2vw', height: '1.2vw' }} id="checked" type={'checkbox'} />
            <CheckedText for="checked">
              (필수)개인정보처리동의 [보기]
            </CheckedText>
          </CheckedGender>
        </CheckedWrap>
      </div>

      <GroundButton
        onClick={() => {
          if (data?.name === "") {
            alert('이름을 입력해주세요.');
            return;
          } else if (data?.gender === "") {
            alert('성별을 선택해주세요.');
            return;
          } else if (data?.tel === "") {
            alert('연락처를 기입해주세요. ');
            return;
          } else if (require === false) {
            alert('약관에 동의주세요')
            return;
          }
          alert('접수가 완료 되었습니다')
          return;

        }}
      >
        <p>무료상담신청</p>

      </GroundButton>
    </BottomInner> */}
  </>)
}
const Landing2 = ({ route }) => {
  const [checked, setChecked] = useState(false);

  const [require, setRequire] = useState(false);
  const [data, setData] = useState({
    name: "",
    birthd: "",
    tel: "",
    gender: "M"
  })

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>


      <StyledTablet>
        <ParentWrap>


          <BottomTab>

            <BottomInner>
              <div style={{ width: '70%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                  <ParentBottom placeholder="이름"
                    value={data?.name}
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value })
                    }}
                    style={{ left: 331, bottom: 171, marginRight: '1.2vw' }} />
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CheckedGender>

                      <CheckedInput
                        onClick={(e) => {
                          setData({ ...data, gender: "M" })
                        }}
                        checked={data?.gender === "M"} type="radio" style={{ width: '1.5vw', height: '1.5vw' }} />
                      <CheckedTextBig>
                        남성
                      </CheckedTextBig>
                    </CheckedGender>
                    <CheckedGender style={{ left: 829 }}>

                      <CheckedInput
                        onClick={(e) => {
                          setData({ ...data, gender: "W" })
                        }}
                        checked={data?.gender === "W"} type="radio" style={{ width: '1.5vw', height: '1.5vw' }} />
                      <CheckedTextBig>
                        여성
                      </CheckedTextBig>
                    </CheckedGender>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1.2vw' }}>
                  <ParentBottom placeholder="생년월일(예,19920101)"
                    value={data?.birthd}
                    onChange={(e) => {
                      setData({ ...data, birthd: e.target.value })
                    }}
                  />

                  <ParentBottom placeholder="휴대폰(-없이 입력하세요)"

                    value={data?.tel}
                    onChange={(e) => {
                      setData({ ...data, tel: e.target.value })
                    }}
                    style={{ left: 711 }} />


                </div>
                <CheckedWrap style={{ marginTop: "1.2vw" }} >

                  <CheckedGender>
                    <CheckedInput
                      onClick={() => {
                        setRequire(!require)
                      }}
                      checked={require}
                      style={{ marginRight: '1.2vw', width: '1.2vw', height: '1.2vw' }} id="checked" type={'checkbox'} />
                    <CheckedText for="checked">
                      (필수)개인정보처리동의 [보기]
                    </CheckedText>
                  </CheckedGender>
                </CheckedWrap>
              </div>

              <GroundButton
                onClick={() => {
                  if (data?.name === "") {
                    alert('이름을 입력해주세요.');
                    return;
                  } else if (data?.gender === "") {
                    alert('성별을 선택해주세요.');
                    return;
                  } else if (data?.tel === "") {
                    alert('연락처를 기입해주세요. ');
                    return;
                  } else if (require === false) {
                    alert('약관에 동의주세요')
                    return;
                  }
                  alert('접수가 완료 되었습니다')
                  return;

                }}
              >
                <p>무료상담신청</p>

              </GroundButton>
            </BottomInner>





          </BottomTab>
        </ParentWrap>
      </StyledTablet>
      <StyledPc>
        <ParentWrap>

          <img style={{ width: '100%' }} src={LANDING_PC} />

          <BottomTab>

            <BottomInner>
              <div style={{ width: '70%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                  <ParentBottom
                    value={data?.name}
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value })
                    }}
                    placeholder="이름" style={{ left: 331, bottom: 171, marginRight: '1.2vw' }} />
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CheckedGender>

                      <CheckedInput
                        checked={data?.gender === "M"}
                        onClick={(e) => {
                          setData({ ...data, gender: 'M' })
                        }}
                        type="radio" style={{ width: '1.5vw', height: '1.5vw' }} />
                      <CheckedTextBig>
                        남성
                      </CheckedTextBig>
                    </CheckedGender>
                    <CheckedGender style={{ left: 829 }}>

                      <CheckedInput
                        checked={data?.gender === "W"}
                        onClick={(e) => {
                          setData({ ...data, gender: 'W' })
                        }}
                        type="radio" style={{ width: '1.5vw', height: '1.5vw' }} />
                      <CheckedTextBig>
                        여성
                      </CheckedTextBig>
                    </CheckedGender>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
                  <ParentBottom placeholder="생년월일(예,19920101)" value={data?.birthd} onChange={(e) => {
                    setData({ ...data, birthd: e.target.value })
                  }} />

                  <ParentBottom placeholder="휴대폰(-없이 입력하세요)"

                    value={data?.tel}
                    style={{ left: 711 }} onChange={(e) => {
                      setData({ ...data, tel: e.target.value })
                    }} />


                </div>
                <CheckedWrap style={{ marginTop: 12 }} >

                  <CheckedGender>
                    <CheckedInput
                      onClick={() => {
                        setRequire(!require)
                      }}
                      checked={require}
                      id="checked" type={'checkbox'} />
                    <CheckedText onClick={() => {
                      setIsOpen(true)
                    }} for="checked">
                      (필수)개인정보처리동의 [보기]
                    </CheckedText>
                  </CheckedGender>
                </CheckedWrap>
              </div>

              <GroundButton
                onClick={() => {
                  if (data?.name === "") {
                    alert('이름을 입력해주세요.');
                    return;
                  } else if (data?.gender === "") {
                    alert('성별을 선택해주세요.');
                    return;
                  } else if (data?.tel === "") {
                    alert('연락처를 기입해주세요. ');
                    return;
                  } else if (require === false) {
                    alert('약관에 동의주세요')
                    return;
                  }
                  alert('접수가 완료 되었습니다')
                  return;

                }}
              >
                <p>무료상담신청</p>

              </GroundButton>
            </BottomInner>





          </BottomTab>
        </ParentWrap>






        {/* 
        <ParentBottom placeholder="생년월일(예,19920101)" />

        <ParentBottom placeholder="휴대폰(-없이 입력하세요)" style={{ left: 711 }} />
        <ParentBottom placeholder="이름" style={{ left: 331, bottom: 171 }} />
        <CheckedWrap>

          <CheckedInput id="checked" type={'checkbox'} />
          <CheckedText for="checked">
            (필수)개인정보처리동의 [보기]
          </CheckedText>
        </CheckedWrap>
        <CheckedGender>

          <CheckedInput type="radio" style={{ width: 24, height: 24 }} />
          <CheckedTextBig>
            남성
          </CheckedTextBig>
        </CheckedGender>
        <CheckedGender style={{ left: 829 }}>

          <CheckedInput type="radio" style={{ width: 24, height: 24 }} />
          <CheckedTextBig>
            여성
          </CheckedTextBig>
        </CheckedGender>
        <AllRequest>

        </AllRequest> */}

      </StyledPc>


    </>
  );
};



export default Landing;
