import React, { useState } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// 스타일드 컴포넌트를 사용하여 스타일을 정의합니다.

const ChecklistItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: ${(props) => (props.isDragging ? "#e0e0e0" : "white")};
`;
export const FormRowWrapper = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: -11px;

  h2 {
    font-size: 16px;
    padding-bottom: 10px;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    padding-top: 8px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    padding: 5px;
    border: 1px solid #ddd;
  }

  input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .description {
    color: red; /* 빨간색 텍스트 색상 */
  }

  /* 입력 필드 2의 공간을 설명 공간보다 크게 설정 */
  .input-field-2 {
    height: 40px; /* 원하는 높이로 조정하세요 */
  }
`;
const RightContent = styled.div`
  width: 50%;
  overflow-y: scroll;
  max-height: 150px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  & input {
    width: auto !important;
  }
`;
const ChckRow = ({
  formName,
  headerName,
  rightContent,
  placeholder,
  description,
  isError,
  isTop,
}) => {
  const [checklistItems, setChecklistItems] = useState(rightContent);

  const onDragEnd = (result) => {
    if (!result.destination) return; // 항목을 옮긴 위치가 없으면 종료

    const items = [...checklistItems];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setChecklistItems(items);
  };

  return (
    <FormRowWrapper>
      <table>
        <tbody>
          <tr>
          <td
              width={"15%"}
              style={{
                backgroundColor: "#dfe6ed",
                paddingLeft: 16,
                verticalAlign: "middle", // 세로 중앙 정렬
              }}
            >
              {headerName}
            </td>
            <td style={{ padding: 8, paddingLeft: 16 }}>
              <RightContent>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="checklist">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {checklistItems.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <ChecklistItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                isDragging={snapshot.isDragging}
                              >
                                <input
                                  type="checkbox"
                                  checked={item.checked}
                                  onChange={() => {
                                    const updatedItems = [...checklistItems];
                                    updatedItems[index].checked = !item.checked;
                                    setChecklistItems(updatedItems);
                                  }}
                                />
                                {item.content}
                              </ChecklistItem>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </RightContent>
            </td>
          </tr>
          {isError && (
            <tr>
              <td colSpan={2} className="description">
                {description}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </FormRowWrapper>
  );
};

export default ChckRow;
