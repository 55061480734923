import styled from "@emotion/styled";
import React from "react";
import Radio from "@mui/material/Radio"; // Material-UI Radio
import FormControlLabel from "@mui/material/FormControlLabel"; // Material-UI FormControlLabel

const TextRadioFormRowWrapper = styled.div`
  background-color: #ffffff;

  border-radius: 5px;

  padding-top: 0px;
  padding-bottom: 0px;

  h2 {
    font-size: 16px;
    padding-bottom: 10px;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    padding-top: 8px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    padding: 5px;
    border: 1px solid #ddd;
    vertical-align: middle; /* 세로 중앙 정렬 */
  }

  input {
    display: none; /* 입력 필드 숨김 */
  }

  .description {
    color: red; /* 빨간색 텍스트 색상 */
  }

  /* 입력 필드 2의 공간을 설명 공간보다 크게 설정 */
  .input-field-2 {
    height: 40px; /* 원하는 높이로 조정하세요 */
  }
`;

const TextRadioFormRow = ({
  formName,
  headerName,
  text,
  radioLabels,
  descriptions,
  isError,
  isTop,
  onRadioChange,
  selectedValue,
}) => {
  return (
    <TextRadioFormRowWrapper>
      <table>
        <tbody>
          <tr>
            <td
              width={"15%"}
              style={{
                backgroundColor: "#dfe6ed",
                paddingLeft: 16,
                verticalAlign: "middle", // 세로 중앙 정렬
              }}
            >
              {headerName}
            </td>
            <td style={{ padding: 8, paddingLeft: 16 }}>
              {radioLabels.map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Radio
                      checked={selectedValue === item?.text} // 현재 값과 선택된 값 비교
                      onChange={() => onRadioChange(item?.text)} // 라디오 버튼 선택 시 이벤트 핸들러 호출
                    />
                  }
                  label={item?.text}
                />
              ))}
            </td>
          </tr>
          {isError && (
            <tr>
              <td colSpan={2} className="description">
                {descriptions.join(" ")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </TextRadioFormRowWrapper>
  );
};

export default TextRadioFormRow;
