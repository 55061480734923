import axios from "axios";
import { GET, POST } from "../url";


//로그인유지정보 


export const getMeLanding = async () => {
  let res;
  try {
    res = await axios.get(POST.GET_ME);
  } catch {
    res = false;
  }
  return res?.data;
};
