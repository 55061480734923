import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { POST_REQ_API } from "../../Data/POST";
import { GET_SITE_SWR } from "../../Data/SWR";
import { hangjungdong } from "../../Util/Sido";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
const MobileSize = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const ButtonStyled = styled.div`
width: 100%;
cursor: pointer;
background-color: #33a0df;
color: #fff;
font-size: 24px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
:hover {
  opacity:0.5
}
`;
const PcPage = ({
  selectedSido,
  setSelectedSido,
  selectedSigugun,

  setSelectedSigugun,
  selectedDong,
  setSelectedDong,
  site,
}) => {
  const isMobileSize = () => {
    return window.innerWidth < 768;
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const sheetRef = React.useRef(null);

  const [toggleVal, setToggleVal] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const { SiteData, SiteError, SiteMutate } = GET_SITE_SWR({ site: site });
  const [checked, setChecked] = useState(false);
  const [isMobileToggle, setIsMobileToggle] = useState(false)
  const [data, setData] = useState({
    name: "",
    birth: "",

    gender: "M",
    payinsure: "10만원 이상",
    rsrvtdate: "",
    rsrvtround: "오전",
    talktime: "오전",
    requests: "",
    prscnInfrm: {
      title: "",
      content: "",
    },
  });
  const [telData, setTelData] = useState({
    first: "010",
    second: "",
    last: "",
  });
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //보험분석눌럿을떄

  const handleSheetDrag = (event) => {
    if (event === "dragged-down") {
      // 시트를 아래로 내리면 창을 닫음
      setOpen(false);
    }
  };

  const _handleToggle = async () => {
    console.log("토글");


    if (
      selectedSido === "" ||
      selectedSigugun === "" ||
      selectedDong === "" ||
      telData?.first === "" ||
      telData?.second === "" ||
      telData?.last === "" ||
      data?.tel === ""
    ) {
      // 빈 값이 하나라도 있을 경우 알림을 표시하거나 다른 작업 수행
      alert("빈 값이 있습니다. 모든 정보를 입력해주세요.");
      return;
    }
    setIsToggle(!isToggle);
  };
  const _handlePost = async () => {
    if (toggleVal === false) {
      alert("약관을 동의해주세요");
      return;
    }
    const Post_Data = {
      ...data,
      tel: telData?.first + telData?.second + telData?.last,
      zip: {
        sido: selectedSido,
        sigugun: selectedSigugun,
        dong: selectedDong,
      },
    };

    console.log("전송데이터", { body: Post_Data, site: site });
    const res = await POST_REQ_API({ body: Post_Data, site: site });
    if (res === undefined) {
      alert("분석요청이 완료되었습니다\n고객님의 연락처로 연락이 진행될 예정입니다.");
      return;
    }
    alert(
      "분석요청이 완료되었습니다\n고객님의 연락처로 연락이 진행될 예정입니다."
    );
  };

  const _handleToggle_m = async () => {
    console.log("토글");
    if (toggleVal === true) {
      return;
    }

    if (
      selectedSido === "" ||
      selectedSigugun === "" ||
      selectedDong === "" ||
      telData?.first === "" ||
      telData?.second === "" ||
      telData?.last === "" ||
      data?.tel === ""
    ) {
      // 빈 값이 하나라도 있을 경우 알림을 표시하거나 다른 작업 수행
      alert("빈 값이 있습니다. 모든 정보를 입력하세요.");
      return;
    }
    setIsToggle(!isToggle);
    setOpen(true)
  };

  return (
    <>
      <header>
        <div className="wrap ">
          <h1>MSS RESEARCH</h1>
          <p>
            컨설팅 기관의 전문위원을 통한
            <br />
            보험 전문 컨설팅 서비스 제공
          </p>
          <div>
            <button

              onClick={(e) => {
                /*   if (toggleVal === true) {
                    return;
                  }
  
                  if (
                    selectedSido === "" ||
                    selectedSigugun === "" ||
                    selectedDong === "" ||
                    telData?.first === "" ||
                    telData?.second === "" ||
                    telData?.last === "" ||
                    data?.tel === ""
                  ) {
                    // 빈 값이 하나라도 있을 경우 알림을 표시하거나 다른 작업 수행
                    alert("빈 값이 있습니다. 모든 정보를 입력하세요.");
                    return;
                  } */
                _handleToggle();
              }}

              className="PCBtn"
              style={{ backgroundColor: "#775DE4", color: "#fff" }}
            >
              보험 분석받기
            </button>
            <button
              onClick={() => {
                setIsMobileToggle(true)

              }}
              className="MobileBtn"
              style={{ backgroundColor: "#775DE4", color: "#fff" }}
            >
              보험 분석받기
            </button>
            {/*  <button className="kakao-login kakao-modal-btn">
              보험 분석받기
            </button> */}
            {/*     <button className="naver-login">
              네이버 로그인으로 보험분석받기
            </button> */}
          </div>
        </div>
      </header>
      <main class="PCMAin">
        <form action="">
          <div className="wrap">
            <section id="st02" className="border-bottom">
              <div className="d-flex titblock">
                <div>
                  <label htmlFor="name">이름을 입력해주세요</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="이름을 입력해주세요"
                    value={data?.name}
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="birth">생년월일을 입력해주세요</label>
                  <input
                    type="date"
                    name="birth"
                    id="birth"
                    placeholder="예) 20000101"
                    maxLength={8}
                    value={data?.birth}
                    onChange={(e) => {
                      setData({ ...data, birth: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div>
                <span>휴대폰번호를 입력해주세요</span>
                <div className="d-flex flex-phone">
                  <label htmlFor="phone1" className="d-none" />
                  <input
                    type="number"
                    name="phone1"
                    id="phone1"
                    placeholder={"010"}
                    maxLength={3}
                    defaultValue={"010"}
                    value={telData?.first}
                    onChange={(e) => {
                      setTelData({ ...telData, first: e.target.value });
                    }}
                  />
                  <span>-</span>
                  <label htmlFor="phone2" className="d-none" />
                  <input
                    type="number"
                    name="phone2"
                    id="phone2"
                    maxLength={4}
                    defaultValue=""
                    value={telData?.second}
                    onChange={(e) => {
                      setTelData({ ...telData, second: e.target.value });
                    }}
                  />
                  <span>-</span>
                  <label htmlFor="phone3" className="d-none" />
                  <input
                    type="number"
                    name="phone3"
                    id="phone3"
                    maxLength={4}
                    defaultValue=""
                    value={telData?.last}
                    onChange={(e) => {
                      setTelData({ ...telData, last: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div>
                <span>거주지를 입력해주세요</span>
                <div className="d-flex flex-address">
                  <select
                    id="sido"
                    value={selectedSido}
                    onChange={(e) => setSelectedSido(e.target.value)}
                  >
                    <option value="">선택</option>
                    {hangjungdong?.sido?.map((item, idx) => {
                      return <option value={item?.sido}>{item?.codeNm}</option>;
                    })}
                  </select>
                  <select
                    id="sigugun"
                    value={selectedSigugun}
                    onChange={(e) => setSelectedSigugun(e.target.value)}
                  >
                    <option value="">선택</option>
                    {hangjungdong?.sigugun
                      ?.filter((item, idx) => {
                        return item?.sido === selectedSido;
                      })
                      .map((item, idx) => {
                        return (
                          <option value={item?.sigugun}>{item?.codeNm}</option>
                        );
                      })}
                    {/* 여기에 sigugun 옵션을 동적으로 생성하는 코드 추가 */}
                  </select>
                  <select
                    id="dong"
                    value={selectedDong}
                    onChange={(e) => setSelectedDong(e.target.value)}
                  >
                    <option value="">선택</option>
                    {hangjungdong?.dong
                      ?.filter((item, idx) => {
                        return item?.sigugun === selectedSigugun;
                      })
                      .map((item, idx) => {
                        return (
                          <option value={item?.dong}>{item?.codeNm}</option>
                        );
                      })}
                    {/* 여기에 dong 옵션을 동적으로 생성하는 코드 추가 */}
                  </select>
                </div>
              </div>
              {/* <div>
                <span>성별을 선택해주세요</span>
                <div className="d-flex">
                  <input
                    type="radio"
                    name="gender"
                    id="male"
                    className="d-none"
                    defaultChecked=""
                    style={{ display: "none" }}
                    onClick={() => {
                      setData({ ...data, gender: "M" });
                    }}
                    checked={data?.gender === "M"}
                  />
                  <label htmlFor="male">남자</label>
                  <input
                    type="radio"
                    name="gender"
                    style={{ display: "none" }}
                    id="female"
                    className="d-none"
                    onClick={() => {
                      setData({ ...data, gender: "W" });
                    }}
                    checked={data?.gender === "W"}
                  />
                  <label htmlFor="female">여자</label>
                </div>
              </div> */}
            </section>
            {/*       <section id="st03" className="border-bottom">
              <div className="d-flex">
                <div>
                  <span>납입보험료를 선택해주세요</span>
                  <select
                    value={data?.payinsure}
                    onChange={(e) => {
                      setData({ ...data, payinsure: e.target.value });
                    }}
                    name="fee"
                    id="fee"
                  >
                    <option value="">납입보험료</option>
                    <option value="10만원 미만">10만원 미만</option>
                    <option value="10만원 이상">10만원 이상</option>
                    <option value="20만원 이상">20만원 이상</option>
                    <option value="30만원 이상">30만원 이상</option>
                    <option value="40만원 이상">40만원 이상</option>
                    <option value="50만원 초과">50만원 초과</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="date">방문예약일을 선택해주세요</label>
                  <input
                    value={data?.rsrvtdate}
                    onChange={(e) => {
                      setData({ ...data, rsrvtdate: e.target.value });
                    }}
                    type="date"
                    name="date"
                    id="date"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <span>방문예약시간을 선택해주세요</span>
                  <select
                    value={data?.rsrvtround}
                    onChange={(e) => {
                      setData({ ...data, rsrvtround: e.target.value });
                    }}
                    name="time"
                    id="time"
                  >
                    <option value="">방문예약시간</option>
                    <option value="오전">오전</option>
                    <option value="오후">오후</option>
                  </select>
                </div>
                <div>
                  <span>통화가능시간을 선택해주세요</span>
                  <select
                    value={data?.talktime}
                    onChange={(e) => {
                      setData({ ...data, talktime: e.target.value });
                    }}
                    name="possible"
                    id="possible"
                  >
                    <option value="">통화가능시간</option>
                    <option value="오전">오전</option>
                    <option value="오후">오후</option>
                    <option value="상시">상시</option>
                  </select>
                </div>
              </div>
              <div>
                <label htmlFor="text">요청사항을 입력해주세요</label>
                <textarea
                  name=""
                  value={data?.requests}
                  onChange={(e) => {
                    setData({ ...data, requests: e.target.value });
                  }}
                  id="text"
                  placeholder="요청사항을 입력해주세요"
                  defaultValue={""}
                />
              </div>
            </section> */}
            <section id="st04">
              <div className="d-flex">
                <a
                  href="#"
                  rel="개인정보 수집 및 제공 동의 살펴보기"
                  className="agreement-modal-btn"
                >
                  개인정보 수집 및 제공 동의
                </a>
                <input
                  type="checkbox"
                  name="agree"
                  id="agree"
                  className="d-none"
                  style={{ display: "none" }}
                  checked={checked}
                  onChange={(e) => {
                    setChecked(!checked)
                  }}
                />
                <label htmlFor="agree" />
              </div>
            </section>
          </div>
        </form>
      </main>
      <footer>
        <div className="wrap d-flex">
          <div className="left-box">
            <div className="logo-box">
              <h2>
                {/*         <img src="./images/logo_goodrich.svg" alt="" /> */}
              </h2>
              <h2>
                {/*  <img src="./images/logo_aia.svg" alt="" /> */}
              </h2>
            </div>
            <span>Copyright 2023. MMS RESEARCH all rights reserved.</span>
          </div>
          <div className="right-box">
            <ul className="d-flex">
              <li>
                <a href="#" className="personal-data-btn">
                  개인정보처리방침
                </a>
              </li>
              <li>
                <a href="#" className="personal-data-btn">
                  이용약관
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      {/* <div>
        <a href="#" className="modal-btn">
          <span />
          <span />
        </a>
        <div className="modal-wrap">
          <div>
            <h2>개인정보 수집 및 제공동의</h2>
            <p>
              회사는 보험 상담 및 보험 서비스 제공 등의 업무처리를 위하여 귀하의
              개인정보를 수집 이용하고자 하는 경우에는 개인정보보호법 제 15조 및
              제 22 조에 따라 본인의 동의를 얻어야 하며, 수집한 개인정보를 제
              3자에 제공할 경우 개인정보보호법 제 17조 및 제 22 조에 따라 귀하의
              사전 동의를 얻어야 합니다.
            </p>
          </div>
          <div>
            <h3>개인정보 수집 및 이용에 관한 동의 (필수)</h3>
            <dl>
              <dt>1. 수집 이용항목</dt>
              <dd>
                <ul>
                  <li>
                    이름, 생년월일, 휴대전화번호, 지역명(시/군), 쿠키에 의한
                    자동수집 정보(이용자의 브라우저 종류 및 OS, 방문 기록(IP,
                    접속시간)
                  </li>
                </ul>
              </dd>
              <dt>2. 수집 이용목적</dt>
              <dd>
                <ul>
                  <li>
                    보험상담, 보험 리모델링 및 가입 권유를 위한 안내 및
                    서비스(이름, 생년월일, 휴대전화번호)
                  </li>
                  <li>
                    상담자 인근 설계사 또는 위탁 계약 체결자 연계[지역명(시/군)]
                  </li>
                  <li>
                    맞춤 서비스 제공, 서비스 개선 용도 활용 [쿠키에 의한
                    자동수집 정보(이용자의 브라우저 종류 및 OS, 방문 기록(IP,
                    접속시간)]
                  </li>
                </ul>
              </dd>
              <dt>3. 보유 이용기간</dt>
              <dd>
                <ul>
                  <li>동의일로부터 1년</li>
                </ul>
              </dd>
            </dl>
            <span>
              귀하는 개인정보 수집 이용에 대한 동의를 거부할 권리가 있습니다.
              <br />
              단, 동의 거부 시 보험계약 상담 서비스를 받으실 수 없습니다.
            </span>
          </div>
          <div>
            <h3>개인정보 제공에 관한 동의(필수)</h3>
            <dl>
              <dt>1. 제공 받는 자</dt>
              <dd>
                <ul>
                  <li>
                    당사 소속 설계사, 당사의 모집 위탁 계약을 체결한 자(대리점,
                    설계사)
                  </li>
                </ul>
              </dd>
              <dt>2. 제공항목</dt>
              <dd>
                <ul>
                  <li>이름, 생년월일, 휴대전화번호, 지역명(시/군)</li>
                </ul>
              </dd>
              <dt>3. 제공목적</dt>
              <dd>
                <ul>
                  <li>
                    보험상담, 보험 리모델링 및 가입 권유를 위한 안내 및
                    서비스(이름, 생년월일, 휴대전화번호)
                  </li>
                  <li>
                    상담자 인근 설계사 또는 위탁 계약 체결자 연계[지역명(시/군)]
                  </li>
                </ul>
              </dd>
              <dt>4. 보유이용기간</dt>
              <dd>동의일로부터 1년</dd>
            </dl>
            <span>
              귀하는 개인정보 수집 이용에 대한 동의를 거부할 권리가 있습니다.
              <br />
              단, 동의 거부 시 보험계약 상담 서비스를 받으실 수 없습니다.
            </span>
          </div>
        </div>
      </div> */}

      <div
        className="modal modal-personal-data d-none"
        style={{ display: isMobileToggle ? "block" : "none" }} //toggleSEt 조건도 추가
      >
        <a onClick={(e) => {
          e.preventDefault();
          setIsMobileToggle(false)
          setTimeout(() => {
            alert('약관에 동의하셔야만 보험분석이 가능합니다.')
          }, 100)
        }} className="modal-btn">
          <span />
          <span />
        </a>
        <div className="modal-wrap">
          <div>
            <ButtonStyled


              onClick={() => {
 
                if (checked === false) {
                  alert('약관 동의가 되어있지않습니다')
                  return;
                }
                setIsMobileToggle(false)
                setToggleVal(true)
                setOpen(true)

              }}
            >
              개인정보 수집 및 제공 동의
            </ButtonStyled>

            <h2>개인정보처리방침</h2>
            <p>
              &lt; mss &gt;('https://mss리서치.com/' 이하 'mss리서치')은(는)
              「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고
              이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여
              다음과 같이 개인정보 처리방침을 수립·공개합니다.
            </p>
            <span>이 개인정보처리방침은 2023년 9월 1부터 적용됩니다.</span>
          </div>
          <div>
            <h3>
              제1조
              <br />
              개인정보의 처리 목적
            </h3>
            <p>
              &lt; mss &gt;('https:mss리서치.com/'이하 'mss리서치')은(는) 다음의
              목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는
              다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는
              경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등
              필요한 조치를 이행할 예정입니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>수집자</dt>
                <dd>
                  <ul>
                    <li>
                      엠예스예스 주식회사
                    </li>
                  </ul>
                </dd>
                <dt>수집·이용 항목</dt>
                <dd>
                  <ul>
                    <li>
                      이름, 생년월일, 성별, 휴대전화번호, 지역, 기타문의
                    </li>
                  </ul>
                </dd>
                <dt>3.수집·이용 목적</dt>
                <dd>
                  <ul>
                    <li>
                      보험상담, 보험 리모델링 및 가입 권유를 위한 안내 및 서비스 (이름, 생년월일, 휴대전화번호) -상담자 인근 설계사 또는 위탁 계약 체결자 연계 [지역명(시/군)] -맞춤 서비스 제공
                    </li>
                  </ul>
                </dd>
                <dt>4.보유·이용기간</dt>
                <dd>
                  <ul>
                    <li>
                      동의일로부터 1년
                      귀하는 개인정보 수집·이용에 대한 동의를 거부할 권리가 있습니다. 단, 동의 거부 시 보험계약 상담 서비스를 받으실 수 없습니다.

                    </li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제2조
              <br />
              개인정보의 처리 및 보유 기간
            </h3>
            <p>
              ① &lt; mss &gt;은(는) 법령에 따른 개인정보 보유·이용기간 또는
              정보주체로부터 개인정보를 수집 시에 동의받은 개인정보
              보유·이용기간 내에서 개인정보를 처리·보유합니다.
              <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1.제공 받는 자</dt>
                <dd>
                  당사의 모집 위탁 계약을 체결한자 (대리점, 설계사)
                  보험사: AIA PP, 굿리치
                </dd>
              </dl>
              <dl>
                <dt>2.제공 항목</dt>
                <dd>
                  이름, 생년월일, 성별, 휴대전화번호, 지역명(시/군), 기타문의
                </dd>
              </dl>
              <dl>
                <dt>3.제공목적</dt>
                <dd>
                  보험상담, 보험 리모델링 및 가입 권유를 위한 안내 및 서비스 (이름, 생년월일, 휴대전화번호) -상담자 인근 설계사 또는 위탁 계약 체결자 연계 [지역명(시/군)]
                </dd>
              </dl>
              <dl>
                <dt>4.보유·이용기간</dt>
                <dd>
                  -동의일로부터 1년
                  귀하는 개인정보 제공에 대한 동의를 거부할 권리가 있습니다. 단, 동의 거부 시 보험계약 상담 서비스를 받으실 수 없습니다

                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제3조
              <br />
              처리하는 개인정보의 항목
            </h3>
            <p>
              ① &lt; mss &gt;은(는) 다음의 개인정보 항목을 처리하고 있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1. &lt; 마케팅 및 광고에의 활용 &gt;</dt>
                <dd>
                  와 관련한 개인정보는 수집.이용에 관한 동의일로부터까지 위
                  이용목적을 위하여 보유.이용됩니다.
                  <br />
                  <ul>
                    <li>
                      필수항목 : 이름, 생년월일, 성별, 자택전화번호, 자택주소,
                      휴대전화번호, 이메일
                    </li>
                    <li>선택항목 :</li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제4조
              <br />
              개인정보의 제3자 제공에 관한 사항
            </h3>
            <p>
              ① &lt; mss &gt;은(는) 개인정보를 제1조(개인정보의 처리 목적)에서
              명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정
              등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만
              개인정보를 제3자에게 제공합니다.
              <br />② &lt; mss &gt;은(는) 다음과 같이 개인정보를 제3자에게
              제공하고 있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1. &lt; MSS &gt;</dt>
                <dd>
                  와 관련한 개인정보는 수집.이용에 관한 동의일로부터까지 위
                  이용목적을 위하여 보유.이용됩니다.
                  <br />
                  <ul>
                    <li>개인정보를 제공받는 자 : MSS</li>
                    <li>
                      제공받는 자의 개인정보 이용목적 : 이름, 생년월일, 성별,
                      자택주소, 휴대전화번호
                    </li>
                    <li>제공받는 자의 보유.이용기간: 1년</li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제5조
              <br />
              개인정보처리의 위탁에 관한 사항
            </h3>
            <p>
              ① &lt; mss &gt;은(는) 원활한 개인정보 업무처리를 위하여 다음과
              같이 개인정보 처리업무를 위탁하고 있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1. &lt; &gt;</dt>
                <dd>
                  <br />
                  <ul>
                    <li>위탁받는 자 (수탁자) :</li>
                    <li>위탁하는 업무의 내용 :</li>
                    <li>위탁기간 :</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <p>
              ② &lt; mss &gt;은(는) 위탁계약 체결시 「개인정보 보호법」 제26조에
              따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적
              보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에
              관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를
              안전하게 처리하는지를 감독하고 있습니다.
              <br />③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본
              개인정보 처리방침을 통하여 공개하도록 하겠습니다.
            </p>
          </div>
          <div>
            <h3>
              제6조
              <br />
              개인정보의 파기절차 및 파기방법
            </h3>
            <p>
              ① &lt; mss &gt; 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등
              개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
              파기합니다.
              <br />② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나
              처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
              계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
              데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>
                  1. 법령 근거 :
                  <br />
                </dt>
                <dt>2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜</dt>
              </dl>
            </div>
            <p>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1. 파기절차</dt>
                <dd>
                  &lt; mss &gt; 은(는) 파기 사유가 발생한 개인정보를 선정하고,
                  &lt; mss &gt; 의 개인정보 보호책임자의 승인을 받아 개인정보를
                  파기합니다.
                </dd>
                <dt>2. 파기방법</dt>
                <dd>
                  <ul>
                    <li>
                      종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                      파기합니다.
                    </li>
                    <li>
                      전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적
                      방법을 사용합니다
                    </li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제7조
              <br />
              정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항
            </h3>
            <p>
              ① 정보주체는 mss에 대해 언제든지 개인정보 열람·정정·삭제·처리정지
              요구 등의 권리를 행사할 수 있습니다.
              <br />
              ② 제1항에 따른 권리 행사는mss에 대해 「개인정보 보호법」 시행령
              제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실
              수 있으며 mss은(는) 이에 대해 지체 없이 조치하겠습니다.
              <br />
              ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은
              자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리
              방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을
              제출하셔야 합니다.
              <br />
              ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조
              제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수
              있습니다.
              <br />
              ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
              대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
              <br />⑥ mss은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의
              요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
              대리인인지를 확인합니다.
            </p>
          </div>
          <div>
            <h3>
              제8조
              <br />
              개인정보의 안전성 확보조치에 관한 사항
            </h3>
            <p>
              &lt; mss &gt;은(는) 개인정보의 안전성 확보를 위해 다음과 같은
              조치를 취하고 있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1. 내부관리계획의 수립 및 시행</dt>
                <dd>
                  개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고
                  시행하고 있습니다.
                </dd>
                <dt>2. 개인정보에 대한 접근 제한</dt>
                <dd>
                  개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
                  부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여
                  필요한 조치를 하고 있으며 침입차단시스템을 이용하여
                  외부로부터의 무단 접근을 통제하고 있습니다.
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제9조
              <br />
              개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한
              사항
            </h3>
            <p>
              mss 은(는) 정보주체의 이용정보를 저장하고 수시로 불러오는
              ‘쿠키(cookie)’를 사용하지 않습니다.
            </p>
          </div>
          <div>
            <h3>
              제10조
              <br />
              행태정보의 수집·이용·제공 및 거부 등에 관한 사항
            </h3>
            <p>
              행태정보의 수집·이용·제공 및 거부등에 관한 사항
              <br />
              은(는) 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지
              않습니다.
            </p>
          </div>
          <div>
            <h3>
              제11조
              <br />
              추가적인 이용·제공 판단기준
            </h3>
            <p>
              &lt; mss &gt; 은(는) ｢개인정보 보호법｣ 제15조제3항 및
              제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른
              사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로
              이용·제공할 수 있습니다. 이에 따라 &lt; mss &gt; 가(이) 정보주체의
              동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을
              고려하였습니다.
            </p>
            <div className="small-box bg-gray">
              <ul className="arrow">
                <li>
                  개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과
                  관련성이 있는지 여부
                </li>
                <li>
                  개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인
                  이용·제공에 대한 예측 가능성이 있는지 여부
                </li>
                <li>
                  개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게
                  침해하는지 여부
                </li>
                <li>
                  가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지
                  여부
                </li>
              </ul>
              <span>
                추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체
                스스로 자율적으로 판단하여 작성·공개함
              </span>
            </div>
          </div>
          <div>
            <h3>
              제12조
              <br />
              가명정보를 처리하는 경우 가명정보 처리에 관한 사항
            </h3>
            <p>
              &lt; mss &gt; 은(는) 다음과 같은 목적으로 가명정보를 처리하고
              있습니다.
            </p>
            <div className="small-box bg-gray">
              <ul className="arrow">
                <li>
                  가명정보의 처리 목적
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
                <li>
                  가명정보의 처리 및 보유기간
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
                <li>
                  가명정보의 제3자 제공에 관한 사항(해당되는 경우에만 작성)
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
                <li>
                  가명정보 처리의 위탁에 관한 사항(해당되는 경우에만 작성)
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
                <li>
                  가명처리하는 개인정보의 항목
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
                <li>
                  법 제28조의4(가명정보에 대한 안전조치 의무 등)에 따른
                  가명정보의 안전성 확보조치에 관한 사항
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3>
              제13조
              <br />
              개인정보 보호책임자에 관한 사항
            </h3>
            <p>
              ① mss 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고,
              개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
              아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            </p>
            <div className="small-box bg-gray">
              <ul className="arrow">
                <li>
                  개인정보 보호책임자
                  <ul>
                    <li>성명 :문성석</li>
                    <li>직책 :대표</li>
                    <li>직급 :대표</li>
                    <li>
                      연락처 :070-4725-0204, Mss740204@naver.com,
                      <br />
                      <span>개인정보 보호 담당부서로 연결됩니다.</span>
                    </li>
                  </ul>
                </li>
                <li>
                  개인정보 보호 담당부서
                  <ul>
                    <li>부서명 :</li>
                    <li>담당자 :</li>
                    <li>연락처 :, ,</li>
                  </ul>
                </li>
              </ul>
            </div>
            <p>
              ② 정보주체께서는 mss 의 서비스(또는 사업)을 이용하시면서 발생한
              모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
              개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. mss 은(는)
              정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
            </p>
          </div>
          <div>
            <h3>
              제14조
              <br />
              국내대리인의 지정
            </h3>
            <p>
              정보주체는 ｢개인정보 보호법｣ 제39조의11에 따라 지정된 &lt; mss
              &gt;의 국내대리인에게 개인정보 관련 고충처리 등의 업무를 위하여
              연락을 취할 수 있습니다. &lt; mss &gt;은(는) 정보주체의 개인정보
              관련 고충처리 등 개인정보 보호책임자의 업무 등을 신속하게 처리할
              수 있도록 노력하겠습니다.
            </p>
            <div className="small-box bg-gray">
              <ul className="arrow">
                <li>
                  &lt; mss &gt; 은(는) ｢개인정보 보호법｣ 제39조의11에 따라
                  국내대리인을 지정하였습니다.
                  <ul>
                    <li>
                      국내대리인의 성명 : [대리인 성명_직접입력] (법인의 경우
                      법인명, 대표자의 성명)
                    </li>
                    <li>
                      국내대리인의 주소 : [대리인 주소_직접입력] (법인의 경우
                      영업소 소재지)
                    </li>
                    <li>국내대리인의 전화번호 : [대리인 전화번호_직접입력]</li>
                    <li>
                      국내대리인의 전자우편 주소 : [대리인 전자우편_직접입력]
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3>
              제15조
              <br />
              개인정보의 열람청구를 접수·처리하는 부서
            </h3>
            <p>
              정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를
              아래의 부서에 할 수 있습니다.
              <br />
              &lt; mss &gt;은(는) 정보주체의 개인정보 열람청구가 신속하게
              처리되도록 노력하겠습니다.
            </p>
            <div className="small-box bg-gray">
              <ul className="arrow">
                <li>
                  개인정보 열람청구 접수·처리 부서
                  <ul>
                    <li>부서명 : 경영</li>
                    <li>담당자 : 문성석</li>
                    <li>연락처 : 07047250204, Mss740204@naver.com,</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3>
              제16조
              <br />
              정보주체의 권익침해에 대한 구제방법
            </h3>
            <p>
              정보주체는 개인정보침해로 인한 구제를 받기 위하여
              개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
              분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
              개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
              바랍니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt />
                <dd>
                  <ul>
                    <li>
                      개인정보분쟁조정위원회 : (국번없이) 1833-6972
                      (www.kopico.go.kr)
                    </li>
                    <li>
                      개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
                    </li>
                    <li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
                    <li>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <p>
              「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의
              정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대
              하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
              이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
              청구할 수 있습니다.
              <span>
                행정심판에 대해 자세한 사항은
                중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기
                바랍니다.
              </span>
            </p>
          </div>
          <div>
            <h3>
              제17조
              <br />
              영상정보처리기기 운영·관리에 관한 사항
            </h3>
            <p>
              ① &lt; mss &gt;은(는) 아래와 같이 영상정보처리기기를 설치·운영하고
              있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1.영상정보처리기기 설치근거·목적 : &lt; mss &gt;의</dt>
                <dt>2.설치 대수, 설치 위치, 촬영 범위 :</dt>
                <dd>
                  <ul>
                    <li>설치대수 : 대</li>
                    <li>설치위치 :</li>
                    <li>촬영범위 :</li>
                  </ul>
                </dd>
                <dt>3.관리책임자, 담당부서 및 영상정보에 대한 접근권한자 :</dt>
                <dt>4.영상정보 촬영시간, 보관기간, 보관장소, 처리방법</dt>
                <dd>
                  <ul>
                    <li>촬영시간 : 시간</li>
                    <li>보관기간 : 촬영시부터</li>
                    <li>보관장소 및 처리방법 :</li>
                  </ul>
                </dd>
                <dt>5.영상정보 확인 방법 및 장소 :</dt>
                <dt>6.정보주체의 영상정보 열람등요구에 대한 조치 :</dt>
                <dd>
                  개인영상정보 열람.존재확인 청구서로 신청하여야 하며, 정보주체
                  자신이 촬영된 경우 또는 명백히 정보주체의 생명.신체.재산
                  이익을 위해 필요한 경우에 한해 열람을 허용함
                </dd>
                <dt>7.영상정보처리기기 설치근거·목적 :</dt>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제18조
              <br />
              개인정보 처리방침 변경
            </h3>
            <p>
              ① 이 개인정보처리방침은 2023년 9월 1부터 적용됩니다.
              <br />② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dd>
                  <ul>
                    <li>예시 ) - 20XX. X. X ~ 20XX. X. X 적용</li>
                    <li>예시 ) - 20XX. X. X ~ 20XX. X. X 적용</li>
                    <li>예시 ) - 20XX. X. X ~ 20XX. X. X 적용</li>
                  </ul>
                </dd>
              </dl>

            </div>



          </div>
        </div>

      </div>



      <div
        className="modal modal-personal-data d-none"
        style={{ display: isToggle ? "block" : "none" }} //toggleSEt 조건도 추가
      >
        <a href="#" className="modal-btn">
          <span />
          <span />
        </a>
        <div className="modal-wrap">
          <div>
            <ButtonStyled


              onClick={() => {

                setIsToggle(false);
                setToggleVal(true)
                alert('접수가 완료 되었습니다.\n 빠른 시일 내에 상담 드리겠습니다.')
              }}
            >
              개인정보 수집 및 제공 동의
            </ButtonStyled>
            <h2>개인정보처리방침</h2>
            <p>
              &lt; mss &gt;('https://mss리서치.com/' 이하 'mss리서치')은(는)
              「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고
              이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여
              다음과 같이 개인정보 처리방침을 수립·공개합니다.
            </p>
            <span>이 개인정보처리방침은 2023년 9월 1부터 적용됩니다.</span>
          </div>
          <div>
            <h3>
              제1조
              <br />
              개인정보의 처리 목적
            </h3>
            <p>
              &lt; mss &gt;('https:mss리서치.com/'이하 'mss리서치')은(는) 다음의
              목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는
              다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는
              경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등
              필요한 조치를 이행할 예정입니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1. 수집자</dt>
                <dd>
                  <ul>
                    <li>
                      엠예스예스 주식회사
                    </li>
                  </ul>
                </dd>
                <dt>2. 수집·이용 항목</dt>
                <dd>
                  <ul>
                    <li>
                      이름, 생년월일, 성별, 휴대전화번호, 지역, 기타문의
                    </li>
                  </ul>
                </dd>
                <dt>3.수집·이용 목적공</dt>
                <dd>
                  <ul>
                    <li>
                      보험상담, 보험 리모델링 및 가입 권유를 위한 안내 및 서비스 (이름, 생년월일, 휴대전화번호) -상담자 인근 설계사 또는 위탁 계약 체결자 연계 [지역명(시/군)] -맞춤 서비스 제공
                    </li>
                  </ul>
                </dd>
                <dt>4. 보유·이용기간</dt>
                <dd>
                  <ul>
                    <li>
                      동의일로부터 1년
                    </li>
                    <li>
                      귀하는 개인정보 수집·이용에 대한 동의를 거부할 권리가 있습니다. 단, 동의 거부 시 보험계약 상담 서비스를 받으실 수 없습니다.
                    </li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제2조
              <br />
              개인정보의 처리 및 보유 기간
            </h3>
            <p>
              ① &lt; mss &gt;은(는) 법령에 따른 개인정보 보유·이용기간 또는
              정보주체로부터 개인정보를 수집 시에 동의받은 개인정보
              보유·이용기간 내에서 개인정보를 처리·보유합니다.
              <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1.제공 받는 자</dt>
                <dd>
                  와 관련한 개인정보는 수집.이용에 관한 동의일로부터까지 위
                  이용목적을 위하여 보유.이용됩니다.
                  <br />
                  <ul>
                    <li>당사의 모집 위탁 계약을 체결한자 (대리점, 설계사)</li>
                    <li>보험사:  굿리치</li>
                    <li>예외사유 :</li>
                  </ul>
                </dd>
              </dl>
              <dl>
                <dt>2.제공항목</dt>
                <dd>
                  -이름, 생년월일, 성별, 휴대전화번호, 지역명(시/군), 기타문의
                  <br />

                </dd>
              </dl>
              <dl>
                <dt>3.제공목적</dt>
                <dd>
                  보험상담, 보험 리모델링 및 가입 권유를 위한 안내 및 서비스 (이름, 생년월일, 휴대전화번호) -상담자 인근 설계사 또는 위탁 계약 체결자 연계 [지역명(시/군)]
                  <br />

                </dd>
              </dl> <dl>
                <dt>4.보유·이용기간</dt>
                <dd>
                  동의일로부터 1년
                  <br />
                  귀하는 개인정보 제공에 대한 동의를 거부할 권리가 있습니다. 단, 동의 거부 시 보험계약 상담 서비스를 받으실 수 없습니다.
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제3조
              <br />
              처리하는 개인정보의 항목
            </h3>
            <p>
              ① &lt; mss &gt;은(는) 다음의 개인정보 항목을 처리하고 있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1. &lt; 마케팅 및 광고에의 활용 &gt;</dt>
                <dd>
                  와 관련한 개인정보는 수집.이용에 관한 동의일로부터까지 위
                  이용목적을 위하여 보유.이용됩니다.
                  <br />
                  <ul>
                    <li>
                      필수항목 : 이름, 생년월일, 성별, 자택전화번호, 자택주소,
                      휴대전화번호, 이메일
                    </li>
                    <li>선택항목 :</li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제4조
              <br />
              개인정보의 제3자 제공에 관한 사항
            </h3>
            <p>
              ① &lt; mss &gt;은(는) 개인정보를 제1조(개인정보의 처리 목적)에서
              명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정
              등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만
              개인정보를 제3자에게 제공합니다.
              <br />② &lt; mss &gt;은(는) 다음과 같이 개인정보를 제3자에게
              제공하고 있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1. &lt; MSS &gt;</dt>
                <dd>
                  와 관련한 개인정보는 수집.이용에 관한 동의일로부터까지 위
                  이용목적을 위하여 보유.이용됩니다.
                  <br />
                  <ul>
                    <li>개인정보를 제공받는 자 : MSS</li>
                    <li>
                      제공받는 자의 개인정보 이용목적 : 이름, 생년월일, 성별,
                      자택주소, 휴대전화번호
                    </li>
                    <li>제공받는 자의 보유.이용기간: 1년</li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제5조
              <br />
              개인정보처리의 위탁에 관한 사항
            </h3>
            <p>
              ① &lt; mss &gt;은(는) 원활한 개인정보 업무처리를 위하여 다음과
              같이 개인정보 처리업무를 위탁하고 있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1. &lt; &gt;</dt>
                <dd>
                  <br />
                  <ul>
                    <li>위탁받는 자 (수탁자) :</li>
                    <li>위탁하는 업무의 내용 :</li>
                    <li>위탁기간 :</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <p>
              ② &lt; mss &gt;은(는) 위탁계약 체결시 「개인정보 보호법」 제26조에
              따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적
              보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에
              관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를
              안전하게 처리하는지를 감독하고 있습니다.
              <br />③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본
              개인정보 처리방침을 통하여 공개하도록 하겠습니다.
            </p>
          </div>
          <div>
            <h3>
              제6조
              <br />
              개인정보의 파기절차 및 파기방법
            </h3>
            <p>
              ① &lt; mss &gt; 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등
              개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
              파기합니다.
              <br />② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나
              처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
              계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
              데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>
                  1. 법령 근거 :
                  <br />
                </dt>
                <dt>2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜</dt>
              </dl>
            </div>
            <p>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1. 파기절차</dt>
                <dd>
                  &lt; mss &gt; 은(는) 파기 사유가 발생한 개인정보를 선정하고,
                  &lt; mss &gt; 의 개인정보 보호책임자의 승인을 받아 개인정보를
                  파기합니다.
                </dd>
                <dt>2. 파기방법</dt>
                <dd>
                  <ul>
                    <li>
                      종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                      파기합니다.
                    </li>
                    <li>
                      전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적
                      방법을 사용합니다
                    </li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제7조
              <br />
              정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항
            </h3>
            <p>
              ① 정보주체는 mss에 대해 언제든지 개인정보 열람·정정·삭제·처리정지
              요구 등의 권리를 행사할 수 있습니다.
              <br />
              ② 제1항에 따른 권리 행사는mss에 대해 「개인정보 보호법」 시행령
              제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실
              수 있으며 mss은(는) 이에 대해 지체 없이 조치하겠습니다.
              <br />
              ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은
              자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리
              방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을
              제출하셔야 합니다.
              <br />
              ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조
              제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수
              있습니다.
              <br />
              ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
              대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
              <br />⑥ mss은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의
              요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
              대리인인지를 확인합니다.
            </p>
          </div>
          <div>
            <h3>
              제8조
              <br />
              개인정보의 안전성 확보조치에 관한 사항
            </h3>
            <p>
              &lt; mss &gt;은(는) 개인정보의 안전성 확보를 위해 다음과 같은
              조치를 취하고 있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1. 내부관리계획의 수립 및 시행</dt>
                <dd>
                  개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고
                  시행하고 있습니다.
                </dd>
                <dt>2. 개인정보에 대한 접근 제한</dt>
                <dd>
                  개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
                  부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여
                  필요한 조치를 하고 있으며 침입차단시스템을 이용하여
                  외부로부터의 무단 접근을 통제하고 있습니다.
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제9조
              <br />
              개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한
              사항
            </h3>
            <p>
              mss 은(는) 정보주체의 이용정보를 저장하고 수시로 불러오는
              ‘쿠키(cookie)’를 사용하지 않습니다.
            </p>
          </div>
          <div>
            <h3>
              제10조
              <br />
              행태정보의 수집·이용·제공 및 거부 등에 관한 사항
            </h3>
            <p>
              행태정보의 수집·이용·제공 및 거부등에 관한 사항
              <br />
              은(는) 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지
              않습니다.
            </p>
          </div>
          <div>
            <h3>
              제11조
              <br />
              추가적인 이용·제공 판단기준
            </h3>
            <p>
              &lt; mss &gt; 은(는) ｢개인정보 보호법｣ 제15조제3항 및
              제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른
              사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로
              이용·제공할 수 있습니다. 이에 따라 &lt; mss &gt; 가(이) 정보주체의
              동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을
              고려하였습니다.
            </p>
            <div className="small-box bg-gray">
              <ul className="arrow">
                <li>
                  개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과
                  관련성이 있는지 여부
                </li>
                <li>
                  개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인
                  이용·제공에 대한 예측 가능성이 있는지 여부
                </li>
                <li>
                  개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게
                  침해하는지 여부
                </li>
                <li>
                  가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지
                  여부
                </li>
              </ul>
              <span>
                추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체
                스스로 자율적으로 판단하여 작성·공개함
              </span>
            </div>
          </div>
          <div>
            <h3>
              제12조
              <br />
              가명정보를 처리하는 경우 가명정보 처리에 관한 사항
            </h3>
            <p>
              &lt; mss &gt; 은(는) 다음과 같은 목적으로 가명정보를 처리하고
              있습니다.
            </p>
            <div className="small-box bg-gray">
              <ul className="arrow">
                <li>
                  가명정보의 처리 목적
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
                <li>
                  가명정보의 처리 및 보유기간
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
                <li>
                  가명정보의 제3자 제공에 관한 사항(해당되는 경우에만 작성)
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
                <li>
                  가명정보 처리의 위탁에 관한 사항(해당되는 경우에만 작성)
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
                <li>
                  가명처리하는 개인정보의 항목
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
                <li>
                  법 제28조의4(가명정보에 대한 안전조치 의무 등)에 따른
                  가명정보의 안전성 확보조치에 관한 사항
                  <br className="no-content" />- 직접작성 가능합니다.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3>
              제13조
              <br />
              개인정보 보호책임자에 관한 사항
            </h3>
            <p>
              ① mss 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고,
              개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
              아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            </p>
            <div className="small-box bg-gray">
              <ul className="arrow">
                <li>
                  개인정보 보호책임자
                  <ul>
                    <li>성명 :문성석</li>
                    <li>직책 :대표</li>
                    <li>직급 :대표</li>
                    <li>
                      연락처 :070-4725-0204, Mss740204@naver.com,
                      <br />
                      <span>개인정보 보호 담당부서로 연결됩니다.</span>
                    </li>
                  </ul>
                </li>
                <li>
                  개인정보 보호 담당부서
                  <ul>
                    <li>부서명 :</li>
                    <li>담당자 :</li>
                    <li>연락처 :, ,</li>
                  </ul>
                </li>
              </ul>
            </div>
            <p>
              ② 정보주체께서는 mss 의 서비스(또는 사업)을 이용하시면서 발생한
              모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
              개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. mss 은(는)
              정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
            </p>
          </div>
          <div>
            <h3>
              제14조
              <br />
              국내대리인의 지정
            </h3>
            <p>
              정보주체는 ｢개인정보 보호법｣ 제39조의11에 따라 지정된 &lt; mss
              &gt;의 국내대리인에게 개인정보 관련 고충처리 등의 업무를 위하여
              연락을 취할 수 있습니다. &lt; mss &gt;은(는) 정보주체의 개인정보
              관련 고충처리 등 개인정보 보호책임자의 업무 등을 신속하게 처리할
              수 있도록 노력하겠습니다.
            </p>
            <div className="small-box bg-gray">
              <ul className="arrow">
                <li>
                  &lt; mss &gt; 은(는) ｢개인정보 보호법｣ 제39조의11에 따라
                  국내대리인을 지정하였습니다.
                  <ul>
                    <li>
                      국내대리인의 성명 : [대리인 성명_직접입력] (법인의 경우
                      법인명, 대표자의 성명)
                    </li>
                    <li>
                      국내대리인의 주소 : [대리인 주소_직접입력] (법인의 경우
                      영업소 소재지)
                    </li>
                    <li>국내대리인의 전화번호 : [대리인 전화번호_직접입력]</li>
                    <li>
                      국내대리인의 전자우편 주소 : [대리인 전자우편_직접입력]
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3>
              제15조
              <br />
              개인정보의 열람청구를 접수·처리하는 부서
            </h3>
            <p>
              정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를
              아래의 부서에 할 수 있습니다.
              <br />
              &lt; mss &gt;은(는) 정보주체의 개인정보 열람청구가 신속하게
              처리되도록 노력하겠습니다.
            </p>
            <div className="small-box bg-gray">
              <ul className="arrow">
                <li>
                  개인정보 열람청구 접수·처리 부서
                  <ul>
                    <li>부서명 : 경영</li>
                    <li>담당자 : 문성석</li>
                    <li>연락처 : 07047250204, Mss740204@naver.com,</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3>
              제16조
              <br />
              정보주체의 권익침해에 대한 구제방법
            </h3>
            <p>
              정보주체는 개인정보침해로 인한 구제를 받기 위하여
              개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
              분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
              개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
              바랍니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt />
                <dd>
                  <ul>
                    <li>
                      개인정보분쟁조정위원회 : (국번없이) 1833-6972
                      (www.kopico.go.kr)
                    </li>
                    <li>
                      개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
                    </li>
                    <li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
                    <li>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <p>
              「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의
              정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대
              하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
              이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
              청구할 수 있습니다.
              <span>
                행정심판에 대해 자세한 사항은
                중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기
                바랍니다.
              </span>
            </p>
          </div>
          <div>
            <h3>
              제17조
              <br />
              영상정보처리기기 운영·관리에 관한 사항
            </h3>
            <p>
              ① &lt; mss &gt;은(는) 아래와 같이 영상정보처리기기를 설치·운영하고
              있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dt>1.영상정보처리기기 설치근거·목적 : &lt; mss &gt;의</dt>
                <dt>2.설치 대수, 설치 위치, 촬영 범위 :</dt>
                <dd>
                  <ul>
                    <li>설치대수 : 대</li>
                    <li>설치위치 :</li>
                    <li>촬영범위 :</li>
                  </ul>
                </dd>
                <dt>3.관리책임자, 담당부서 및 영상정보에 대한 접근권한자 :</dt>
                <dt>4.영상정보 촬영시간, 보관기간, 보관장소, 처리방법</dt>
                <dd>
                  <ul>
                    <li>촬영시간 : 시간</li>
                    <li>보관기간 : 촬영시부터</li>
                    <li>보관장소 및 처리방법 :</li>
                  </ul>
                </dd>
                <dt>5.영상정보 확인 방법 및 장소 :</dt>
                <dt>6.정보주체의 영상정보 열람등요구에 대한 조치 :</dt>
                <dd>
                  개인영상정보 열람.존재확인 청구서로 신청하여야 하며, 정보주체
                  자신이 촬영된 경우 또는 명백히 정보주체의 생명.신체.재산
                  이익을 위해 필요한 경우에 한해 열람을 허용함
                </dd>
                <dt>7.영상정보처리기기 설치근거·목적 :</dt>
              </dl>
            </div>
          </div>
          <div>
            <h3>
              제18조
              <br />
              개인정보 처리방침 변경
            </h3>
            <p>
              ① 이 개인정보처리방침은 2023년 9월 1부터 적용됩니다.
              <br />② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
            </p>
            <div className="small-box bg-gray">
              <dl>
                <dd>
                  <ul>
                    <li>예시 ) - 20XX. X. X ~ 20XX. X. X 적용</li>
                    <li>예시 ) - 20XX. X. X ~ 20XX. X. X 적용</li>
                    <li>예시 ) - 20XX. X. X ~ 20XX. X. X 적용</li>
                  </ul>
                </dd>
              </dl>

            </div>


          </div>
        </div>

      </div>
      <div
        className="modal modal-kakao d-none"
        style={{ display: isToggle ? "block" : "none" }} //toggleSEt 조건도 추가    
      >
        <a

          onClick={(e) => {
            e.preventDefault();
            setIsToggle(false)
            setTimeout(() => {
              alert('약관에 동의하셔야만 보험분석이 가능합니다.')
            }, 100)
          }}
          className="modal-btn"
        >
          <span />
          <span />
        </a>
        {/*    <form action="">
          <div className="modal-wrap">
            <p>
              계정과 비밀번호 입력 없이
              <br />
              카카오톡으로 로그인해 보세요.
            </p>
            <div>
              <button className="kakao-login">카카오 로그인</button>
            </div>
          </div>
          <div className="modal-wrap bg-gray">
            <span>계정 정보 입력으로 로그인할 수 있어요.</span>
            <div>
              <input
                type="text"
                name="kakao_id"
                id="kakao_id"
                placeholder="카카오메일 아이디, 이메일, 전화번호"
              />
              <label htmlFor="kakao_id" className="d-none">
                카카오메일 아이디, 이메일, 전화번호
              </label>
            </div>
            <div>
              <input
                type="password"
                name="kakao_pw"
                id="kakao_pw"
                placeholder="비밀번호"
              />
              <label htmlFor="kakao_pw" className="d-none">
                비밀번호
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                name="login-info-check"
                id="login-info-check"
                defaultChecked=""
                className="d-none"
              />
              <label htmlFor="login-info-check">간편로그인 정보 저장</label>
            </div>
            <button className="kakao-login">로그인</button>
            <ul>
              <li>
                <a href="#">회원가입</a>
              </li>
              <li>
                <a href="#">계정 찾기</a>
              </li>
              <li>
                <a href="#">비밀번호 찾기</a>
              </li>
            </ul>
          </div>
        </form> */}
      </div>
      {
        isMobile && (
          <BottomSheet
            ref={sheetRef}
            open={open}
            defaultSnap={0.3}
            snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight]}
            onSpringEnd={(event) => {
              if (event.type === "SNAP" && event.source === "snap-to-bottom") {
                console.log(
                  "Just finished an imperative transition to the bottom snap point"
                );
              }
            }}
            onDismiss={() => setOpen(false)} // 시트가 닫힐 때
            onPositionChange={handleSheetDrag} // 시트의 위치 변경 감지
          >
            <main class="MobileMain">
              <form action="">
                <div className="wrap">
                  <section id="st02" className="border-bottom">
                    <div className="d-flex titblock">
                      <div>
                        <label htmlFor="name">이름을 입력해주세요</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="이름을 입력해주세요"
                          value={data?.name}
                          onChange={(e) => {
                            setData({ ...data, name: e.target.value });
                          }}
                        />
                      </div>
                      <div>
                        <label htmlFor="birth">생년월일을 입력해주세요</label>
                        <input
                          type="date"
                          name="birth"
                          id="birth"
                          placeholder="예) 20000101"
                          maxLength={8}
                          value={data?.birth}
                          onChange={(e) => {
                            setData({ ...data, birth: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <span>휴대폰번호를 입력해주세요</span>
                      <div className="d-flex flex-phone">
                        <label htmlFor="phone1" className="d-none" />
                        <input
                          type="number"
                          name="phone1"
                          id="phone1"
                          placeholder={"010"}
                          maxLength={3}
                          defaultValue={"010"}
                          value={telData?.first}
                          onChange={(e) => {
                            setTelData({ ...telData, first: e.target.value });
                          }}
                        />
                        <span>-</span>
                        <label htmlFor="phone2" className="d-none" />
                        <input
                          type="number"
                          name="phone2"
                          id="phone2"
                          maxLength={4}
                          defaultValue=""
                          value={telData?.second}
                          onChange={(e) => {
                            setTelData({ ...telData, second: e.target.value });
                          }}
                        />
                        <span>-</span>
                        <label htmlFor="phone3" className="d-none" />
                        <input
                          type="number"
                          name="phone3"
                          id="phone3"
                          maxLength={4}
                          defaultValue=""
                          value={telData?.last}
                          onChange={(e) => {
                            setTelData({ ...telData, last: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <span>거주지를 입력해주세요</span>
                      <div className="d-flex flex-address">
                        <select
                          id="sido"
                          value={selectedSido}
                          onChange={(e) => setSelectedSido(e.target.value)}
                        >
                          <option value="">선택</option>
                          {hangjungdong?.sido?.map((item, idx) => {
                            return (
                              <option value={item?.sido}>{item?.codeNm}</option>
                            );
                          })}
                        </select>
                        <select
                          id="sigugun"
                          value={selectedSigugun}
                          onChange={(e) => setSelectedSigugun(e.target.value)}
                        >
                          <option value="">선택</option>
                          {hangjungdong?.sigugun
                            ?.filter((item, idx) => {
                              return item?.sido === selectedSido;
                            })
                            .map((item, idx) => {
                              return (
                                <option value={item?.sigugun}>
                                  {item?.codeNm}
                                </option>
                              );
                            })}
                          {/* 여기에 sigugun 옵션을 동적으로 생성하는 코드 추가 */}
                        </select>
                        <select
                          id="dong"
                          value={selectedDong}
                          onChange={(e) => setSelectedDong(e.target.value)}
                        >
                          <option value="">선택</option>
                          {hangjungdong?.dong
                            ?.filter((item, idx) => {
                              return item?.sigugun === selectedSigugun;
                            })
                            .map((item, idx) => {
                              return (
                                <option value={item?.dong}>{item?.codeNm}</option>
                              );
                            })}
                          {/* 여기에 dong 옵션을 동적으로 생성하는 코드 추가 */}
                        </select>
                      </div>
                    </div>
                    {/*  <div>
                      <span>성별을 선택해주세요</span>
                      <div className="d-flex">
                        <input
                          type="radio"
                          name="gender"
                          id="male-a"
                          className="d-none"
                          defaultChecked=""
                          style={{ display: "none" }}
                          onClick={() => {
                            setData({ ...data, gender: "M" });
                          }}
                          checked={data?.gender === "M"}
                        />
                        <label htmlFor="male-a">남자</label>
                        <input
                          type="radio"
                          name="gender"
                          style={{ display: "none" }}
                          id="female-b"
                          className="d-none"
                          onClick={() => {
                            setData({ ...data, gender: "W" });
                          }}
                          checked={data?.gender === "W"}
                        />
                        <label htmlFor="female-b">여자</label>
                      </div>
                    </div> */}
                  </section>
                  {/* <section id="st03" className="border-bottom">
                    <div className="d-flex titblock">
                      <div>
                        <span>납입보험료를 선택해주세요</span>
                        <select
                          value={data?.payinsure}
                          onChange={(e) => {
                            setData({ ...data, payinsure: e.target.value });
                          }}
                          name="fee"
                          id="fee"
                        >
                          <option value="">납입보험료</option>
                          <option value="10만원 미만">10만원 미만</option>
                          <option value="10만원 이상">10만원 이상</option>
                          <option value="20만원 이상">20만원 이상</option>
                          <option value="30만원 이상">30만원 이상</option>
                          <option value="40만원 이상">40만원 이상</option>
                          <option value="50만원 초과">50만원 초과</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="date">방문예약일을 선택해주세요</label>
                        <input
                          value={data?.rsrvtdate}
                          onChange={(e) => {
                            setData({ ...data, rsrvtdate: e.target.value });
                          }}
                          type="date"
                          name="date"
                          id="date"
                        />
                      </div>
                    </div>
                    <div className="d-flex titblock">
                      <div>
                        <span>방문예약시간을 선택해주세요</span>
                        <select
                          value={data?.rsrvtround}
                          onChange={(e) => {
                            setData({ ...data, rsrvtround: e.target.value });
                          }}
                          name="time"
                          id="time"
                        >
                          <option value="">방문예약시간</option>
                          <option value="오전">오전</option>
                          <option value="오후">오후</option>
                        </select>
                      </div>
                      <div>
                        <span>통화가능시간을 선택해주세요</span>
                        <select
                          value={data?.talktime}
                          onChange={(e) => {
                            setData({ ...data, talktime: e.target.value });
                          }}
                          name="possible"
                          id="possible"
                        >
                          <option value="">통화가능시간</option>
                          <option value="오전">오전</option>
                          <option value="오후">오후</option>
                          <option value="상시">상시</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label htmlFor="text">요청사항을 입력해주세요</label>
                      <textarea
                        name=""
                        value={data?.requests}
                        onChange={(e) => {
                          setData({ ...data, requests: e.target.value });
                        }}
                        id="text"
                        placeholder="요청사항을 입력해주세요"
                        defaultValue={""}
                      />
                    </div>
                  </section> */}
                  <section id="st04">

                    <input
                      type="button"
                      defaultValue="보험 분석하기"
                      onClick={() => {
                        _handlePost();
                      }}
                    />
                  </section>
                </div>
              </form>
            </main>
          </BottomSheet>
        )
      }
    </>
  );
};

export default PcPage;
