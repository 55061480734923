import styled from "@emotion/styled";
import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { SearchIco } from "../../Assets";
import GenTop from "../../Component/GenTop";
import FilterForm from "../../Component/Table/FilterForm";
import MainTable from "../../Component/Table/MainTable";
import SearchHeader from "../../Component/Table/SearchHeader";
import { WrapperDash } from "../../Styled/DashBoardGlobal";
import TableButton from "../../Component/Table/TableButton";
import {
  ButtonArea,
  SettingBottomArea,
  SettingBottomBtnArea,
  SettingContentArea,
  SettingNoticeArea,
  SettingTitle,
  SettingTopBtnArea,
} from "../../Styled/SettingStyled";
import { GET_CENTER_LIST_SWR, GET_DELARY_SWR, GET_USER_INFO_SWR } from "../../Data/SWR";
import { DEL_DELARY_API } from "../../Data/POST";
const TableContainer = styled.div`
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
`;

const TableHeader = styled.div`
  background-color: #dfe6ed;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding: 10px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const PlusIcon = styled.div`
  background-color: #eee;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px; /* 추가 */
  min-height: 20px; /* 추가 */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const DealerManagement = () => {
  const [checked, setChecked] = useState([]); // 체크 상태를 나타내는 배열
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("오름차"); // 초기 필터 선택
  const [selectedFilter2, setSelectedFilter2] = useState("전체"); // 초기 필터 선택

  const [selectId, setSelectId] = useState(0);
  const [selectId2, setSelectId2] = useState("전체");
  const [inputVal, setInputVal] = useState("");
  const [search, setSearch] = useState("");

  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { DelaryListData, DelaryListError, DelaryListMutate } = GET_DELARY_SWR({
    offset: offset,
    limit: 10,
    search: search,
    filter: selectId,
    centerId: selectId2,
  });

  const { UserINfoData, UserINfoError, UserINfoMutate } = GET_USER_INFO_SWR()
  const [FilterVal2, setFilterVal2] = useState([
    {
      onClick: (e) => {
        e("전체");
      },
      text: "전체",
    },
  ]);
  const { CenterListData, CenterListMutate, CenterListoError } =
    GET_CENTER_LIST_SWR();

  useEffect(() => {
    if (CenterListData) {
      const MapData = CenterListData?.map((item, idx) => {
        return {
          event: (e) => {
            setSelectId2(item?.id);
          },
          text: item?.title,
        };
      });
      MapData.unshift({
        event: (e) => {
          setSelectId2("전체");
        },
        text: "전체",
      });

      setFilterVal2(MapData);
    }
  }, [CenterListData]);
  const Navigate = useNavigate();

  const headers = [
    { title: "딜러명", key: "name" },
    { title: "소속센터", key: "USER_CENTER.title" },
    { title: "권한", key: "master" },
    { title: "연락처", key: "tel" },
    { title: "마지막로그인", key: "updatedAt" },
    { title: "주소", key: "USER_SITE.site_name" },
  ];

  const FilterValue = [
    {
      event: (e) => {
        setSelectId("내림차");
      },
      text: "내림차",
    },
    {
      event: (e) => {
        setSelectId("오름차");
      },
      text: "오름차",
    },
  ];

  const _handleDelete = async () => {
    if (checked.length === 0) {
      alert("제거할 항목을 선택해주세요.");
      return;
    }
    console.log(checked);
    const res = await DEL_DELARY_API(checked);
    if (res === undefined) {
      alert("네트워크 연결을 확인해주세요");
      return;
    }
    await DelaryListMutate();
  };

  /* 테이블모듈 =데이터 동기화  마무ㄹ*/
  return (
    <WrapperDash>
      <GenTop
        title={"회원 관리"}
        SubTitle={"딜러 관리"}
        SubTitle2={"딜러 관리"}
      />
      <div style={{ marginTop: 20 }}>
        딜러 명칭으로 검색
        <SearchHeader
          value={inputVal}
          onEvent={(e) => {
            setInputVal(e.target.value);
          }}
          onPressEnter={() => {
            setSearch(inputVal);
          }}
          onPressClose={() => {
            setInputVal("");
            setSearch("");
          }}
        />
      </div>
      <div style={{ marginTop: 20, display: "flex", alignItems: "center" }}>
        {UserINfoData?.master === 2 &&
          <FilterForm
            anchorEl={anchorEl2}
            setAnchorEl={setAnchorEl2}
            FilterValue={FilterVal2}
            selectedFilter={selectedFilter2}
            setSelectedFilter={setSelectedFilter2}
          />
        }

        <div style={{ marginLeft: 16 }}>
          <FilterForm
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            FilterValue={FilterValue}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        </div>
      </div>
      <SettingTopBtnArea>
        <SettingTitle> </SettingTitle>


        {UserINfoData?.master !== 0 &&
          <TableButton
            color={{ active: "blue", none: "red" }}
            event={() => {
              Navigate("/admin/members/Invite");
            }}
            text={"딜러 등록"}
          />
        }

      </SettingTopBtnArea>
      <MainTable
        Action={() => {
          console.log("AA");
        }}
        ActionMode={false}
        data={DelaryListData?.rows ? DelaryListData?.rows : []}
        headers={headers}
        CheckMode={true}
        checked={checked}
        setChecked={setChecked}
      />
      <div style={{ marginTop: 16, display: "flex", flexDirection: "row" }}>
        <div style={{ marginRight: 16 }}>
          <TableButton
            color={{ active: "red", none: "black" }}
            event={() => {
              _handleDelete();
            }}
            text={"딜러 삭제"}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={10}
          onPageChange={(pageNumber) => {
            setCurrentPage(pageNumber);
            setOffset((pageNumber - 1) * 10);
          }}
          totalItems={DelaryListData?.count}
          pageNeighbours={14}
        />
      </div>
    </WrapperDash>
  );
};

export default DealerManagement;
