import React, { useEffect, useState } from "react";

import ImageUploadFormRow from "../../Component/Table/ImageUploadFormRow";
import ChckRow, { FormRowWrapper } from "../../Component/TableIn/ChckRow";
import ChkList from "../../Component/TableIn/ChkList";
import Editor from "../../Component/TableIn/Editor";
import FormRow from "../../Component/TableIn/FromRow";
import TextCheckboxFormRow from "../../Component/TableIn/TextCheckboxFormRow";
import TextRadioFormRow from "../../Component/TableIn/TextRadioFormRow";
import { WrapperDash } from "../../Styled/DashBoardGlobal";
import GenTop from "../../Component/GenTop";
import { TableWrap } from "../Admin/GeneralSettings";
import { POST_INVITE_API } from "../../Data/POST";
import { useNavigate } from "react-router-dom";
import TableButton from "../../Component/Table/TableButton";
import { GetAllCenter, GET_USER_INFO_SWR } from "../../Data/SWR";

const Invite = () => {
  const [data, setData] = useState({
    center_master: false,
    cnt_name: "",
    cnt_tel: "",
    invite_text: "",
  });
  const Navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("딜러");
  const [centerTitle, setCenterTitle] = useState("");
  const [centerValue, setCenterValue] = useState(""); // 선택한 옵션의 value 값
  const { UserINfoData, UserINfoError, UserINfoMutate } = GET_USER_INFO_SWR();
  const { AllCenterData, AllCenterError, AllCenterMutate } = GetAllCenter()
  const [select, setSelect] = useState({ value: "", title: "" })

  //센터리스트 api

  const _handlePost = async () => {
    if (data?.center_master === true) {
      if (select === "") {
        alert('센터를 지정해주세요')
        return;
      }
    }
    if (data.cnt_name.length < 3) {
      alert("딜러명 3글자 이상이어야 합니다.");
      return;
    } else if (data.cnt_tel.length < 3) {
      alert("전화번호는 3글자 이상이어야 합니다.");
      return;
    } else if (data.invite_text.length < 3) {
      alert("초대 문구는 3글자 이상이어야 합니다.");
      return;
    }

    const res = await POST_INVITE_API(
      selectedValue === "센터장"
        ? { body: { join: { ...data, center_master: true }, center: centerTitle } }
        : {
          body: {
            join: { ...data, center_master: false, cnt_id: centerValue, cint_title: centerTitle },
            center: centerTitle,
          },
        }
    );
    if (res === undefined) {
      alert("네트워크 연결을 확인해주세요");
      return;
    }
    console.log(res, "결과값");
    alert(`초대 발급성공 \n주소는 ${res?.result?.site} 입니다 `);
    Navigate(-1);
  };


  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;

    const selectedOption = AllCenterData?.find((item) => item.id === Number(selectedValue));

    if (selectedOption) {
      setCenterValue(selectedOption.id);
      setCenterTitle(selectedOption.title);
    } else {
      setCenterValue("");
      setCenterTitle("");
    }
  };

  useEffect(() => {
    if (UserINfoData?.master !== 2) {
      const selectedOption = AllCenterData?.find((item) => item.id === Number(UserINfoData?.centerId));
      setCenterValue(selectedOption?.id);
      setCenterTitle(selectedOption.title);
    }
  }, [])
  return (
    <WrapperDash>
      <GenTop
        title={"회원 관리"}
        SubTitle={"딜러 관리"}
        SubTitle2={"딜러 등록"}
      />
      <div
        style={{ marginTop: 16, display: "flex", justifyContent: "flex-end" }}
      >
    
          <TableButton
            color={{ active: "blue", none: "red" }}
            event={() => {
              _handlePost();
            }}
            text={"딜러 등록"}
          />
  
      </div>
      <TableWrap style={{ marginTop: 16 }}>
        {UserINfoData?.master === 2 &&
          <TextRadioFormRow
            formName="Form 1"
            headerName="센터 관리 권한"
            text="Select an option:"
            radioLabels={[{ text: "센터장" }, { text: "딜러" }]}
            onRadioChange={(e) => {
              setSelectedValue(e);
            }}
            selectedValue={selectedValue}
          />}


        {selectedValue === "센터장" ? (
          <FormRow
            formName="제목 입력"
            isTop={true}
            value={centerTitle}
            event={(e) => {
              setCenterTitle(e.target.value);
            }}
            headerName="센터명"
            placeholder="센터명을 입력해주세요."
            description="초대장 제목을 기입해주세요"
          />
        ) : <FormRowWrapper style={{ marginTop: 16, width: '100%', padding: 0, margin: 0 }}>
          <table>
            <tbody>
              <tr>
                <td
                  width={"15%"}
                  style={{
                    backgroundColor: "#dfe6ed",

                    paddingLeft: 16,
                  }}
                >
                  센터를 선택해주세요
                </td>
                <td style={{ padding: 8, paddingLeft: 16 }}>
                  <div>
                    <select disabled={UserINfoData?.master !== 2} onChange={handleSelectChange} value={centerValue}>
                      <option value="">--선택해주세요---</option>
                      {AllCenterData?.map((item, idx) => (
                        <option key={item.id} value={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </select>

                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </FormRowWrapper>}
        {/*생성종료 */}

        {/*센터 생성일경우 */}

        <FormRow
          formName="딜러명 입력"
          isTop={true}
          headerName="딜러명"
          placeholder="딜러명을 입력해주세요."
          description="초대장 제목을 기입해주세요"
          value={data?.cnt_name}
          event={(e) => {
            setData({ ...data, cnt_name: e.target.value });
          }}
        />

        <FormRow
          formName="딜러명 입력"
          isTop={true}
          headerName="연락처"
          placeholder="연락처를 입력해주세요."
          description="이름을 기입해주세요"
          value={data?.cnt_tel}
          event={(e) => {
            setData({ ...data, cnt_tel: e.target.value });
          }}
        />
        <FormRow
          formName="딜러명 입력"
          isTop={true}
          headerName="초대장 내용"
          AreaType={true}
          placeholder="초대장 내용을 입력해주세요."
          description="이름을 기입해주세요"
          value={data?.invite_text}
          event={(e) => {
            setData({ ...data, invite_text: e.target.value });
          }}
        />
      </TableWrap>
    </WrapperDash>
  );
};

export default Invite;
