import React from "react";
import { Link } from "react-router-dom";
import { SearchIco } from "../../Assets";
import GenTop from "../../Component/GenTop";
import { WrapperDash } from "../../Styled/DashBoardGlobal";
import {
  ButtonArea,
  SettingBottomArea,
  SettingBottomBtnArea,
  SettingContentArea,
  SettingNoticeArea,
  SettingTitle,
  SettingTopBtnArea,
  UserContentArea,
} from "../../Styled/SettingStyled";

const ReceptionManagement = () => {
  return (
    <WrapperDash>
      <GenTop
        title={"센터 관리"}
        SubTitle={"센터 관리"}
        SubTitle2={"접수 관리"}
      />

      <UserContentArea>
        <div className="title">기본검색</div>
        <table className="firstTable">
          <thead>
            <tr>
              <th className="firstTh">검색어</th>
              <th>
                <select>
                  <option value={"아이디"}>접수딜러</option>
                  <option value={"이름"}>이름</option>
                  <option value={"휴대전화"}>휴대전화</option>
                </select>
                <input type="text" />
                <img src={SearchIco} />
              </th>
            </tr>
          </thead>
        </table>
        <ButtonArea>
          <button className="black">검 색</button>
          <button className="gray">초 기 화</button>
        </ButtonArea>

        <div className="secondTitle">
          총 회원수 : <strong>22</strong>명
        </div>
        <table className="secondTable">
          <thead>
            <tr>
              <th>
                <input type="checkbox" />
              </th>
              <th>이름</th>
              <th>생년 월일</th>
              <th>연락처</th>
              <th>접수 딜러</th>
              <th>납입 보험료</th>
              <th>통화 가능시간</th>
              <th>상태</th>
              <th>개인정보 수집정보</th>
              <th>더보기</th>
            </tr>

            <tr>
              <th>
                <input type="checkbox" />
              </th>
              <th>qwe</th>
              <th>qwe</th>
              <th>qwe</th>
              <th>qwe</th>
              <th>qwe</th>
              <th>qwe</th>
              <th>qwe</th>
              <th>qwe</th>
              <th>
                <Link
                /*     to={{
                    pathname: `/admin/memberDetail/${item?.id}`,
                    state: "정지",
                  }} */
                >
                  더보기
                </Link>
              </th>
            </tr>
          </thead>
        </table>
      </UserContentArea>

      <SettingBottomArea>
        <SettingBottomBtnArea>
          <button
            style={{
              border: "1px solid #1565C0",
              backgroundColor: "#1565C0",
              color: "#fff",
            }}
          >
            선택회원 정지
          </button>
          <button
            style={{
              border: "1px solid #f73d79",
              backgroundColor: "#f73d79",
              color: "#fff",
            }}
            className="red"
          >
            선택회원 삭제
          </button>
        </SettingBottomBtnArea>
      </SettingBottomArea>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/*     <Pagination
          currentPage={currentPage}
          itemsPerPage={10}
          onPageChange={(pageNumber) => {
            setCurrentPage(pageNumber);
            setOffset((pageNumber - 1) * 10);
          }}
          totalItems={UserListData?.count}
          pageNeighbours={14}
        /> */}
      </div>
    </WrapperDash>
  );
};

export default ReceptionManagement;
