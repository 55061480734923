/* import React from "react";
import GenTop from "../../../Component/GenTop";
import ImageUploadFormRow from "../../../Component/Table/ImageUploadFormRow";
import ChckRow from "../../../Component/TableIn/ChckRow";
import ChkList from "../../../Component/TableIn/ChkList";
import Editor from "../../../Component/TableIn/Editor";
import FormRow from "../../../Component/TableIn/FromRow";
import TextCheckboxFormRow from "../../../Component/TableIn/TextCheckboxFormRow";
import TextRadioFormRow from "../../../Component/TableIn/TextRadioFormRow";
import { WrapperDash } from "../../../Styled/DashBoardGlobal";

const NoticeEdit = () => {
  const rightContent = [
    { id: "1", content: "Item 1", checked: false },
    { id: "2", content: "Item 2", checked: true },
    { id: "3", content: "Item 3", checked: false },
    { id: "4", content: "Item 4", checked: true },
    { id: "5", content: "Item 5", checked: false },
  ];

  return (
    <WrapperDash>
      <GenTop
        title={"공지사항"}
        SubTitle={"공지사항 관리"}
        SubTitle2={"공지사항 등록"}
      />
      <div style={{ marginTop: 16 }}>
        <FormRow
          formName="제목 입력"
          isTop={true}
          headerName="제목을 입력해주세요"
          placeholder="입력 필드 2"
          description="공지사항 제목을 기입해주세요"
        />
        <ImageUploadFormRow
          formName="제목 입력"
          isTop={true}
          headerName="제목을 입력해주세요"
          placeholder="입력 필드 2"
          description="공지사항 제목을 기입해주세요"
        />
        <TextCheckboxFormRow
          formName="Form Name"
          headerName="Header Name"
          text="Some text here"
          checkboxes={["Checkbox 1", "Checkbox 2", "Checkbox 3"]}
          descriptions={["Description 1", "Description 2"]}
          isError={false}
          isTop={true}
        />
        <TextRadioFormRow
          formName="Form 1"
          headerName="Header 1"
          text="Select an option:"
          radioLabels={["Option 1", "Option 2", "Option 3"]}
          descriptions={["Description 1", "Description 2"]}
          isError={false}
          isTop={true}
        />

        <ChckRow
          formName="Form 1"
          headerName="Header 1"
          text="Select an option:"
          radioLabels={["Option 1", "Option 2", "Option 3"]}
          descriptions={["Description 1", "Description 2"]}
          isError={false}
          isTop={true}
          rightContent={rightContent}
        />
        <Editor
          value={""}
          placeholder={"공지사항 내용을 작성해주세요"}
          onEditorChange={onEditorChange}
          setfilesCheck={setfilesCheck}
          filesdata={filesdata}
          onFilesChange={onFilesChange}
        />
      </div>
    </WrapperDash>
  );
};

export default NoticeEdit;
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GenTop from "../../../Component/GenTop";
import ImageUploadFormRow from "../../../Component/Table/ImageUploadFormRow";
import TableButton from "../../../Component/Table/TableButton";
import ChckRow from "../../../Component/TableIn/ChckRow";
import ChkList from "../../../Component/TableIn/ChkList";
import Editor from "../../../Component/TableIn/Editor";
import FormRow from "../../../Component/TableIn/FromRow";
import TextCheckboxFormRow from "../../../Component/TableIn/TextCheckboxFormRow";
import TextRadioFormRow from "../../../Component/TableIn/TextRadioFormRow";
import { POST_NOTICE_API } from "../../../Data/POST";
import { WrapperDash } from "../../../Styled/DashBoardGlobal";
import { TableWrap } from "../GeneralSettings";

const NoticeEdit = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    title: "",
    content: "",
  });

  const _handleCreate = async () => {
    const res = await POST_NOTICE_API({ body: data });
    if (res === undefined) {
      alert("네트워크 연결을 확인해주세요");
      return;
    }
    alert("등록성공");
    navigate(-1); // 뒤로 가기
  };
  return (
    <WrapperDash>
      <GenTop
        title={"공지사항"}
        SubTitle={"공지사항 관리"}
        SubTitle2={"공지사항 등록"}
      />
      <div
        style={{
          marginTop: 16,
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TableButton
          color={{ active: "red", none: "black" }}
          event={() => {
            _handleCreate();
          }}
          text={"공지사항 등록"}
        />
      </div>
      <TableWrap style={{ marginTop: 16 }}>
        <FormRow
          formName="제목 입력"
          isTop={true}
          value={data?.title}
          event={(e) => {
            setData({ ...data, title: e.target.value });
          }}
          headerName="제목을 입력해주세요"
          placeholder="제목을 입력해주세요."
          description="공지사항 제목을 기입해주세요"
        />
        <FormRow
          formName="내용 입력"
          isTop={true}
          AreaType={true}
          value={data?.content}
          event={(e) => {
            setData({ ...data, content: e.target.value });
          }}
          headerName="내용 입력해주세요"
          placeholder="내용을 입력해주세요."
          description="공지사항 내용을 기입해주세요"
        />
      </TableWrap>
    </WrapperDash>
  );
};

export default NoticeEdit;
