import styled from "@emotion/styled";
import { Pagination } from "@mui/material";
import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { SearchIco } from "../../Assets";
import GenTop from "../../Component/GenTop";
import FilterForm from "../../Component/Table/FilterForm";
import MainTable from "../../Component/Table/MainTable";
import SearchHeader from "../../Component/Table/SearchHeader";
import { WrapperDash } from "../../Styled/DashBoardGlobal";
import TableButton from "../../Component/Table/TableButton";
import {
  ButtonArea,
  SettingBottomArea,
  SettingBottomBtnArea,
  SettingContentArea,
  SettingNoticeArea,
  SettingTitle,
  SettingTopBtnArea,
} from "../../Styled/SettingStyled";
const TableContainer = styled.div`
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
`;

const TableHeader = styled.div`
  background-color: #dfe6ed;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding: 10px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const PlusIcon = styled.div`
  background-color: #eee;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px; /* 추가 */
  min-height: 20px; /* 추가 */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Center = () => {
  const [checked, setChecked] = useState([]); // 체크 상태를 나타내는 배열
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("전체"); // 초기 필터 선택

  const Navigate = useNavigate();
  const data = [
   /*  {
      id: 1,
      title: "공시사항 제목2",
      order: 30,
      createdAt: "2023-01-01",
      ison: "게시",
      user: { name: "하준" },
    },
    {
      id: 1,
      title: "공지사항 제목",
      order: 30,
      ison: "게시",
      createdAt: "2023-01-01",
      user: { name: "하준" },
    }, */
    // ...
  ];

  const headers = [
    { title: "센터 담당자", key: (data) => data.user.name },
    { title: "센터 연락처", key: "title" },
    { title: "센터 방문자수", key: "createdAt" },
    { title: "센터 신청서수", key: "createdAt" },
    { title: "센터 미처리수", key: "order" },
    { title: "센터 전환율", key: "order" },
  ];

  /* 테이블모듈 =데이터 동기화  마무ㄹ*/
  return (
    <WrapperDash>
      <GenTop
        title={"센터 관리"}
        SubTitle={"센터 관리"}
        SubTitle2={"센터 관리"}
      />
      <div style={{ marginTop: 20 }}>
        <SearchHeader />
      </div>
      <div style={{ marginTop: 20, display: "flex", alignItems: "center" }}>
        <FilterForm
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
        <div style={{ marginLeft: 16 }}>
          <FilterForm
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        </div>
      </div>
      <SettingTopBtnArea>
        <SettingTitle> </SettingTitle>
      </SettingTopBtnArea>
      <MainTable
        Action={() => {
          console.log("AA");
        }}
        ActionMode={false}
        data={data}
        headers={headers}
        CheckMode={true}
        checked={checked}
        setChecked={setChecked}
      />
      <div style={{ marginTop: 16, display: "flex", flexDirection: "row" }}>
        <div style={{ marginRight: 16 }}>
          <TableButton
            color={{ active: "red", none: "black" }}
            event={() => {
              Navigate("/admin/notice/edit");
            }}
            text={"내역 삭제"}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          currentPage={1}
          itemsPerPage={10}
          totalItems={3}
          pageNeighbours={14}
        />
      </div>
    </WrapperDash>
  );
};

export default Center;
