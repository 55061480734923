export const DBTYPEJSON = [{ value: "", key: "선택안함" }, {
    key: "방송DB",
    value: "A1"
}, {
    key: "퍼미션DB",
    value: "A1"
}]

export const MEDIACODEJSON = [{ value: "", key: "선택안함" }, {
    key: "인포모셜",
    value: "A1"
}, {
    key: "인포모셜_실버",
    value: "A2"
}, {
    key: "인포모셜_전화상담",
    value: "A3"
}, {
    key: "퍼미션_롯데멤버스",
    value: "A4"
}
]

export const CNSLAPLCTTYPEJSON = [{ value: "", key: "선택안함" }, {
    key: "무료상담신청",
    value: "A1"
}, {
    key: "보장분석",
    value: "A1"
},
{
    key: "재무설계",
    value: "A1"
}
    , {
    key: "빠른상담",
    value: "A1"
}
    , {
    key: "방문상담",
    value: "A1"
}
    , {
    key: "전화상담",
    value: "A1"
}, {
    key: "소개",
    value: "G1"
}
    , {
    key: "청약",
    value: "H1"
}
    , {
    key: "기타",
    value: "ZZ"
}


]

export const PRDCTKINDJSON = [
    { value: "", key: "선택안함" },
    , {
        key: "실손종합보험",
        value: "K01",
    },
    {
        key: "암보험",
        value: "K02",
    },
    {
        key: "건강보험",
        value: "K03",
    }
    ,
    {
        key: "정기보험",
        value: "K04",
    }
    ,
    {
        key: "종신보험",
        value: "K05",
    }
    ,
    {
        key: "어린이보험",
        value: "K06",
    }
    ,
    {
        key: "간병보험",
        value: "K07",
    }
    ,
    {
        key: "연금보험",
        value: "K08",
    }
    ,
    {
        key: "실버보험",
        value: "K09",
    }
    ,
    {
        key: "저축보험",
        value: "K10",
    }
    ,
    {
        key: "연금저축",
        value: "K11",
    }, {
        key: "상해보험",
        value: "K12",
    }

    , {
        key: "운전자보험",
        value: "K13",
    }, {
        key: "주택화재보험",
        value: "K14",
    }
    , {
        key: "화재보험",
        value: "K15",
    }
    , {
        key: "치아보험",
        value: "K16",
    }
    , {
        key: "상조보험",
        value: "K17",
    }
    , {
        key: "변액종신",
        value: "K18",
    }, {
        key: "변액연금",
        value: "K19",
    }, {
        key: "변액유니버셜",
        value: "K20",
    }, {
        key: "단독실비보험",
        value: "K21",
    }, {
        key: "배상책임보험",
        value: "K22",
    }

    , {
        key: "법률비용보험",
        value: "K23",
    }
    , {
        key: "여행자보험",
        value: "K24",
    }
    , {
        key: "레저보험",
        value: "K25",
    }
    , {
        key: "기타일반보험",
        value: "K26",
    }
    , {
        key: "자동차보험",
        value: "K30",
    }
    , {
        key: "유병자보험",
        value: "K31",
    }
]

export const CTPRVCODEJSON = [{ value: "", key: "선택안함" }, {
    key: "서울",
    value: "11"
}, {
    key: "부산",
    value: "26"
}
    , {
    key: "대구",
    value: "27"
}
    , {
    key: "인천",
    value: "28"
}
    , {
    key: "광주",
    value: "29"
}
    , {
    key: "대전",
    value: "30"
}, {
    key: "울산",
    value: "31"
}, {
    key: "세종",
    value: "36"
}
    , {
    key: "경기",
    value: "41"
}, {
    key: "충북",
    value: "43"
}
    , {
    key: "충남",
    value: "44"
}
    , {
    key: "전북",
    value: "45"
}
    , {
    key: "전남",
    value: "46"
}
    , {
    key: "경북",
    value: "47"
}
    , {
    key: "경남",
    value: "48"
}
    , {
    key: "제주",
    value: "50"
}
    , {
    key: "강원",
    value: "51"
}


]

export const AGERANGETYPE = [{ value: "", key: "선택안함" }, {
    key: "20대 미만",
    value: "E1",
}, {
    key: "20대",
    value: "E2",
}, {
    key: "30대",
    value: "E3",
}
    , {
    key: "40대",
    value: "E4",
}, {
    key: "50대",
    value: "E5",
}
    , {
    key: "60대",
    value: "E6",
}, {
    key: "70대 이상",
    value: "E7",
}
]





export const INSURANCE_COMPANIESJSON = [
    { value: "", key: "선택안함" },
    { value: "L01", key: "한화생명" },
    { value: "L02", key: "ABL생명" },
    { value: "L03", key: "삼성생명" },
    { value: "L04", key: "흥국생명" },
    { value: "L05", key: "교보생명" },
    { value: "L11", key: "신한라이프" },
    { value: "L17", key: "푸본현대생명" },
    { value: "L18", key: "KB생명" },
    { value: "L31", key: "DGB생명" },
    { value: "L33", key: "KDB생명" },
    { value: "L34", key: "미래에셋생명" },
    { value: "L41", key: "IBK연금보험" },
    { value: "L42", key: "NH농협생명" },
    { value: "L51", key: "라이나생명" },
    { value: "L52", key: "AIA생명" },
    { value: "L61", key: "KB라이프생명" },
    { value: "L62", key: "오렌지라이프" },
    { value: "L71", key: "DB생명" },
    { value: "L72", key: "메트라이프생명" },
    { value: "L74", key: "동양생명" },
    { value: "L76", key: "PCA생명" },
    { value: "L77", key: "처브라이프" },
    { value: "L78", key: "BNP파리바카디프생명" },
    { value: "N01", key: "메리츠화재" },
    { value: "N02", key: "한화손해보험" },
    { value: "N03", key: "롯데손해보험" },
    { value: "N04", key: "MG손해보험" },
    { value: "N05", key: "흥국화재" },
    { value: "N08", key: "삼성화재" },
    { value: "N09", key: "현대해상" },
    { value: "N10", key: "KB손해보험" },
    { value: "N11", key: "DB손해보험" },
    { value: "N17", key: "하나손해보험" },
    { value: "N51", key: "AIG손해보험" },
    { value: "N52", key: "ACE손해보험" },
    { value: "N71", key: "NH농협손해보험" }
];

export const QUIRYTYLEJSON = [
    { value: "", key: "선택안함" },
    {
        key: "전화",
        value: "T",
    }, {
        key: "인터넷",
        value: "I",
    }, {
        key: "기타",
        value: "ㄸF",
    }]

export const GENDERJSON = [{ value: "", key: "선택안함" }, {
    key: "남자",
    value: "1"
}, {
    key: "여자",
    value: "2",
}]
export const AGGREEJSON = [{ value: "", key: "선택안함" }, {
    key: "동의",
    value: "Y"
}, {
    key: "비동의",
    value: "N",
}]

export const DRIVEASSENS = [{ value: "", key: "선택안함" }, {
    key: "운전면허보유",
    value: "Y"
}, {
    key: "운전면허미보유",
    value: "N",
}]



export const INPUTDB = [
    { "key": "InterfaceType", "value": "interfaceType" },
    { "key": "유입요청구분", "value": "inflowRqstClass" },
    { "key": "요청시간", "value": "timestamp" },
    { "key": "DB유형", "value": "dbType" },
    { "key": "DB대상구분", "value": "dbTargetClass" },
    { "key": "제휴사고객키", "value": "prtnrCstmrKey" },
    { "key": "상담신청유형", "value": "cnslAplctType" },
    { "key": "상담신청유형상세", "value": "cnslAplctTypeDtl" },
    { "key": "매체코드", "value": "mediaCode" },
    { "key": "보험사코드", "value": "insrrCode" },
    { "key": "상품종류", "value": "prdctKind" },
    { "key": "상품명", "value": "prdctName" },
    { "key": "유입일시", "value": "inflowDt" },
    { "key": "이름", "value": "name" },
    { "key": "생년월일", "value": "brthd" },
    { "key": "성별", "value": "gender" },
    { "key": "휴대전화", "value": "clphnNo" },
    { "key": "추가전화", "value": "adtnTlphnNo" },
    { "key": "안심번호", "value": "safeTlphnNo" },
    { "key": "이메일", "value": "email" },
    { "key": "시도코드", "value": "ctprvCode" },
    { "key": "시군구코드", "value": "twnshCode" },
    { "key": "근무지역", "value": "workRegion" },
    { "key": "사은품", "value": "gift" },
    { "key": "사은품지급기한", "value": "giftTmlmPymnt" },
    { "key": "추가정보1", "value": "adtnInfo1" },
    { "key": "추가정보2", "value": "adtnInfo2" },
    { "key": "추가정보3", "value": "adtnInfo3" },
    { "key": "추가정보4", "value": "adtnInfo4" },
    { "key": "추가정보5", "value": "adtnInfo5" },
    { "key": "개인정보활용동의여부", "value": "prvcyUseAgrmnYn" },
    { "key": "개인정보활용동의일", "value": "prvcyUseAgrmnDate" },
    { "key": "개인정보활용동의기간", "value": "prvcyUseAgrmnPeriod" },
    { "key": "개인정보활용동의유형", "value": "prvcyUseAgrmnType" },
    { "key": "마케팅활용수신동의유형", "value": "mrktnUseRcvAgrmnType" },
    { "key": "마케팅수신동의여부", "value": "mrktnRcvAgrmnYn" },
    { "key": "상담요청내용", "value": "cnslRqstCntnt" },
    { "key": "보험대상자명", "value": "insprsName" },
    { "key": "보험대상자생년월일", "value": "insprsBrthd" },
    { "key": "보험대상자성별", "value": "insprsGender" },
    { "key": "직업", "value": "job" },
    { "key": "운전여부", "value": "driveYn" },
    { "key": "상담가능시간", "value": "cnslPsbltTime" },
    { "key": "출산예정일", "value": "duedt" }
]


export const MONTHDATA = [
    { value: "", key: "선택안함" },
    { "key": "1 개월", "value": 1 },
    { "key": "2 개월", "value": 2 },
    { "key": "3 개월", "value": 3 },
    { "key": "4 개월", "value": 4 },
    { "key": "5 개월", "value": 5 },
    { "key": "6 개월", "value": 6 },
    { "key": "7 개월", "value": 7 },
    { "key": "8 개월", "value": 8 },
    { "key": "9 개월", "value": 9 },
    { "key": "10 개월", "value": 10 },
    { "key": "11 개월", "value": 11 },
    { "key": "12 개월", "value": 12 }
]