import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DOWNICO, FILTERICO } from "../Assets";
import { MenuList } from "../Data/MainMenuData";

const MainMenuArea = styled.div`
  padding: 0;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  min-width: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const MenuBtnCompo = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column; // 드롭다운을 위한 수정
`;

const MenuItem = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 40px;
  background-color: ${(props) => (props.active ? "#10151C" : "#1b2430")};
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #10151c;
  }
`;

const StyledImg = styled.img`
  width: 20px;
  height: 20px;
  resize: cover;
  filter: ${(props) => (props.active ? "none" : "grayscale(100%)")};
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledP = styled.p`
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  margin-left: ${(props) => (props.bottom ? "36px" : "16px")};
  cursor: pointer;
`;

const MainMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // 각 메뉴 항목의 드롭다운 상태를 관리하기 위한 배열
  const [dropdownStates, setDropdownStates] = useState(
    Array(MenuList.length).fill(false)
  );

  // 열린 드롭다운의 인덱스를 추적하기 위한 상태
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);

  // localStorage에서 드롭다운 상태를 초기화합니다.
  useEffect(() => {
    const savedDropdownStates = JSON.parse(
      localStorage.getItem("dropdownStates") || "[]"
    );

    console.log(savedDropdownStates, "불러오기스테이트");
    if (savedDropdownStates.length === MenuList.length) {
      setDropdownStates(savedDropdownStates);
    }
  }, []);

  // 메뉴 항목을 클릭하면 해당 메뉴의 드롭다운 상태를 토글하고 해당 메뉴의 주소로 네비게이션
  const toggleDropdown = (index, link) => {
    const updatedStates = [...dropdownStates];

    // 다른 드롭다운을 클릭했을 때 현재 열린 드롭다운을 닫습니다.
    updatedStates.forEach((state, i) => {
      if (i !== index) {
        updatedStates[i] = false;
      }
    });

    updatedStates[index] = !updatedStates[index];

    setDropdownStates(updatedStates);
    setOpenDropdownIndex(index);

    // 해당 메뉴의 주소로 네비게이션
    navigate(link);
    localStorage.setItem("dropdownStates", JSON.stringify(updatedStates));
  };

  // 페이지가 언로드되기 전에 드롭다운 상태를 localStorage에 저장합니다.

  return (
    <MainMenuArea>
      {MenuList.map((menu, index) => (
        <MenuBtnCompo key={menu.title}>
          {/* 메뉴 항목을 클릭하면 해당 메뉴의 드롭다운을 열거나 닫습니다. */}
          <MenuItem
            onClick={() => toggleDropdown(index, menu.subs[0].link)}
            active={location.pathname.startsWith(menu.subs[0].link)}
          >
            <StyledDiv>
              <StyledImg
                src={FILTERICO}
                active={location.pathname.startsWith(menu.subs[0].link)}
              />
              <StyledP active={location.pathname.startsWith(menu.subs[0].link)}>
                {menu.title}
              </StyledP>
            </StyledDiv>
            <StyledImg
              src={DOWNICO}
              active={location.pathname.startsWith(menu.subs[0].link)}
            />
          </MenuItem>

          {/* 해당 메뉴의 드롭다운이 열렸을 때 하위 메뉴 항목을 표시합니다. */}
          {dropdownStates[index] && (
            <div>
              {menu.subs.map((submenu) => (
                <MenuItem
                  key={submenu.title}
                  onClick={() => navigate(submenu.link)}
                  active={location.pathname === submenu.link}
                >
                  <StyledP active={true} bottom={true}>
                    {submenu.title}
                  </StyledP>
                </MenuItem>
              ))}
            </div>
          )}
        </MenuBtnCompo>
      ))}
    </MainMenuArea>
  );
};

export default MainMenu;
