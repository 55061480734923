import styled from "@emotion/styled";
import React from "react";

export const WrapperDash = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  /* 테두리 스타일 적용 */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-top: 16px;
  padding-bottom: 16px;
  /* 그림자 효과 적용 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  /* 백그라운드 색상 적용 */
  background-color: white;
`;
