export const MenuList = [

  {
    title: "DB관리",
    subs: [
      {
        title: "DB관리",
        link: "/admin/db/DBManageMent",
      },
    ],
  },
  /*  {
      title: "환경설정",
      subs: [
      {
          title: "대시보드",
          link: "/admin/settings/dashboard",
        },  
        { title: "기본환경설정", link: "/admin/settings/general" },
      ],
    },
    {
      title: "회원관리",
      subs: [
        {
          title: "딜러 관리",
          link: "/admin/members/dealers",
        },
        {
          title: "초정장 관리",
          link: "/admin/members/invite",
        },
         {
           title: "딜러 수수료 지급내역",
           link: "/admin/members/dealersrefund",
         },
  
      ],
    },
    {
      title: "센터 관리",
      subs: [
        {
          title: "센터 관리",
          link: "/admin/center/center",
        },
        {
          title: "접수 관리",
          link: "/admin/center/reception",
        },
      ],
    }, */
];
