import React from "react";
import { EXITICO, HOMEICO } from "../Assets";

export const TopTapData = [
 
  {
    name: "로그아웃",
    action: (Nav, setModal) => {
      setModal(true);
    },
    ICO: EXITICO,
  },
];
