import styled from "@emotion/styled";
import React, { useState } from "react";

// 스타일드 컴포넌트를 사용하여 스타일을 정의합니다.
const StyledAdminTopBtn = styled.div`
  cursor: pointer;
  margin-right:16px;
  img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    transition: transform 0.2s ease; /* 애니메이션 설정 */
  }

  &:hover img {
    transform: scale(1.1); /* 클릭 시 이미지 크기 확대 애니메이션 */
  }
`;

const AdmTopBtn = ({ action, ICO }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledAdminTopBtn
      onClick={() => {
        action();
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={ICO}
        alt="Icon"
        style={{ transform: isHovered ? "scale(1.1)" : "scale(1)" }}
      />
    </StyledAdminTopBtn>
  );
};

export default AdmTopBtn;
